import React from "react";
import { DataTable } from "./data-table";
import { RolesPermissionsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";

export default function RolesPermissionsTable() {
  const agentStore = useStore((state) => state);
  return (
    <div>
      <DataTable
        columns={RolesPermissionsColumns}
        data={[
          {
            usertype: "agent",
          },
          {
            usertype: "individual",
          },
          {
            usertype: "company",
          },
          {
            usertype: "government",
          },
        ]}
      />
    </div>
  );
}
