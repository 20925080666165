import React from "react";
import { DataTable } from "./data-table";
import { RegistrationsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";

export default function AgentsRegistrationsTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["agents-registrations"],
    queryFn: async () => {
      const {
        data,
      }: {
        data: {
          allagent: {
            id: number;
            agent_id: string;
            agent_name: string;
            phone_number: string;
            email: string;
            country: string;
            state: string;
            lga: string;
            street_address: string;
            association: string | null;
            national_identity_number: string;
            payment_id: string | null;
            amount: string | null;
            payment_status: string | null;
            document_url: string | null;
            document_name: string | null;
            actions: string | null;
            comment: string | null;
            confim_by: string | null;
            confirm_date: string | null;
            created_by: string;
            activities: string | null;
            reference: null;
            registration_status: string;
            action_taken_registration: string | null;
            registration_approved_by: string | null;
            registration_approval_date: string | null;
            registration_approval_comment: string | null;
            created_at: string;
            updated_at: string;
            action_taken_payment: string | null;
            payment_approved_by: string | null;
            payment_approval_date: string | null;
            payment_approval_comment: string | null;
          }[];
        };
      } = await axios.get(`${getBackendUrl()}/all-registered-account`);
      return data.allagent;
    },
  });

  return (
    <div>
      <DataTable columns={RegistrationsColumns} data={data || []} />
    </div>
  );
}
