import { Badge } from "@/components/ui/badge";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const ProcessItems = [
  {
    title: "Visit the Verification Page",
    description: `Click on the "Verify Certificate" button below to navigate to the verification page.`,
  },
  {
    title: "Enter Certificate Details",
    description:
      "In the provided form, enter the certificate number and any other required details, such as the Certificate number.",
  },
  {
    title: "Submit for Verification",
    description: `Click "Verify" to initiate the verification process. The system will check the certificate details against the NESREA database.`,
  },
  {
    title: "Review Verification Status",
    description: `After submission, view the verification result on your screen. It will display either "Verified" (authentic) or "Unverified" (not recognized by NESREA).`,
  },
  {
    title:
      "Report unverified Certificates",
    description: `If the certificate is unverified, please reach out to NESREA's support team for assistance and Report unverified Certificates in the report section`,
  },
];

const VerificationProcess = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div id="verifycertificate" className="w-full">
      <div
        ref={ref}
        className="w-full h-fit py-32 bg-primary-50 flex flex-col gap-16 items-center justify-center relative z-10"
      >
        <div data-aos="fade-in" className="text-center space-y-5">
          <h1 className="text-4xl font-semibold">Verify NESREA Certificate</h1>
          <p className="opacity-70 text-lg max-w-lg">
            Verify the authenticity of an environmental clearance certificate
            issued by NESREA to ensure compliance.
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-10 px-10 w-full max-w-7xl mx-auto">
          {ProcessItems.map(({ title, description }, index) => (
            <div
              key={title}
              data-aos="fade-in"
              data-aos-delay={`${2 * (index * 100)}`}
              data-aos-anchor-placement="top-bottom"
              className="flex-1 max-w-[300px] min-w-[300px] p-5 border-2 border-primary-700 rounded-lg"
            >
              <div className="flex flex-col items-center text-center gap-3">
                <Badge className="font-medium w-fit mb-3">
                  STEP {index + 1}
                </Badge>
                <h1 className="font-semibold text-xl">{title}</h1>
                <p className="opacity-70 text-xs">{description}</p>
              </div>
            </div>
          ))}
        </div>
        <Link
          to={"/verify-certificate"}
          className={cn(
            buttonVariants({ variant: "default" }),
            "w-full max-w-[380px] text-2xl px-10 py-7"
          )}
        >
          Verify Certificate Now
        </Link>
      </div>
    </div>
  );
});

export default VerificationProcess;
