import React, { useEffect } from "react";
import ComponentSwitch from "@/components/ui/component-switch";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { PaymentForm } from "@/components/ui/payment-section";
import { useAgentForm } from "./_hooks";

export const AgentProfileRegistrationForm = () => {
  const { agent_page, agent_form, utils } = useAgentForm();

  useEffect(() => {
    // Check if the user has a profile
  }, []);

  return (
    <LoaderWrapper isLoading={utils.isLoading}>
      <div className="w-full flex flex-col gap-5">
        <div className="space-y-3">
          <h4 className="font-medium text-2xl">
            {agent_page.formPage <= 2
              ? "Enter the following details to update your profile"
              : "Upload the documents to update your profile"}
          </h4>
          <p className="text-neutral-400 text-xl">Mandatory documents: NIN</p>
        </div>

        {/* If the user has not uploaded a profile, show the Profile Registration Form */}

        <>
          <ComponentSwitch
            activeKey={agent_page.formPage.toString()}
            components={agent_form.formPages}
          />
        </>

        {/* If the user has uploaded a profile, show the Payment Form */}
       
      </div>
    </LoaderWrapper>
  );
};
