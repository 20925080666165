import SupportTable from "@/components/tables/supportTable";
import React from "react";
import { useStore } from "@/contexts/userStore";

export default function SupportPage() {
  const userStore = useStore();
  const { registration_status } = userStore.user;
  return registration_status == "verified" ? (
    <div>
      <SupportTable />
    </div>
  ) : (
    <></>
  );
}
