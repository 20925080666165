import { useStore } from "@/contexts/agentStore";
import { applications } from "@/lib/dummy-data/applicationsData";
import { clientsData } from "@/lib/dummy-data/clientsData";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

export default function ApplicationsLayout() {
  
  return (
    <>
      <Outlet />
    </>
  );
}
