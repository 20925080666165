import { useStore } from "@/contexts/agentStore";
import { clientsData } from "@/lib/dummy-data/clientsData";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

export default function ClientsLayout() {
  const agentStore = useStore((state) => state);

  return (
    <>
      <Outlet />
    </>
  );
}
