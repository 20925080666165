import React from "react";
import {
  AgentProfileRegistrationForm,
  CompanyProfileRegistrationForm,
  GovernmentProfileRegistrationForm,
  IndividualProfileRegistrationForm,
} from "@/pages/dashboard/profile/_forms";
import { useStore } from "@/contexts/userStore";
import ComponentSwitch from "@/components/ui/component-switch";

export default function ProfilePage() {
  const userStore = useStore((state) => state);
  const { registration_status, registration_payment_status } = userStore.user;

  const ProfileRegistrationForms = [
    {
      key: "agent",
      component: <AgentProfileRegistrationForm />,
    },
    {
      key: "company",
      component: <CompanyProfileRegistrationForm />,
    },
    {
      key: "individual",
      component: <IndividualProfileRegistrationForm />,
    },
    {
      key: "government",
      component: <GovernmentProfileRegistrationForm />,
    },
  ];

  return (
    <>
      {registration_status == "unverified" &&
        registration_payment_status == "unpaid" && (
          <>
            <ComponentSwitch
              components={ProfileRegistrationForms}
              activeKey={userStore.user.account_type}
            />
          </>
        )}

      {registration_status == "pending" && (
        <div className="w-full h-full flex items-center justify-center">
          <h1 className="text-2xl text-primary-700 font-medium">
            Awaiting Admin Approval
          </h1>
        </div>
      )}
    </>
  );
}
