import { phoneNumberValidator } from "@/lib/utils";

import { z } from "zod";

export const government_form_schema_page_1 = z.object({
  institutionName: z.string().min(2, {
    message: "Institution Name must be at least 2 characters.",
  }),
  contactPerson: z.string().min(2, {
    message: "Contact Person must be at least 2 characters.",
  }),
  phoneNumber: z
    .string()
    .min(2, {
      message: "Phone Number must be at least 2 characters.",
    })
    .refine((val) => phoneNumberValidator(val)),
  state: z.enum(
    [
      "abia",
      "adamawa",
      "akwa-ibom",
      "Anambra",
      "bauchi",
      "bayelsa",
      "benue",
      "borno",
      "cross-river",
      "delta",
      "ebonyi",
      "edo",
      "ekiti",
      "enugu",
      "federal-capital-territory",
      "gombe",
      "imo",
      "jigawa",
      "kaduna",
      "kano",
      "katsina",
      "kebbi",
      "kogi",
      "kwara",
      "lagos",
      "nasarawa",
      "niger",
      "ogun",
      "ondo",
      "osun",
      "oyo",
      "plateau",
      "rivers",
      "sokoto",
      "taraba",
      "yobe",
      "zamfara",
    ],
    { message: "State Is Required." }
  ),
  lga: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  street: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  tin_cac: z.string().min(2, {
    message: "TIN/CAC is required",
  }),
});

export const government_form_schema_page_2 = z.object({
  department: z.string().min(2, { message: "Department is required" }),
  association: z.string().min(2, { message: "Association is required" }),
});

export type government_form_schema_page_1_type = z.infer<
  typeof government_form_schema_page_1
>;
export type government_form_schema_page_2_type = z.infer<
  typeof government_form_schema_page_2
>;
