import React from "react";
import { FormControl, FormMessage } from "@/components/ui/form";

import { FormLabel } from "@/components/ui/form";

import { FormItem } from "@/components/ui/form";

import { FormField } from "@/components/ui/form";

import { zodResolver } from "@hookform/resolvers/zod";

import {
  agent_form_schema_page_1,
  agent_form_schema_page_1_type,
} from "../_schema";

import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { STATES } from "@/lib/interfaces";
import { LGAs, stateNamesMap, states } from "@/lib/site-data";
import { useForm } from "react-hook-form";

export const AgentFirstFormPage = ({
  handleSubmit,
}: {
  handleSubmit: (values: agent_form_schema_page_1_type) => void;
}) => {
  const form = useForm<agent_form_schema_page_1_type>({
    resolver: zodResolver(agent_form_schema_page_1),
    defaultValues: {
      fullName: "",
      phoneNumber: "",
      street: "",
    },
  });
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Full Name <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter your full name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Phone Number <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="tel"
                  placeholder="Enter your phone number"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="flex-1 min-w-[300px] flex-col gap-3 flex">
              <FormLabel>State</FormLabel>
              <FormControl>
                <Select
                  {...field}
                  onValueChange={(currentValue: any) => {
                    const val: agent_form_schema_page_1_type["state"] =
                      currentValue;
                    form.setValue("state", val);
                    const label: any = states.filter(
                      (state) => state.value == val
                    )[0].label;
                    const myLabel: STATES = label;
                    form.setValue("lga", LGAs[myLabel][0]);
                  }}
                >
                  <SelectTrigger className="!m-0">
                    <SelectValue placeholder="State" />
                  </SelectTrigger>
                  <SelectContent>
                    {states.map((state) => (
                      <SelectItem key={state.value} value={state.value}>
                        {state.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lga"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Local Government Area (LGA)</FormLabel>
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    form.setValue("lga", value);
                  }}
                  {...field}
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Local Government Area" />
                  </SelectTrigger>
                  <SelectContent>
                    {LGAs[stateNamesMap[form.getValues().state]] ? (
                      LGAs[stateNamesMap[form.getValues().state]].map((lga) => (
                        <SelectItem key={lga} value={lga}>
                          {lga}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value="n/a">Please Select a State</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Street <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter your street name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="w-full max-w-[164px]">Next</Button>
      </form>
    </Form>
  );
};
