import React from "react";
import UserProfiling from "./components/UserProfiling";
import Registrations from "./components/Registrations";
import RevenueOverview from "./components/RevenueOverview";

export default function ProfileManagementPage() {
  return (
    <div className="w-full flex flex-col gap-16">
      <UserProfiling />
      <Registrations />
      <RevenueOverview />
    </div>
  );
}
