import React from "react";
import AgentAddApplicationPage from "../AgentView/add-application";
import { useStore } from "@/contexts/userStore";
import ComponentSwitch from "@/components/ui/component-switch";

export default function AddApplicationPage() {
  const userStore = useStore((state) => state);
  const components = [
    {
      key: "agent",
      component: <AgentAddApplicationPage />,
    },
  ];
  return (
    <>
      <ComponentSwitch
        activeKey={userStore.user?.account_type}
        components={components}
      />
    </>
  );
}