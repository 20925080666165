import AgentsRegistrationsTable from "@/components/tables/AgentsRegistrationsTable";
import RegistrationsTable from "@/components/tables/RegistrationsTable";
import React from "react";

export default function RegistrationsPage() {
  return (
    <div className="flex flex-col gap-4">
      <RegistrationsTable />
      <AgentsRegistrationsTable />
    </div>
  );
}
