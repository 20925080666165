import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  cn,
  emailValidator,
  getBackendUrl,
  phoneNumberValidator,
} from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { useNavigate, useNavigation } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import DashboardModal from "@/components/modals/DashboardModal";
import { Undo2 } from "lucide-react";
import ImportDetailsTable from "@/pages/dashboard/applications/AgentView/add-application/components/ImportDetailsTable";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useStore as useStore_ } from "@/contexts/userStore";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { toast } from "sonner";
import { DocumentsForm } from "../DocumentsForm";
import ImporterApplicationForm from "@/pages/dashboard/applications/ImporterView/apply/_forms/application-form";

const ClientFormSchema = z.object({
  client: z.string(),
});
type ClientFormType = z.infer<typeof ClientFormSchema>;

const CompanyFormSchema = z.object({
  companyName: z.string().min(3, { message: "" }),
  companyAddress: z.string(),
  emailAddress: z.string().refine((val) => (val ? emailValidator(val) : true)),
  tin: z.string(),
  cac: z.string(),
});
type CompanyFormType = z.infer<typeof CompanyFormSchema>;

const IndividualFormSchema = z.object({
  fullName: z.string().min(3, { message: "" }),
  nin: z.string().min(11, { message: "" }).max(11, { message: "" }),
  residentialAddress: z.string().min(5, { message: "" }),
  phoneContact: z.string().refine((val) => phoneNumberValidator(val)),
  emailAddress: z.string().refine((val) => emailValidator(val)),
});
type IndividualFormType = z.infer<typeof IndividualFormSchema>;

export default function ApplicationsForm() {
  const navigate = useNavigate();
  const [formPage, setFormPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const agentStore = useStore((state) => state);
  const userStore = useStore_((state) => state.user);

  const [isDocumentUpload, setIsDocumentUpload] = useState(false);

  const [documents, setDocuments] = useState<File[]>([]);

  const [isActionLoading, setIsActionLoading] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["apply-agent-clients"],
    queryFn: async () => {
      const { data }: { data: { Clientlist: any[] } } = await axios.get(
        `${getBackendUrl()}/get-my-client/${userStore.email}`
      );
      return data.Clientlist.filter((data) => data.status == "Approved").map(
        (item) => ({
          name: item.importer_name,
          category: item.importer_category,
          dateCreated: item.created_at,
          status: item.status,
          email: item.email,
        })
      );
    },
  });

  const activeClientDetails = data?.find(
    (client) => client.name == agentStore.activeApplicationClient
  );

  return (
    <LoaderWrapper isLoading={isActionLoading || isLoading}>
      <div className="w-full space-y-4">
        {!isDocumentUpload && (
          <ClientSelectForm
            clients={data || []}
            isDisabled={(agentStore.activeApplicationClient?.length || 0) > 0}
            handleSubmit={(value) => {
              setFormPage((prev) => prev + 1);
              agentStore.setActiveApplicationClient(value.client);
            }}
          />
        )}

        {showModal && (
          <DashboardModal
            title=""
            description="Your import clearance application is successful, and will be reviewed shortly"
            cta={{
              txt: "Back to Applications",
              onClick: () => {
                navigate("/dashboard/application");
                setShowModal(false);
              },
            }}
          />
        )}

        {!isDocumentUpload &&
          (agentStore.activeApplicationClient?.length || 0) > 0 && (
            <>
              {/* <ImportDetailsTable />

              {agentStore.importDetails.length > 0 && (
                <Button
                  className="w-full max-w-[140px]"
                  onClick={() => setIsDocumentUpload(true)}
                >
                  Next
                </Button>
              )} */}

              {activeClientDetails?.email && (
                <ImporterApplicationForm
                  importer_email={activeClientDetails?.email}
                />
              )}
            </>
          )}

        {isDocumentUpload && (
          <div className="mb-20">
            <h1 className="text-2xl font-medium">
              Upload the documents for the selected category
            </h1>
            <p className="text-lg text-neutral-400">
              Mandatory documents: Proforma Invoice, MSDS, Bill of Lading,
              Packing List
            </p>
          </div>
        )}

        {isDocumentUpload && (
          <DocumentsForm
            handleSubmit={async (docs) => {
              setDocuments(docs);
              let today = new Date();
              // Doc Action Here
              let activeClient = data?.find(
                (client) => client.name == agentStore.activeApplicationClient
              );
              if (!activeClient) {
                toast.error("No Selected Client");
                return;
              }

              const docFormData = new FormData();
              docFormData.append("importer_email", activeClient.email);
              docs.forEach((doc) => docFormData.append("files[]", doc));

              try {
                await axios.post(
                  `${getBackendUrl()}/submit-clearance-application`,
                  docFormData
                );
                setShowModal(true);
              } catch (error) {
                toast.error(
                  "An Error Occurred while submitting clearance application"
                );
              }
            }}
          />
        )}
      </div>
    </LoaderWrapper>
  );
}

const ClientSelectForm = ({
  handleSubmit,
  isDisabled,
  clients,
}: {
  handleSubmit: (values: ClientFormType) => void;
  isDisabled: boolean;
  clients: {
    name: string;
    category: string;
    dateCreated: string;
    status: string;
    email: string;
  }[];
}) => {
  const agentStore = useStore((state) => state);
  const form = useForm<ClientFormType>({
    resolver: zodResolver(ClientFormSchema),
    defaultValues: {
      client: agentStore.activeApplicationClient,
    },
  });

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="client"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <div className="flex items-center justify-between w-full">
                <FormLabel>
                  Client <span className="text-destructive">*</span>
                </FormLabel>
                {isDisabled && (
                  <Button
                    className={cn(
                      buttonVariants({ variant: "link" }),
                      "bg-transparent hover:no-underline hover:bg-transparent p-0 font-light text-xs text-destructive flex items-center gap-1"
                    )}
                    onClick={() => {
                      agentStore.setActiveApplicationClient("");
                    }}
                  >
                    <Undo2 size={15} /> Reset
                  </Button>
                )}
              </div>
              <FormControl>
                <Select
                  //   {...field}
                  value={field.value}
                  disabled={isDisabled}
                  name={field.name}
                  onValueChange={(val) => {
                    form.setValue("client", val);
                    field.onBlur();
                    agentStore.clearImportDetails();
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select client" />
                  </SelectTrigger>
                  <SelectContent>
                    {clients.map((client) => (
                      <SelectItem
                        key={`${client.name}client`}
                        value={client.name}
                      >
                        {client.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isDisabled && (
          <Button
            type="submit"
            className="w-full max-w-[164px]"
            disabled={!form.formState.isValid}
          >
            Next
          </Button>
        )}
      </form>
    </Form>
  );
};
