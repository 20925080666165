import React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormItem,
  FormLabel,
  FormField,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

export default function SettingsPage() {
  const formschema = z.object({
    message: z.string().min(1),
    importerType: z.string().min(1),
  });
  const form = useForm<z.infer<typeof formschema>>({
    resolver: zodResolver(formschema),
    defaultValues: {
      message: "",
      importerType: "",
    },
  });
  const onSubmit = (data: z.infer<typeof formschema>) => {
    
  };
  return (
    <div>
      <Tabs defaultValue="notifications" className="w-full">
        <TabsList className="w-full">
          <TabsTrigger value="notifications">Notifications</TabsTrigger>
        </TabsList>
        <TabsContent value="notifications" className="p-5 flex flex-col gap-7">
          <div className="w-full flex flex-col gap-5">
            <h1 className="text-lg font-medium text-primary">
              Notification Settings
            </h1>
            <div className="p-5 rounded-md bg-[#F2F2F2] shadow-md max-w-lg min-h-[150px] w-full flex items-center justify-between">
              <div className="">
                <h3 className="text-primary font-medium">
                  Email Notifications
                </h3>
                <p className="text-sm">Receive email notifications</p>
              </div>
              <Switch />
            </div>
            <div className="p-5 rounded-md bg-[#F2F2F2] shadow-md max-w-lg min-h-[150px] w-full flex items-center justify-between">
              <div className="">
                <h3 className="text-primary font-medium">SMS</h3>
                <p className="text-sm">Receive SMS notifications</p>
              </div>
              <Switch />
            </div>
          </div>
          <div className="w-full flex flex-col gap-3">
            <h1 className="text-lg font-medium text-primary">
              Broadcast message
            </h1>
            <Form {...form}>
              <form
                className="flex flex-col gap-3 max-w-sm"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <FormField
                  control={form.control}
                  name="importerType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Importer Type</FormLabel>
                      <FormControl>
                        <Select
                          //   {...field}
                          value={field.value}
                          name={field.name}
                          onValueChange={(val) => {
                            form.setValue("importerType", val);
                            field.onBlur();
                          }}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select Importer Type" />
                          </SelectTrigger>
                          <SelectContent>
                            {[
                              "all",
                              "individual",
                              "agent",
                              "company",
                              "government",
                            ].map((type) => (
                              <SelectItem key={type} value={type}>
                                {type}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Message</FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder="Type a message here"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <Button type="submit" className="ml-auto">
                  Save
                </Button>
              </form>
            </Form>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}
