import React, { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Bar } from "recharts";
import { ChartConfig, ChartTooltipContent } from "@/components/ui/chart";
import { ChartTooltip } from "@/components/ui/chart";
import { BarChart, XAxis } from "recharts";
import { YAxis } from "recharts";
import { ChartContainer } from "@/components/ui/chart";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const filters = [
  {
    title: "Daily",
    value: "daily",
  },
  {
    title: "Weekly",
    value: "weekly",
  },
  {
    title: "Monthly",
    value: "monthly",
  },
  {
    title: "Quarterly",
    value: "quarterly",
  },
  {
    title: "Annually",
    value: "annually",
  },
];

const chartData = [
  { category: "Abia", value: 100, fill: "var(--color-abia)" },
  { category: "Adamawa", value: 150, fill: "var(--color-adamawa)" },
  { category: "Akwa Ibom", value: 200, fill: "var(--color-akwaIbom)" },
  { category: "Anambra", value: 120, fill: "var(--color-anambra)" },
  { category: "Bauchi", value: 90, fill: "var(--color-bauchi)" },
  { category: "Bayelsa", value: 80, fill: "var(--color-bayelsa)" },
  { category: "Benue", value: 110, fill: "var(--color-benue)" },
  { category: "Borno", value: 130, fill: "var(--color-borno)" },
  { category: "Cross River", value: 140, fill: "var(--color-crossRiver)" },
  { category: "Delta", value: 160, fill: "var(--color-delta)" },
  { category: "Ebonyi", value: 70, fill: "var(--color-ebonyi)" },
  { category: "Edo", value: 180, fill: "var(--color-edo)" },
  { category: "Ekiti", value: 75, fill: "var(--color-ekiti)" },
  { category: "Enugu", value: 95, fill: "var(--color-enugu)" },
  { category: "Gombe", value: 85, fill: "var(--color-gombe)" },
  { category: "Imo", value: 160, fill: "var(--color-imo)" },
  { category: "Jigawa", value: 90, fill: "var(--color-jigawa)" },
  { category: "Kaduna", value: 200, fill: "var(--color-kaduna)" },
  { category: "Kano", value: 220, fill: "var(--color-kano)" },
  { category: "Kogi", value: 100, fill: "var(--color-kogi)" },
  { category: "Kwara", value: 130, fill: "var(--color-kwara)" },
  { category: "Lagos", value: 300, fill: "var(--color-lagos)" },
  { category: "Nasarawa", value: 110, fill: "var(--color-nasarawa)" },
  { category: "Niger", value: 140, fill: "var(--color-niger)" },
  { category: "Ogun", value: 150, fill: "var(--color-ogun)" },
  { category: "Ondo", value: 120, fill: "var(--color-ondo)" },
  { category: "Osun", value: 130, fill: "var(--color-osun)" },
  { category: "Oyo", value: 160, fill: "var(--color-oyo)" },
  { category: "Plateau", value: 90, fill: "var(--color-plateau)" },
  { category: "Rivers", value: 250, fill: "var(--color-rivers)" },
  { category: "Sokoto", value: 80, fill: "var(--color-sokoto)" },
  { category: "Taraba", value: 70, fill: "var(--color-taraba)" },
  { category: "Yobe", value: 60, fill: "var(--color-yobe)" },
  { category: "Zamfara", value: 50, fill: "var(--color-zamfara)" },
];

// Split chartData into three parts
const chunkSize = Math.ceil(chartData.length / 3);
const chartChunks = [
  chartData.slice(0, chunkSize),
  chartData.slice(chunkSize, chunkSize * 2),
  chartData.slice(chunkSize * 2),
];

const chartConfig = {
  abia: { label: "Abia", color: "#005C00" },
  adamawa: { label: "Adamawa", color: "#005C00" },
  akwaIbom: { label: "Akwa Ibom", color: "#005C00" },
  anambra: { label: "Anambra", color: "#005C00" },
  bauchi: { label: "Bauchi", color: "#005C00" },
  bayelsa: { label: "Bayelsa", color: "#005C00" },
  benue: { label: "Benue", color: "#005C00" },
  borno: { label: "Borno", color: "#005C00" },
  crossRiver: { label: "Cross River", color: "#005C00" },
  delta: { label: "Delta", color: "#005C00" },
  ebonyi: { label: "Ebonyi", color: "#005C00" },
  edo: { label: "Edo", color: "#005C00" },
  ekiti: { label: "Ekiti", color: "#005C00" },
  enugu: { label: "Enugu", color: "#005C00" },
  gombe: { label: "Gombe", color: "#005C00" },
  imo: { label: "Imo", color: "#005C00" },
  jigawa: { label: "Jigawa", color: "#005C00" },
  kaduna: { label: "Kaduna", color: "#005C00" },
  kano: { label: "Kano", color: "#005C00" },
  kogi: { label: "Kogi", color: "#005C00" },
  kwara: { label: "Kwara", color: "#005C00" },
  lagos: { label: "Lagos", color: "#005C00" },
  nasarawa: { label: "Nasarawa", color: "#005C00" },
  niger: { label: "Niger", color: "#005C00" },
  ogun: { label: "Ogun", color: "#005C00" },
  ondo: { label: "Ondo", color: "#005C00" },
  osun: { label: "Osun", color: "#005C00" },
  oyo: { label: "Oyo", color: "#005C00" },
  plateau: { label: "Plateau", color: "#005C00" },
  rivers: { label: "Rivers", color: "#005C00" },
  sokoto: { label: "Sokoto", color: "#005C00" },
  taraba: { label: "Taraba", color: "#005C00" },
  yobe: { label: "Yobe", color: "#005C00" },
  zamfara: { label: "Zamfara", color: "#005C00" },
} satisfies ChartConfig;

const tickData = {
  stroke: "#000000",
  strokeWidth: 1,
  fill: "#000000",
  fontSize: 14,
};

export default function RevenueByState() {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);
  return (
    <div className="w-full p-5 flex flex-col gap-5 shadow-[0_4px_20px_0px_#0000001A] rounded-xl">
      <div className="flex justify-between items-center">
        <h4 className="font-semibold text-primary-700 flex">
          Revenue By Category
        </h4>
        <Select>
          <SelectTrigger className="w-fit">
            <div className="flex items-center gap-2 text-primary-700 font-semibold">
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <SelectValue placeholder="Filters" />
            </div>
          </SelectTrigger>
          <SelectContent>
            {filters.map((filter) => (
              <SelectItem key={filter.value} value={filter.value}>
                {filter.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="">
        <Carousel
          opts={{
            align: "start",
          }}
          setApi={setApi}
          className="w-full h-full flex flex-col"
        >
          <CarouselContent>
            {chartChunks.map((chunk, chunkIndex) => (
              <CarouselItem key={chunkIndex} className="">
                <div className="p-1">
                  <ChartContainer
                    className="h-full w-full"
                    config={chartConfig}
                  >
                    <BarChart
                      accessibilityLayer
                      data={chunk}
                      layout="vertical"
                      margin={{
                        left: 0,
                      }}
                    >
                      <YAxis
                        dataKey="category"
                        type="category"
                        tick={tickData}
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        width={200}
                      />
                      <XAxis dataKey="value" type="number" hide />
                      <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent />}
                      />
                      <Bar dataKey="value" layout="vertical" radius={5} />
                    </BarChart>
                  </ChartContainer>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="w-full mt-auto flex items-center justify-center py-5 gap-4">
            <CarouselPrevious className=" translate-y-0 w-10 h-10" />
            {current} of {count}
            <CarouselNext className="translate-y-0 w-10 h-10" />
          </div>
        </Carousel>
      </div>
    </div>
  );
}
