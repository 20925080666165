import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import {
  ImportDocumentationData,
  RegistrationPaymentHistoryData,
} from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { PERMISSIONS } from "@/lib/permissions";
import { Select } from "@/components/ui/select";
import {
  AGENT_PERMISSIONS,
  COMPANY_PERMISSIONS,
  GOVERNMENT_PERMISSIONS,
  INDIVIDUAL_PERMISSIONS,
} from "@/lib/permissions";
import { INITIAL_STATE_PERMISSIONS } from "@/lib/permissions";

export const RolesPermissionsColumns: ColumnDef<any>[] = [
  {
    accessorKey: "adminname",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Admin Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "adminId",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Admin ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "role",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Role
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },

  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const [selectedPermissions, setSelectedPermissions] = React.useState(
        INITIAL_STATE_PERMISSIONS
      );

      const permission_templates = {
        agent: AGENT_PERMISSIONS,
        company: COMPANY_PERMISSIONS,
        government: GOVERNMENT_PERMISSIONS,
        individual: INDIVIDUAL_PERMISSIONS,
      };
      return (
        <div className="grid w-fit grid-cols-2 gap-7">
          <Dialog>
            <DialogTrigger className="underline text-primary-700 text-sm">
              Remove
            </DialogTrigger>
            <DialogContent></DialogContent>
          </Dialog>
          <Dialog>
            <DialogTrigger className="underline text-primary-700 text-sm">
              Edit
            </DialogTrigger>
            <DialogContent className="w-full min-w-[700px] max-h-[85vh] overflow-y-auto">
              <div className="flex flex-col gap-2">
                <h3 className="font-medium text-primary-700">Assign Roles</h3>
                <div className="w-full p-2 bg-secondary-50 grid grid-cols-3">
                  <h1 className="uppercase text-xl font-medium">module</h1>
                  <h1 className="uppercase text-xl font-medium">permissions</h1>
                </div>
                {PERMISSIONS.map((permission) => (
                  <div className="w-full p-3 bg-[#F1F1F1] grid grid-cols-3">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id={permission.module}
                        checked={
                          selectedPermissions.find(
                            (p) => p.module === permission.module
                          )?.isAllowed
                        }
                        onCheckedChange={(isChecked) => {
                          setSelectedPermissions((prev) => {
                            return prev.map((p) => {
                              if (p.module === permission.module) {
                                return {
                                  ...p,
                                  isAllowed: isChecked as boolean,
                                  permissions: isChecked
                                    ? permission.permissions
                                    : [],
                                };
                              }
                              return p;
                            });
                          });
                        }}
                      />
                      <Label
                        className="capitalize cursor-pointer text-xs font-normal"
                        htmlFor={permission.module}
                      >
                        {permission.module.split("-").join(" ")}
                      </Label>
                    </div>
                    {permission.permissions.map((perm, index) => (
                      <div className="flex items-center gap-2">
                        <Checkbox
                          id={`${perm}-${index}-${permission.module}`}
                          checked={selectedPermissions
                            .find((p) => p.module === permission.module)
                            ?.permissions.includes(perm)}
                          onCheckedChange={(isChecked) => {
                            setSelectedPermissions((prev) => {
                              return prev.map((p) => {
                                if (p.module === permission.module) {
                                  return {
                                    ...p,
                                    permissions: isChecked
                                      ? [...p.permissions, perm]
                                      : p.permissions.filter((p) => p !== perm),
                                  };
                                }
                                return p;
                              });
                            });
                          }}
                        />
                        <Label
                          className="capitalize cursor-pointer text-xs font-normal"
                          htmlFor={`${perm}-${index}-${permission.module}`}
                        >
                          {perm}
                        </Label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    },
  },
];
