import React from "react";
import { Button } from "../ui/button";

export default function ConfirmationModal({
  cta,
  title,
}: {
  cta: { handleClick: () => void; txt: string };
  title: string;
}) {
  return (
    <div className="w-full h-full fixed top-0 left-0 flex items-center justify-center bg-black/50 z-[9999999999] backdrop-blur-sm">
      <div className="w-full max-w-md h-fit bg-white rounded-lg p-5 flex flex-col gap-5">
        <h4 className="text-2xl font-semibold text-primary-700">{title}</h4>
        <Button onClick={cta.handleClick} className="w-full">
          {cta.txt}
        </Button>
      </div>
    </div>
  );
}
