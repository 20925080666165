import { ScrollArea } from "@/components/ui/scroll-area";
import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import { useStore } from "@/contexts/userStore";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import UserSwitch from "@/components/ui/UserSwitch";
import { generateBreadcrumbs } from "@/lib/utils";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { isLoggedIn } from "@/lib/auth";

[
  {
    label: "Admin",
    href: "/admin",
  },
  {
    label: "Profile Management",
    href: "/admin/profile-management",
  },
  {
    label: "Registrations",
    href: "/admin/profile-management/registrations",
  },
];

export default function AdminLayout() {
  const userStore = useStore((state) => state);
  const { pathname } = useLocation();
  const breadCrumbs = generateBreadcrumbs(pathname);
  const isAdmin = userStore.user.account_type == "admin";
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate("/dashboard");
    }
  }, [isAdmin]);
  return (
    <ScrollArea className="h-screen w-full max-w-full">
      <div className="flex w-full relative">
        <div className="flex-[0.3] sticky top-0 left-0 h-screen">
          <Sidebar />
        </div>
        <div className="flex-1 overflow-hidden">
          <DashboardHeader />
          <div className="p-12 space-y-5 w-full flex flex-col">
            <Breadcrumb>
              <BreadcrumbList>
                {breadCrumbs.slice(1).map((crumb, index) =>
                  index + 2 != breadCrumbs.length ? (
                    <React.Fragment key={crumb.href}>
                      <BreadcrumbItem>
                        <BreadcrumbLink
                          className="text-black/50 font-light capitalize"
                          href={crumb.href}
                        >
                          {crumb.label}
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator className="text-black/50" />
                    </React.Fragment>
                  ) : (
                    <BreadcrumbItem key={crumb.href}>
                      <BreadcrumbPage className="text-primary-500 font-medium">
                        {crumb.label}
                      </BreadcrumbPage>
                    </BreadcrumbItem>
                  )
                )}
              </BreadcrumbList>
            </Breadcrumb>

            <Outlet />
          </div>
        </div>
        <UserSwitch />
      </div>
    </ScrollArea>
  );
}
