import SignupForm from "@/components/forms/SignupForm";
import { ChevronLeft } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

export default function SignUp() {
  return (
    <div className="w-full grid md:grid-cols-2 grid-cols-1 h-screen">
      <div className="relative">
        <div
          className="md:flex hidden absolute top-0 left-0 w-full h-full z-10 brightness-50 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url('/images/signup-backdrop.png')` }}
        ></div>
        <div className="w-full h-full z-20 relative flex flex-col items-center justify-center text-center">
          <h1 className="text-9xl font-bold text-primary-foreground">NESCAP</h1>
          <p className="text-lg font-medium text-primary-foreground">
            NESREA CONFORMITY ASSESSMENT PORTAL
          </p>
          <p className="bg-primary-50 rounded-lg p-3 max-w-md text-primary/70 font-medium border-primary-500 border-2 uppercase">
            For further enquiries, visit us at... No. 56 lome crescent, wuse
            zone 7, Abuja, Nigeria. <br />
            Contact us at: <br />
            dg@nesrea.gov.ng, info@nesrea.gov.ng, +2349153993191
          </p>
        </div>
      </div>

      <div className="flex flex-col relative">
        <div className="w-full p-3">
          <Link
            className="flex items-center text-primary underline text-sm"
            to={"/"}
          >
            <ChevronLeft size={20} />
            Go Home
          </Link>
        </div>
        <SignupForm />
      </div>
    </div>
  );
}
