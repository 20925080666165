import React from "react";
import AllClearanceApplicationsTable from "./all-clearance-applications-table";
import DashboardHeader from "@/components/ui/dashboard-header";

export default function ImporterApplicationsView() {
  return (
    <>
      <DashboardHeader
        title="Import Clearance Certificate"
        caption="Welcome to the application process"
      />
      <div className="flex flex-col gap-4">
        <AllClearanceApplicationsTable />
      </div>
    </>
  );
}
