import React from "react";
import { TrendingUp } from "lucide-react";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

const chartData = [
  { month: "January", trend: 186 },
  { month: "February", trend: 305 },
  { month: "March", trend: 237 },
  { month: "April", trend: 73 },
  { month: "May", trend: 209 },
  { month: "June", trend: 214 },
  { month: "July", trend: 150 },
  { month: "August", trend: 300 },
  { month: "September", trend: 200 },
  { month: "October", trend: 275 },
  { month: "November", trend: 230 },
  { month: "December", trend: 190 },
];

const chartConfig = {
  desktop: {
    label: "trend",
    color: "rgb(var(--primary))",
  },
} satisfies ChartConfig;

const tickData = { stroke: "#A1A1A1", strokeWidth: 0.25, fontSize: 11 };

export default function MonthlyRevenueTrends() {
  return (
    <div className="pb-10">
      <ChartContainer config={chartConfig}>
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid strokeWidth={0.25} />
          <XAxis
            dataKey="month"
            tickLine={false}
            tick={tickData}
            axisLine={false}
            // tickMargin={8}
            label={{
              value: "Months",
              position: "insideBottom",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
            tickFormatter={(value) => value.slice(0, 3)}
          ></XAxis>
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={0}
            tick={tickData}
            label={{
              value: "Revenue",
              angle:-90,
              position: "center",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
          />

          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent />}
          />
          <Line
            dataKey="trend"
            type="linear"
            stroke="rgb(var(--primary))"
            strokeWidth={2}
            dot={{
              fill: "rgb(var(--primary))",
            }}
          />
        </LineChart>
      </ChartContainer>
    </div>
  );
}
