import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectItem,
  SelectValue,
  SelectTrigger,
  SelectContent,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

const PAYMENT_CYCLES = [
  "1 week",
  "2 weeks",
  "1 month",
  "2 months",
  "3 months",
  "6 months",
  "1 year",
  "2 years",
];

export default function GenerateInvoiceForm() {
  const generateInvoiceSchema = z.object({
    id: z.string().min(1),
    email: z.string().email(),
    amount: z.number().min(1),
    description: z.string().min(1),
    paymentCycle: z.enum([
      "1 week",
      "2 weeks",
      "1 month",
      "2 months",
      "3 months",
      "6 months",
      "1 year",
      "2 years",
    ]),
  });
  const form = useForm<z.infer<typeof generateInvoiceSchema>>({
    resolver: zodResolver(generateInvoiceSchema),
    defaultValues: {
      id: "",
      email: "",
      amount: 0,
      description: "",
      paymentCycle: "1 week",
    },
  });
  return (
    <div>
      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          onSubmit={form.handleSubmit(async (data) => {
            
          })}
        >
          <FormField
            control={form.control}
            name="id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  NESREA ID <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="ID" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Email <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Amount <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    placeholder="Amount"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Description <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Description" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="paymentCycle"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Payment Cycle <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Select {...field}>
                    <SelectTrigger>
                      <SelectValue placeholder="Payment Cycle" />
                    </SelectTrigger>
                    <SelectContent>
                      {PAYMENT_CYCLES.map((cycle) => (
                        <SelectItem key={cycle} value={cycle}>
                          {cycle}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Dialog>
            <DialogTrigger disabled={!form.formState.isValid} asChild>
              <Button className="w-full max-w-[200px]" type="button">
                Generate Invoice
              </Button>
            </DialogTrigger>
            <DialogContent>
              <div
                className="w-full aspect-[9/10] border-4 border-primary-500 bg-contain bg-center bg-no-repeat"
                style={{ backgroundImage: `url('/images/cert.png')` }}
              ></div>
              <Button className="w-full max-w-[80%] mx-auto">Send</Button>
              <Link
                to={"/images/cert.png"}
                target="_blank"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "w-full max-w-[80%] mx-auto border-none"
                )}
              >
                Download
              </Link>
            </DialogContent>
          </Dialog>
        </form>
      </Form>
    </div>
  );
}
