import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React from "react";

const generateRandomValue = () => Math.floor(Math.random() * 100);

const HeatMapData = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
].map((day) => ({
  label: day,
  periods: [
    { label: "00:00 - 03:00", value: generateRandomValue() },
    { label: "03:00 - 06:00", value: generateRandomValue() },
    { label: "06:00 - 09:00", value: generateRandomValue() },
    { label: "09:00 - 12:00", value: generateRandomValue() },
    { label: "12:00 - 15:00", value: generateRandomValue() },
    { label: "15:00 - 18:00", value: generateRandomValue() },
    { label: "18:00 - 21:00", value: generateRandomValue() },
    { label: "21:00 - 00:00", value: generateRandomValue() },
  ],
}));

export default function UserLoginHeatMap() {
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          User Login Heat Map
        </h1>
        <Sheet>
          <SheetTrigger
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-transparent text-primary-700 hover:text-primary-foreground flex gap-1 items-center"
            )}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Filters
          </SheetTrigger>
          <SheetContent className="bg-secondary-50 flex flex-col gap-5 py-10">
            {/* Filters Here */}
          </SheetContent>
        </Sheet>
      </div>
      <div className="w-full min-h-[50vh] flex items-center justify-center flex-col gap-5">
        <div className="flex flex-col w-full max-w-xs">
          <div className="w-full bg-gradient-to-r from-primary-50 via-primary-600 to-primary-700 h-5" />
          <div className="w-full flex items-center justify-between">
            <h5 className="text-primary-700 text-sm">Low</h5>
            <h5 className="text-primary-700 text-sm">Medium</h5>
            <h5 className="text-primary-700 text-sm">High</h5>
          </div>
        </div>
        <div className="flex flex-col w-full">
          {HeatMapData.map((item) => (
            <div className="flex">
              <h5 className="p-3 border-r text-sm w-[100px]">{item.label}</h5>
              {item.periods.map((period) => (
                <div
                  className={cn(
                    "w-[100px] flex items-center justify-center aspect-[1.6/1] ",
                    period.value >= 0 && period.value < 50 && "bg-primary-100",
                    period.value >= 50 && "bg-primary-500",
                    period.value >= 75 &&
                      "bg-primary-900 text-primary-foreground"
                  )}
                >
                  x
                </div>
              ))}
            </div>
          ))}
          <div className="flex items-center w-full">
            <div className="w-[100px]"></div>
            {HeatMapData[0].periods.map((item) => (
              <h5 className="text-sm w-[100px] p-3 flex items-center justify-center text-center">
                {item.label.split("-")[0]}
                <br />
                {item.label.split("-")[1]}
              </h5>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
