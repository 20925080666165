import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { PasswordInput } from "@/components/ui/password-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { userStoreInitialState, useStore } from "@/contexts/userStore";
import Cookies from "js-cookie";

const FormSchema = z.object({
  email: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  password: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
});

export default function SignInForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const userRole = searchParams.get("account_type");
  const navigate = useNavigate();
  const userStore = useStore();

  useEffect(() => {
    if (!searchParams.get("account_type")) {
      setSearchParams({ account_type: "user" });
    }
  }, [searchParams]);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  async function onSubmitUser(values: z.infer<typeof FormSchema>) {
    try {
      setIsLoading(true);
      let {
        data: { session_id, userLoginInfo, message, user_Profile_info },
      } = await axios.post(`${getBackendUrl()}/user-login`, {
        email: values.email,
        password: values.password,
      });

      if (userLoginInfo.email_verification_status == null) {
        toast.error("Your email is not verified", {
          description: "Please verify your email to continue",
        });
        setIsLoading(false);
        return;
      }

      const user_data: userStoreInitialState["user"] = {
        id: userLoginInfo.id,
        profile_updated: user_Profile_info != null ? true : false,
        registration_status:
          user_Profile_info?.action_taken_registration === null
            ? "pending"
            : user_Profile_info?.action_taken_registration == "Approved"
            ? "verified"
            : "unverified",
        registration_payment_status: user_Profile_info?.payment_status
          ? "paid"
          : "unpaid",
        registration_payment_date: "",
        nesrea_id: userLoginInfo.nesrea_id,
        name: user_Profile_info?.importer_name || "",
        email: userLoginInfo.email,
        account_type: userLoginInfo.account_type,
        email_verification_status: userLoginInfo.email_verification_status,
        created_at: userLoginInfo.created_at,
        updated_at: userLoginInfo.updated_at,
      };

      // store user data in local storage
      localStorage.setItem("nesrea_session_id", session_id);
      localStorage.setItem("nesrea_user", JSON.stringify(user_data));
      toast.success("Login successful", {
        description: <>You can now access your dashboard</>,
      });
      userStore.setUser(user_data);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while signing in", {
        description: "Please try again",
      });
      setIsLoading(false);
    }
  }
  async function onSubmitAdmin(values: z.infer<typeof FormSchema>) {
    try {
      setIsLoading(true);
      let {
        data: { session_id, user, message, user_Profile_info },
      } = await axios.post(`${getBackendUrl()}/admin-login`, {
        email: values.email,
        password: values.password,
      });
      const user_data: userStoreInitialState["user"] = {
        id: user.id,
        nesrea_id: "",
        profile_updated: false,
        registration_status: user.registration_status,
        registration_payment_status: user.registration_payment_status,
        registration_payment_date: "",
        name: user.name,
        email: user.email,
        account_type: user.account_type,
        email_verification_status: user.email_verification_status,
        created_at: user.created_at,
        updated_at: user.updated_at,
      };

      // store user data in local storage
      localStorage.setItem("nesrea_session_id", session_id);
      localStorage.setItem("nesrea_user", JSON.stringify(user_data));
      toast.success("Login successful", {
        description: <>You can now access your dashboard</>,
      });

      userStore.setUser(user_data);

      navigate("/admin/dashboard");
    } catch (error) {
      toast.error("An error occurred while signing in", {
        description: "Please try again",
      });
      setIsLoading(false);
    }
  }
  return (
    <div className="w-full max-w-lg shadow-lg m-auto p-12 flex flex-col items-center gap-7 text-center">
      <img src="/images/Logo.png" className="w-[200px]" alt="" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(
            userRole == "user" ? onSubmitUser : onSubmitAdmin
          )}
          className="max-w-sm w-full space-y-6 text-left"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-primary capitalize">
                  {`${userRole}`} Email{" "}
                  <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="example@mail.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-primary">
                  Password <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <PasswordInput placeholder="********" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button disabled={isLoading} type="submit" className="w-full">
            {isLoading ? "Signing in..." : "Sign in"}
          </Button>
        </form>
      </Form>
      <div className="flex flex-col gap-2">
        <Link to="/auth/sign-up" className="text-primary underline text-sm">
          Don't have an account? Sign up
        </Link>
      </div>
    </div>
  );
}
