import { z } from "zod";

export const company_form_schema_page_1 = z.object({
  companyName: z.string().min(2, {
    message: "Company Name must be at least 2 characters.",
  }),
  companyRepresentative: z.string().min(2, {
    message: "Company Representative must be at least 2 characters.",
  }),
  companyAddress: z.string().min(2, {
    message: "Company Address must be at least 2 characters.",
  }),
  tin_cac: z.string().min(2, {
    message: "TIN/CAC must be at least 2 characters.",
  }),
  association: z.string().min(2, { message: "Association is required" }),
  nin: z
    .string()
    .min(11, {
      message: "NIN must be at least 11 digits.",
    })
    .max(11, { message: "NIN must not be more than 11 digits." }),
});

export const company_form_schema_page_2 = z.object({
  association: z.string().min(2, { message: "Association is required" }),
  nin: z
    .string()
    .min(11, {
      message: "NIN must be at least 11 digits.",
    })
    .max(11, { message: "NIN must not be more than 11 digits." }),
});

export type company_form_schema_page_1_type = z.infer<
  typeof company_form_schema_page_1
>;
export type company_form_schema_page_2_type = z.infer<
  typeof company_form_schema_page_2
>;
