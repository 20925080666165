import React from "react";
import { DataTable } from "./data-table";
import { ImportDocumentationColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { importDocumentationData } from "@/lib/dummy-data/importDocumentationData";

export default function ImportDocumentationTable() {
  const agentStore = useStore((state) => state);
  return (
    <div>
      <DataTable
        columns={ImportDocumentationColumns}
        data={importDocumentationData}
      />
    </div>
  );
}
