import React from "react";
import { DataTable } from "./data-table";
import { ImportDetailsDisplayAdminTableColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";

export default function ImportDetailsDisplayAdminTable() {
  return (
    <div>
      <DataTable columns={ImportDetailsDisplayAdminTableColumns} data={[]} />
    </div>
  );
}
