import DashboardModal from "@/components/modals/DashboardModal";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronLeft } from "lucide-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

export default function VerifyCertificatePage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const formSchema = z.object({
    fullName: z.string().min(2, { message: "Please enter the full name" }),
    certificateNumber: z
      .string()
      .min(5, { message: "Please enter the certificate number" }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      certificateNumber: "",
    },
  });
  return (
    <div className="w-full h-screen flex flex-col items-center">
      <div className="w-full p-3">
        <Link
          className="flex items-center text-primary underline text-sm"
          to={"/"}
        >
          <ChevronLeft size={20} />
          Go Home
        </Link>
      </div>
      {showModal && (
        <DashboardModal
          title=""
          description=""
          cta={{
            txt: "Back to Homepage",
            onClick: () => {
              navigate("/");
              setShowModal(false);
            },
          }}
        />
      )}
      <div className="my-auto flex flex-col items-center gap-2">
        <div
          className="w-[200px] aspect-square bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url('/images/Logo.png')` }}
        />
        <Form {...form}>
          <form
            className="w-full max-w-md mx-auto space-y-3"
            onSubmit={form.handleSubmit(
              ({ fullName, certificateNumber }) => {}
            )}
          >
            <h4 className="font-medium text-lg text-center">
              Enter the following details to verify import clearance certificate
            </h4>
            <FormField
              control={form.control}
              name="fullName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Full Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter your full name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="certificateNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Certificate Number</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Enter your certificate number"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" disabled={!form.formState.isValid}>
              Verify
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
