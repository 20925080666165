// export const PERMISSIONS = [
//   {
//     module: "profile-management",
//     permissions: ["create", "manage"],
//   },
//   {
//     module: "clearance-request",
//     permissions: ["view", "create"],
//   },
//   {
//     module: "waivers-requests",
//     permissions: ["view", "create"],
//   },
//   {
//     module: "client-profile",
//     permissions: ["create", "manage"],
//   },
//   {
//     module: "invoice",
//     permissions: ["create", "manage"],
//   },
//   {
//     module: "registration-payments",
//     permissions: ["pay", "waiver"],
//   },
//   {
//     module: "clearance-payment",
//     permissions: ["pay", "waiver"],
//   },
//   {
//     module: "certificates",
//     permissions: ["view", "download"],
//   },
//   {
//     module: "notifications",
//     permissions: ["receive"],
//   },
//   {
//     module: "support",
//     permissions: ["submit"],
//   },
// ];

type Permission = {
  module: string;
  isAllowed: boolean;
  permissions: string[];
};

export const PERMISSIONS: Permission[] = [
  {
    module: "profile",
    isAllowed: true,
    permissions: ["create", "manage"],
  },
  {
    module: "application",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "waivers",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "client",
    isAllowed: true,
    permissions: ["create", "manage"],
  },
  {
    module: "payments",
    isAllowed: true,
    permissions: ["pay", "waiver"],
  },
  {
    module: "certificates",
    isAllowed: true,
    permissions: ["view", "download"],
  },
  {
    module: "notifications",
    isAllowed: true,
    permissions: ["receive"],
  },
  {
    module: "support",
    isAllowed: true,
    permissions: ["submit"],
  },
];

export const INITIAL_STATE_PERMISSIONS: Permission[] = [
  {
    module: "profile",
    permissions: [],
    isAllowed: false,
  },
  {
    module: "application",
    permissions: [],
    isAllowed: false,
  },
  {
    module: "waivers",
    permissions: [],
    isAllowed: false,
  },
  {
    module: "client",
    permissions: [],
    isAllowed: false,
  },
  {
    module: "payments",
    permissions: [],
    isAllowed: false,
  },
  {
    module: "certificates",
    permissions: [],
    isAllowed: false,
  },
  {
    module: "notifications",
    permissions: [],
    isAllowed: false,
  },
  {
    module: "support",
    permissions: [],
    isAllowed: false,
  },
];

export const AGENT_PERMISSIONS: Permission[] = [
  {
    module: "profile",
    isAllowed: true,
    permissions: ["create", "manage"],
  },
  {
    module: "application",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "waivers",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "client",
    isAllowed: true,
    permissions: ["create", "manage"],
  },
  {
    module: "payments",
    isAllowed: true,
    permissions: ["pay", "waiver"],
  },
  {
    module: "certificates",
    isAllowed: true,
    permissions: ["view", "download"],
  },
  {
    module: "notifications",
    isAllowed: true,
    permissions: ["receive"],
  },
  {
    module: "support",
    isAllowed: true,
    permissions: ["submit"],
  },
];

export const COMPANY_PERMISSIONS: Permission[] = [
  {
    module: "profile",
    isAllowed: true,
    permissions: ["create", "manage"],
  },
  {
    module: "application",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "waivers",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "client",
    isAllowed: false,
    permissions: [],
  },
  {
    module: "payments",
    isAllowed: true,
    permissions: ["pay", "waiver"],
  },
  {
    module: "certificates",
    isAllowed: true,
    permissions: ["view", "download"],
  },
  {
    module: "notifications",
    isAllowed: true,
    permissions: ["receive"],
  },
  {
    module: "support",
    isAllowed: true,
    permissions: ["submit"],
  },
];

export const GOVERNMENT_PERMISSIONS: Permission[] = [
  {
    module: "profile",
    isAllowed: true,
    permissions: ["create", "manage"],
  },
  {
    module: "application",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "waivers",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "client",
    isAllowed: false,
    permissions: [],
  },
  {
    module: "payments",
    isAllowed: true,
    permissions: ["pay", "waiver"],
  },
  {
    module: "certificates",
    isAllowed: true,
    permissions: ["view", "download"],
  },
  {
    module: "notifications",
    isAllowed: true,
    permissions: ["receive"],
  },
  {
    module: "support",
    isAllowed: true,
    permissions: ["submit"],
  },
];
export const INDIVIDUAL_PERMISSIONS: Permission[] = [
  {
    module: "profile",
    isAllowed: true,
    permissions: ["create", "manage"],
  },
  {
    module: "application",
    isAllowed: true,
    permissions: ["view", "create"],
  },
  {
    module: "waivers",
    isAllowed: false,
    permissions: [],
  },
  {
    module: "client",
    isAllowed: false,
    permissions: [],
  },
  {
    module: "payments",
    isAllowed: true,
    permissions: ["pay", "waiver"],
  },
  {
    module: "certificates",
    isAllowed: true,
    permissions: ["view", "download"],
  },
  {
    module: "notifications",
    isAllowed: true,
    permissions: ["receive"],
  },
  {
    module: "support",
    isAllowed: true,
    permissions: ["submit"],
  },
];
