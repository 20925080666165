import WaiverRequestForm from "@/components/forms/WaiverRequestForm";
import React from "react";

export default function WaiverApplicationPage() {
  return (
    <div>
      <WaiverRequestForm />
    </div>
  );
}
