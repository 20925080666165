import { Invoice } from "@/lib/interfaces";

export const invoices: Invoice[] = [
    {
        id: "1",
        importerName: "Importer A",
        importerCategory: "Category 1",
        invoiceNumber: "INV-001",
        invoiceDate: "2023-01-01",
        status: "Unpaid",
    },
    {
        id: "2",
        importerName: "Importer B",
        importerCategory: "Category 2",
        invoiceNumber: "INV-002",
        invoiceDate: "2023-01-02",
        status: "Paid",
    },
    {
        id: "3",
        importerName: "Importer C",
        importerCategory: "Category 1",
        invoiceNumber: "INV-003",
        invoiceDate: "2023-01-03",
        status: "Unpaid",
    },
    {
        id: "4",
        importerName: "Importer D",
        importerCategory: "Category 3",
        invoiceNumber: "INV-004",
        invoiceDate: "2023-01-04",
        status: "Paid",
    },
    {
        id: "5",
        importerName: "Importer E",
        importerCategory: "Category 2",
        invoiceNumber: "INV-005",
        invoiceDate: "2023-01-05",
        status: "Unpaid",
    },
];
