export const clientsData: any = [
  {
    name: "Acme Corp",
    category: "Company",
    dateCreated: "2024-09-15",
  },
  {
    name: "Jane Doe",
    category: "Individual",
    dateCreated: "2024-10-01",
  },
  {
    name: "Tech Innovations Ltd",
    category: "Company",
    dateCreated: "2024-10-10",
  },
  {
    name: "John Smith",
    category: "Individual",
    dateCreated: "2024-11-02",
  },
];
