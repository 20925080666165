import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "./button";
import { CircleArrowOutUpLeft } from "lucide-react";
import { useStore } from "@/contexts/userStore";
import { userTypes } from "@/lib/site-data";

export default function UserSwitch() {
  const [position, setPosition] = useState("bottom");
  const userStore = useStore((state) => state);
  return (
    <div className="fixed z-[99999999] bottom-0 right-0 p-5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="rounded-full aspect-square p-2 bg-white hover:bg-white/80"
          >
            <CircleArrowOutUpLeft size={15} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>User Types</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            value={userStore.user.account_type}
            onValueChange={setPosition}
          >
            {userTypes.map((type) => (
              <DropdownMenuRadioItem
                key={type}
                onClick={() =>
                  userStore.setUser({ ...userStore.user, account_type: type })
                }
                value={type}
                className="capitalize"
              >
                {type}
              </DropdownMenuRadioItem>
            ))}
            <DropdownMenuRadioItem
              key="admin"
              onClick={() =>
                userStore.setUser({ ...userStore.user, account_type: "admin" })
              }
              value="admin"
              className="capitalize"
            >
              admin
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
