export const userCategoryData = [
  {
    name: "Ebuka Folarin Abubakar",
    id: "NES/1234567891-1",
    dateCreated: "2024-09-28",
    lastActive: "2024-09-28",
    category: "agent",
    status: "active",
  },
  {
    name: "Mary Abu",
    id: "NES/1234567892-2",
    dateCreated: "2024-09-27",
    lastActive: "2024-09-27",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Emmanue Ojo",
    id: "NES/1234567893-3",
    dateCreated: "2024-09-25",
    lastActive: "2024-09-25",
    category: "company",
    status: "active",
  },
  {
    name: "UNIAJB",
    id: "NES/1234567894-4",
    dateCreated: "2024-09-28",
    lastActive: "2024-09-28",
    category: "government",
    status: "inactive",
  },
  {
    name: "John Doe",
    id: "NES/987654321-5",
    dateCreated: "2024-09-26",
    lastActive: "2024-09-26",
    category: "agent",
    status: "active",
  },
  {
    name: "Jane Smith",
    id: "NES/123456780-6",
    dateCreated: "2024-09-24",
    lastActive: "2024-09-24",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Alice Johnson",
    id: "NES/123456781-7",
    dateCreated: "2024-09-23",
    lastActive: "2024-09-23",
    category: "company",
    status: "active",
  },
  {
    name: "Bob Brown",
    id: "NES/123456782-8",
    dateCreated: "2024-09-22",
    lastActive: "2024-09-22",
    category: "government",
    status: "inactive",
  },
  {
    name: "Charlie Davis",
    id: "NES/123456783-9",
    dateCreated: "2024-09-21",
    lastActive: "2024-09-21",
    category: "agent",
    status: "active",
  },
  {
    name: "Diana Evans",
    id: "NES/123456784-10",
    dateCreated: "2024-09-20",
    lastActive: "2024-09-20",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Ethan Foster",
    id: "NES/123456785-11",
    dateCreated: "2024-09-19",
    lastActive: "2024-09-19",
    category: "company",
    status: "active",
  },
  {
    name: "Fiona Green",
    id: "NES/123456786-12",
    dateCreated: "2024-09-18",
    lastActive: "2024-09-18",
    category: "government",
    status: "inactive",
  },
  {
    name: "George Harris",
    id: "NES/123456787-13",
    dateCreated: "2024-09-17",
    lastActive: "2024-09-17",
    category: "agent",
    status: "active",
  },
  {
    name: "Hannah Ives",
    id: "NES/123456788-14",
    dateCreated: "2024-09-16",
    lastActive: "2024-09-16",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Ian Jones",
    id: "NES/123456789-15",
    dateCreated: "2024-09-15",
    lastActive: "2024-09-15",
    category: "company",
    status: "active",
  },
  {
    name: "Jack King",
    id: "NES/987654320-16",
    dateCreated: "2024-09-14",
    lastActive: "2024-09-14",
    category: "government",
    status: "inactive",
  },
  {
    name: "Kathy Lee",
    id: "NES/987654321-17",
    dateCreated: "2024-09-13",
    lastActive: "2024-09-13",
    category: "agent",
    status: "active",
  },
  {
    name: "Liam Miller",
    id: "NES/987654322-18",
    dateCreated: "2024-09-12",
    lastActive: "2024-09-12",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Mia Nelson",
    id: "NES/987654323-19",
    dateCreated: "2024-09-11",
    lastActive: "2024-09-11",
    category: "company",
    status: "active",
  },
  {
    name: "Noah O'Connor",
    id: "NES/987654324-20",
    dateCreated: "2024-09-10",
    lastActive: "2024-09-10",
    category: "government",
    status: "inactive",
  },
  {
    name: "Olivia Parker",
    id: "NES/987654325-21",
    dateCreated: "2024-09-09",
    lastActive: "2024-09-09",
    category: "agent",
    status: "active",
  },
  {
    name: "Paul Quinn",
    id: "NES/987654326-22",
    dateCreated: "2024-09-08",
    lastActive: "2024-09-08",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Quinn Roberts",
    id: "NES/987654327-23",
    dateCreated: "2024-09-07",
    lastActive: "2024-09-07",
    category: "company",
    status: "active",
  },
  {
    name: "Rita Smith",
    id: "NES/987654328-24",
    dateCreated: "2024-09-06",
    lastActive: "2024-09-06",
    category: "government",
    status: "inactive",
  },
  {
    name: "Sam Taylor",
    id: "NES/987654329-25",
    dateCreated: "2024-09-05",
    lastActive: "2024-09-05",
    category: "agent",
    status: "active",
  },
  {
    name: "Tina Underwood",
    id: "NES/987654330-26",
    dateCreated: "2024-09-04",
    lastActive: "2024-09-04",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Victor Vance",
    id: "NES/987654331-27",
    dateCreated: "2024-09-03",
    lastActive: "2024-09-03",
    category: "company",
    status: "active",
  },
  {
    name: "Wendy Xiong",
    id: "NES/987654332-28",
    dateCreated: "2024-09-02",
    lastActive: "2024-09-02",
    category: "government",
    status: "inactive",
  },
  {
    name: "Xander Young",
    id: "NES/987654333-29",
    dateCreated: "2024-09-01",
    lastActive: "2024-09-01",
    category: "agent",
    status: "active",
  },
  {
    name: "Yara Zane",
    id: "NES/987654334-30",
    dateCreated: "2024-08-31",
    lastActive: "2024-08-31",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Zoe Adams",
    id: "NES/987654335-31",
    dateCreated: "2024-08-30",
    lastActive: "2024-08-30",
    category: "company",
    status: "active",
  },
  {
    name: "Aaron Bell",
    id: "NES/987654336-32",
    dateCreated: "2024-08-29",
    lastActive: "2024-08-29",
    category: "government",
    status: "inactive",
  },
  {
    name: "Bella Carter",
    id: "NES/987654337-33",
    dateCreated: "2024-08-28",
    lastActive: "2024-08-28",
    category: "agent",
    status: "active",
  },
  {
    name: "Cody Diaz",
    id: "NES/987654338-34",
    dateCreated: "2024-08-27",
    lastActive: "2024-08-27",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Daisy Evans",
    id: "NES/987654339-35",
    dateCreated: "2024-08-26",
    lastActive: "2024-08-26",
    category: "company",
    status: "active",
  },
  {
    name: "Eli Fisher",
    id: "NES/987654340-36",
    dateCreated: "2024-08-25",
    lastActive: "2024-08-25",
    category: "government",
    status: "inactive",
  },
  {
    name: "Faye Grant",
    id: "NES/987654341-37",
    dateCreated: "2024-08-24",
    lastActive: "2024-08-24",
    category: "agent",
    status: "active",
  },
  {
    name: "Gabe Hall",
    id: "NES/987654342-38",
    dateCreated: "2024-08-23",
    lastActive: "2024-08-23",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Holly Ingram",
    id: "NES/987654343-39",
    dateCreated: "2024-08-22",
    lastActive: "2024-08-22",
    category: "company",
    status: "active",
  },
  {
    name: "Ian Johnson",
    id: "NES/987654344-40",
    dateCreated: "2024-08-21",
    lastActive: "2024-08-21",
    category: "government",
    status: "inactive",
  },
  {
    name: "Jade King",
    id: "NES/987654345-41",
    dateCreated: "2024-08-20",
    lastActive: "2024-08-20",
    category: "agent",
    status: "active",
  },
  {
    name: "Kyle Lee",
    id: "NES/987654346-42",
    dateCreated: "2024-08-19",
    lastActive: "2024-08-19",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Luna Moore",
    id: "NES/987654347-43",
    dateCreated: "2024-08-18",
    lastActive: "2024-08-18",
    category: "company",
    status: "active",
  },
  {
    name: "Mason Nelson",
    id: "NES/987654348-44",
    dateCreated: "2024-08-17",
    lastActive: "2024-08-17",
    category: "government",
    status: "inactive",
  },
  {
    name: "Nina Ortiz",
    id: "NES/987654349-45",
    dateCreated: "2024-08-16",
    lastActive: "2024-08-16",
    category: "agent",
    status: "active",
  },
  {
    name: "Oscar Perez",
    id: "NES/987654350-46",
    dateCreated: "2024-08-15",
    lastActive: "2024-08-15",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Paula Quinn",
    id: "NES/987654351-47",
    dateCreated: "2024-08-14",
    lastActive: "2024-08-14",
    category: "company",
    status: "active",
  },
  {
    name: "Quincy Reed",
    id: "NES/987654352-48",
    dateCreated: "2024-08-13",
    lastActive: "2024-08-13",
    category: "government",
    status: "inactive",
  },
  {
    name: "Rita Smith",
    id: "NES/987654353-49",
    dateCreated: "2024-08-12",
    lastActive: "2024-08-12",
    category: "agent",
    status: "active",
  },
  {
    name: "Steve Taylor",
    id: "NES/987654354-50",
    dateCreated: "2024-08-11",
    lastActive: "2024-08-11",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Tina Underwood",
    id: "NES/987654355-51",
    dateCreated: "2024-08-10",
    lastActive: "2024-08-10",
    category: "company",
    status: "active",
  },
  {
    name: "Victor Vance",
    id: "NES/987654356-52",
    dateCreated: "2024-08-09",
    lastActive: "2024-08-09",
    category: "government",
    status: "inactive",
  },
  {
    name: "Wendy Xiong",
    id: "NES/987654357-53",
    dateCreated: "2024-08-08",
    lastActive: "2024-08-08",
    category: "agent",
    status: "active",
  },
  {
    name: "Xena Young",
    id: "NES/987654358-54",
    dateCreated: "2024-08-07",
    lastActive: "2024-08-07",
    category: "individual",
    status: "inactive",
  },
  {
    name: "Yara Zane",
    id: "NES/987654359-55",
    dateCreated: "2024-08-06",
    lastActive: "2024-08-06",
    category: "company",
    status: "active",
  },
  {
    name: "Zoe Adams",
    id: "NES/987654360-56",
    dateCreated: "2024-08-05",
    lastActive: "2024-08-05",
    category: "government",
    status: "inactive",
  },
];
