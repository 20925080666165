import * as React from "react";
import { cn } from "@/lib/utils";
import { Input } from "./input"; // Import your existing Input component
import { EyeIcon, EyeOff } from "lucide-react";

export interface PasswordInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  val?: string;
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const togglePasswordVisibility = () => {
      setIsPasswordVisible((prevState) => !prevState);
    };

    return (
      <div className="relative w-full">
        <Input
          type={isPasswordVisible ? "text" : "password"}
          className={cn("pr-10", className)} // Adding padding for the icon
          ref={ref}
          {...props}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-primary/50"
        >
          {isPasswordVisible ? <EyeOff size={20} /> : <EyeIcon size={20} />}
        </button>
      </div>
    );
  }
);

PasswordInput.displayName = "PasswordInput";

export { PasswordInput };
