import React, { useEffect } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./components/DashboardHeader";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useStore } from "@/contexts/userStore";
import { useStore as useAgentStore } from "@/contexts/agentStore";
import DashboardAlert from "@/components/alerts/DashboardAlert";
import { clientsData } from "@/lib/dummy-data/clientsData";
import { applications } from "@/lib/dummy-data/applicationsData";
import UserSwitch from "@/components/ui/UserSwitch";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { generateBreadcrumbs } from "@/lib/utils";
import { isLoggedIn } from "@/lib/auth";

export const DashboardLayout = () => {
  const userStore = useStore((state) => state);
  const agentStore = useAgentStore((state) => state);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let isAllowed =
    userStore.user.profile_updated || pathname == "/dashboard/profile";
  let isAdmin = userStore.user.account_type == "admin";
  const breadCrumbs = generateBreadcrumbs(pathname);

  const fetchAgentData = async () => {
    if (agentStore.clients.length == 0) {
      // Fetch Clients
      agentStore.setClients(clientsData);

      // fetch applications
      agentStore.setApplications(applications);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      navigate("/admin/dashboard");
      return;
    }
    switch (userStore.user.account_type) {
      case "agent":
        fetchAgentData();
        break;

      default:
        break;
    }
  }, [userStore.user.account_type]);

  return (
    <ScrollArea className="h-screen">
      <div className="flex w-full relative">
        <div className="flex-[0.25] min-w-xs h-screen sticky top-0 left-0">
          <Sidebar />
        </div>
        <div className="px-12 py-7 flex-1 relative overflow-hidden">
          <div
            className="absolute top-0 right-0 bg-[70%_] bg-center bg-no-repeat w-full h-full opacity-5"
            style={{ backgroundImage: `url('/images/Logo.png')` }}
          ></div>
          <div className="w-full h-full z-10 relative flex flex-col gap-5">
            <DashboardHeader />
            <Breadcrumb>
              <BreadcrumbList>
                {breadCrumbs.slice(1).map((crumb, index) =>
                  index + 2 != breadCrumbs.length ? (
                    <React.Fragment key={crumb.href}>
                      <Link
                        to={crumb.href}
                        className="text-black/50 font-light capitalize"
                      >
                        {crumb.label}
                      </Link>
                      <BreadcrumbSeparator className="text-black/50" />
                    </React.Fragment>
                  ) : (
                    <BreadcrumbItem key={crumb.href}>
                      <BreadcrumbPage className="text-primary-500 font-medium">
                        {crumb.label}
                      </BreadcrumbPage>
                    </BreadcrumbItem>
                  )
                )}
              </BreadcrumbList>
            </Breadcrumb>


            {!isAllowed ? (
              <div className="flex items-center justify-center w-full h-[70vh]">
                <DashboardAlert
                  title="You Need to Update Your Profile"
                  cta={{ txt: "Go To Profile", href: "/dashboard/profile" }}
                  description="Keep your profile current with accurate information for a seamless experience and better service."
                />
              </div>
            ) : (
              <>
                <Outlet />
              </>
            )}
          </div>
        </div>
        <UserSwitch />
      </div>
    </ScrollArea>
  );
};
