import HSCodesTable from '@/components/tables/HSCodesTable';
import React from 'react'

export default function CategoriesPage() {
  return (
    <div>
      <HSCodesTable />
    </div>
  );
}
