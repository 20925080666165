import WaiverRequestsTable from "@/components/tables/WaiverRequestsTable";
import React from "react";

export default function WaiversView() {
  return (
    <div>
      <WaiverRequestsTable />
    </div>
  );
}
