export const HSCodes: {
  categoryId: string;
  label: string;
  code: string;
  items: {
    categoryId: string;
    label: string;
    code: string;
    items: {
      categoryId: string;
      label: string;
      code: string;
      items: {
        itemId: string;
        label: string;
        code: string;
      }[];
    }[];
  }[];
}[] = [
  {
    categoryId: "food-beverages",
    label: "Food & Beverages",
    code: "01",
    items: [
      {
        categoryId: "fruits",
        label: "Fruits",
        code: "10",
        items: [
          {
            categoryId: "citrus-fruits",
            label: "Citrus Fruits",
            code: "101",
            items: [
              { itemId: "oranges", label: "Oranges", code: "01" },
              { itemId: "lemons", label: "Lemons", code: "02" },
            ],
          },
          {
            categoryId: "berries",
            label: "Berries",
            code: "102",
            items: [
              { itemId: "strawberries", label: "Strawberries", code: "01" },
              { itemId: "blueberries", label: "Blueberries", code: "02" },
            ],
          },
        ],
      },
      {
        categoryId: "beverages",
        label: "Beverages",
        code: "20",
        items: [
          {
            categoryId: "soft-drinks",
            label: "Soft Drinks",
            code: "201",
            items: [
              { itemId: "cola", label: "Cola", code: "01" },
              { itemId: "lemonade", label: "Lemonade", code: "02" },
            ],
          },
          {
            categoryId: "alcoholic-drinks",
            label: "Alcoholic Drinks",
            code: "202",
            items: [
              { itemId: "beer", label: "Beer", code: "01" },
              { itemId: "wine", label: "Wine", code: "02" },
            ],
          },
        ],
      },
    ],
  },
  {
    categoryId: "textiles",
    label: "Textiles & Fabrics",
    code: "02",
    items: [
      {
        categoryId: "natural-fibers",
        label: "Natural Fibers",
        code: "30",
        items: [
          {
            categoryId: "cotton",
            label: "Cotton",
            code: "301",
            items: [
              { itemId: "raw-cotton", label: "Raw Cotton", code: "01" },
              { itemId: "cotton-yarn", label: "Cotton Yarn", code: "02" },
            ],
          },
          {
            categoryId: "wool",
            label: "Wool",
            code: "302",
            items: [
              { itemId: "raw-wool", label: "Raw Wool", code: "01" },
              { itemId: "wool-yarn", label: "Wool Yarn", code: "02" },
            ],
          },
        ],
      },
      {
        categoryId: "synthetic-fibers",
        label: "Synthetic Fibers",
        code: "40",
        items: [
          {
            categoryId: "polyester",
            label: "Polyester",
            code: "401",
            items: [
              {
                itemId: "polyester-fiber",
                label: "Polyester Fiber",
                code: "01",
              },
              { itemId: "polyester-yarn", label: "Polyester Yarn", code: "02" },
            ],
          },
          {
            categoryId: "nylon",
            label: "Nylon",
            code: "402",
            items: [
              { itemId: "nylon-fiber", label: "Nylon Fiber", code: "01" },
              { itemId: "nylon-yarn", label: "Nylon Yarn", code: "02" },
            ],
          },
        ],
      },
    ],
  },
  {
    categoryId: "electronics",
    label: "Electronics",
    code: "85",
    items: [
      {
        categoryId: "computers",
        label: "Computers",
        code: "50",
        items: [
          {
            categoryId: "laptops",
            label: "Laptops",
            code: "501",
            items: [
              { itemId: "gaming-laptop", label: "Gaming Laptop", code: "01" },
              {
                itemId: "business-laptop",
                label: "Business Laptop",
                code: "02",
              },
            ],
          },
          {
            categoryId: "desktops",
            label: "Desktops",
            code: "502",
            items: [
              { itemId: "workstation", label: "Workstation", code: "01" },
              { itemId: "all-in-one", label: "All-in-One", code: "02" },
            ],
          },
        ],
      },
      {
        categoryId: "mobile-devices",
        label: "Mobile Devices",
        code: "51",
        items: [
          {
            categoryId: "smartphones",
            label: "Smartphones",
            code: "511",
            items: [
              { itemId: "android-phone", label: "Android Phone", code: "01" },
              { itemId: "ios-phone", label: "iOS Phone", code: "02" },
            ],
          },
          {
            categoryId: "tablets",
            label: "Tablets",
            code: "512",
            items: [
              { itemId: "android-tablet", label: "Android Tablet", code: "01" },
              { itemId: "ios-tablet", label: "iOS Tablet", code: "02" },
            ],
          },
        ],
      },
    ],
  },
];
