import axios from "axios";
import { getBackendUrl } from "../utils";
import { useStore } from "@/contexts/userStore";
import { toast } from "sonner";

export const isLoggedIn = async (): Promise<boolean> => {
  // const sessionId = localStorage.getItem("nesrea_session_id");
  // if (!sessionId) {
  //   return false;
  // }
  // const {
  //   data: {
  //     content: { isValid, message },
  //   },
  // } = await axios.post(`${getBackendUrl()}/validate-session`, {
  //   session_id: sessionId,
  // });
  // if (!isValid) {
  //   localStorage.removeItem("nesrea_session_id");
  //   localStorage.removeItem("nesrea_user");
  // }

  return true;
};

export const logout = async () => {
  const user = JSON.parse(localStorage.getItem("nesrea_user") || "{}");
  try {
    // TODO: logout from backend

    //


    // Remove user data from local storage
    localStorage.removeItem("nesrea_user");
    localStorage.removeItem("nesrea_session_id");
    toast.success("Logged out successfully");
    window.location.href = "/auth/sign-in";
  } catch (error) {
    toast.error("Failed to logout");
  }
};
