import ClientsForm from '@/components/forms/ClientsForm';
import DashboardHeader from '@/components/ui/dashboard-header';
import React from 'react'

export default function AddClientsPage() {
  return (
    <div>
      <DashboardHeader
        title="Client Creation"
        caption="create a new client account."
      />
      <ClientsForm />
    </div>
  );
}
