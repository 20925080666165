import React from "react";

const Documents = [
  {
    Icon: () => (
      <svg
        width="41"
        height="38"
        viewBox="0 0 41 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3008 13H32.2438"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1883 19H18.3594V7"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1878 7V19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3558 19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 7H39.1864V1H1V23V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: "Proforma Invoice",
    description: "Mandatory document detailing the consignment.",
  },
  {
    Icon: () => (
      <svg
        width="41"
        height="38"
        viewBox="0 0 41 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3008 13H32.2438"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1883 19H18.3594V7"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1878 7V19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3558 19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 7H39.1864V1H1V23V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: "CAC",
    description: "Required for companies applying for clearance.",
  },
  {
    Icon: () => (
      <svg
        width="41"
        height="38"
        viewBox="0 0 41 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3008 13H32.2438"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1883 19H18.3594V7"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1878 7V19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3558 19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 7H39.1864V1H1V23V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: "TIN",
    description: "Proof of tax compliance for individuals or companies.",
  },
  {
    Icon: () => (
      <svg
        width="41"
        height="38"
        viewBox="0 0 41 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3008 13H32.2438"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1883 19H18.3594V7"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.1878 7V19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3558 19V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 7H39.1864V1H1V23V37"
          stroke="#005C00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: "MSDS",
    description: "Essential for hazardous or controlled goods.",
  },
];

export default function DocumentsRequired() {
  return (
    <div
      id="documents"
      data-aos="fade-in"
      data-aos-anchor-placement="top-center"
      className="w-full h-fit md:h-[70vh] relative flex items-center"
    >
      <div className="w-full bg-background h-full p-16 flex flex-col justify-center items-center text-center gap-10 relative z-10">
        <h1 className="text-3xl text-primary font-[500]">Documents Required</h1>
        <div className="grid lg:grid-cols-4 gap-10 w-full">
          {Documents.map(({ Icon, title, description }) => (
            <div
              key={title}
              className="w-full h-full text-left p-7 shadow-[0_4px_40px_0_#00000014] rounded-2xl max-h-[230px] aspect-[1.23/1] flex flex-col justify-center gap-3"
            >
              <Icon />
              <h3 className="font-semibold text-xl text-primary">{title}</h3>
              <p className="text-sm max-w-[90%]">{description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
