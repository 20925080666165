import React from 'react'
import ClearanceApplicationTable from '@/components/tables/clearanceApplicationTable'

export default function ClearanceApplicationPage() {
  return (
    <div>
      <ClearanceApplicationTable />
    </div>
  )
}
