import { STATES } from "./interfaces";

export const rootNavigationRoutes = [
  {
    txt: "Home",
    href: "/",
  },
  {
    txt: "Services",
    href: "#services",
  },
  {
    txt: "Clearance",
    href: "#clearance",
  },
  {
    txt: "Document",
    href: "#documents",
  },
  {
    txt: "Contact",
    href: "#contact",
  },
];

export const states = [
  { value: "abia", label: "Abia" },
  { value: "adamawa", label: "Adamawa" },
  { value: "akwa-ibom", label: "Akwa Ibom" },
  { value: "anambra", label: "Anambra" },
  { value: "bauchi", label: "Bauchi" },
  { value: "bayelsa", label: "Bayelsa" },
  { value: "benue", label: "Benue" },
  { value: "borno", label: "Borno" },
  { value: "cross-river", label: "Cross River" },
  { value: "delta", label: "Delta" },
  { value: "ebonyi", label: "Ebonyi" },
  { value: "edo", label: "Edo" },
  { value: "ekiti", label: "Ekiti" },
  { value: "enugu", label: "Enugu" },
  { value: "gombe", label: "Gombe" },
  { value: "imo", label: "Imo" },
  { value: "jigawa", label: "Jigawa" },
  { value: "kaduna", label: "Kaduna" },
  { value: "kano", label: "Kano" },
  { value: "katsina", label: "Katsina" },
  { value: "kebbi", label: "Kebbi" },
  { value: "kogi", label: "Kogi" },
  { value: "kwara", label: "Kwara" },
  { value: "lagos", label: "Lagos" },
  { value: "nasarawa", label: "Nasarawa" },
  { value: "niger", label: "Niger" },
  { value: "ogun", label: "Ogun" },
  { value: "ondo", label: "Ondo" },
  { value: "osun", label: "Osun" },
  { value: "oyo", label: "Oyo" },
  { value: "plateau", label: "Plateau" },
  { value: "rivers", label: "Rivers" },
  { value: "sokoto", label: "Sokoto" },
  { value: "taraba", label: "Taraba" },
  { value: "yobe", label: "Yobe" },
  { value: "zamfara", label: "Zamfara" },
  { value: "fct", label: "Federal Capital Territory" },
];

export const LGAs = {
  Abia: [
    "Aba North",
    "Aba South",
    "Arochukwu",
    "Bende",
    "Ikwuano",
    "Isiala-Ngwa North",
    "Isiala-Ngwa South",
    "Isuikwato",
    "Obi Nwa",
    "Ohafia",
    "Osisioma",
    "Ngwa",
    "Ugwunagbo",
    "Ukwa East",
    "Ukwa West",
    "Umuahia North",
    "Umuahia South",
    "Umu-Neochi",
  ],
  Adamawa: [
    "Demsa",
    "Fufore",
    "Ganaye",
    "Gireri",
    "Gombi",
    "Guyuk",
    "Hong",
    "Jada",
    "Lamurde",
    "Madagali",
    "Maiha",
    "Mayo-Belwa",
    "Michika",
    "Mubi North",
    "Mubi South",
    "Numan",
    "Shelleng",
    "Song",
    "Toungo",
    "Yola North",
    "Yola South",
  ],
  Anambra: [
    "Aguata",
    "Anambra East",
    "Anambra West",
    "Anaocha",
    "Awka North",
    "Awka South",
    "Ayamelum",
    "Dunukofia",
    "Ekwusigo",
    "Idemili North",
    "Idemili south",
    "Ihiala",
    "Njikoka",
    "Nnewi North",
    "Nnewi South",
    "Ogbaru",
    "Onitsha North",
    "Onitsha South",
    "Orumba North",
    "Orumba South",
    "Oyi",
  ],
  "Akwa Ibom": [
    "Abak",
    "Eastern Obolo",
    "Eket",
    "Esit Eket",
    "Essien Udim",
    "Etim Ekpo",
    "Etinan",
    "Ibeno",
    "Ibesikpo Asutan",
    "Ibiono Ibom",
    "Ika",
    "Ikono",
    "Ikot Abasi",
    "Ikot Ekpene",
    "Ini",
    "Itu",
    "Mbo",
    "Mkpat Enin",
    "Nsit Atai",
    "Nsit Ibom",
    "Nsit Ubium",
    "Obot Akara",
    "Okobo",
    "Onna",
    "Oron",
    "Oruk Anam",
    "Udung Uko",
    "Ukanafun",
    "Uruan",
    "Urue-Offong/Oruko ",
    "Uyo",
  ],
  Bauchi: [
    "Alkaleri",
    "Bauchi",
    "Bogoro",
    "Damban",
    "Darazo",
    "Dass",
    "Ganjuwa",
    "Giade",
    "Itas/Gadau",
    "Jama'are",
    "Katagum",
    "Kirfi",
    "Misau",
    "Ningi",
    "Shira",
    "Tafawa-Balewa",
    "Toro",
    "Warji",
    "Zaki",
  ],
  Bayelsa: [
    "Brass",
    "Ekeremor",
    "Kolokuma/Opokuma",
    "Nembe",
    "Ogbia",
    "Sagbama",
    "Southern Jaw",
    "Yenegoa",
  ],
  Benue: [
    "Ado",
    "Agatu",
    "Apa",
    "Buruku",
    "Gboko",
    "Guma",
    "Gwer East",
    "Gwer West",
    "Katsina-Ala",
    "Konshisha",
    "Kwande",
    "Logo",
    "Makurdi",
    "Obi",
    "Ogbadibo",
    "Oju",
    "Okpokwu",
    "Ohimini",
    "Oturkpo",
    "Tarka",
    "Ukum",
    "Ushongo",
    "Vandeikya",
  ],
  Borno: [
    "Abadam",
    "Askira/Uba",
    "Bama",
    "Bayo",
    "Biu",
    "Chibok",
    "Damboa",
    "Dikwa",
    "Gubio",
    "Guzamala",
    "Gwoza",
    "Hawul",
    "Jere",
    "Kaga",
    "Kala/Balge",
    "Konduga",
    "Kukawa",
    "Kwaya Kusar",
    "Mafa",
    "Magumeri",
    "Maiduguri",
    "Marte",
    "Mobbar",
    "Monguno",
    "Ngala",
    "Nganzai",
    "Shani",
  ],
  "Cross River": [
    "Akpabuyo",
    "Odukpani",
    "Akamkpa",
    "Biase",
    "Abi",
    "Ikom",
    "Yarkur",
    "Odubra",
    "Boki",
    "Ogoja",
    "Yala",
    "Obanliku",
    "Obudu",
    "Calabar South",
    "Etung",
    "Bekwara",
    "Bakassi",
    "Calabar Municipality",
  ],
  Delta: [
    "Oshimili",
    "Aniocha",
    "Aniocha South",
    "Ika South",
    "Ika North-East",
    "Ndokwa West",
    "Ndokwa East",
    "Isoko south",
    "Isoko North",
    "Bomadi",
    "Burutu",
    "Ughelli South",
    "Ughelli North",
    "Ethiope West",
    "Ethiope East",
    "Sapele",
    "Okpe",
    "Warri North",
    "Warri South",
    "Uvwie",
    "Udu",
    "Warri Central",
    "Ukwani",
    "Oshimili North",
    "Patani",
  ],
  Ebonyi: [
    "Edda",
    "Afikpo",
    "Onicha",
    "Ohaozara",
    "Abakaliki",
    "Ishielu",
    "lkwo",
    "Ezza",
    "Ezza South",
    "Ohaukwu",
    "Ebonyi",
    "Ivo",
  ],
  Enugu: [
    "Enugu South,",
    "Igbo-Eze South",
    "Enugu North",
    "Nkanu",
    "Udi Agwu",
    "Oji-River",
    "Ezeagu",
    "IgboEze North",
    "Isi-Uzo",
    "Nsukka",
    "Igbo-Ekiti",
    "Uzo-Uwani",
    "Enugu Eas",
    "Aninri",
    "Nkanu East",
    "Udenu.",
  ],
  Edo: [
    "Esan North-East",
    "Esan Central",
    "Esan West",
    "Egor",
    "Ukpoba",
    "Central",
    "Etsako Central",
    "Igueben",
    "Oredo",
    "Ovia SouthWest",
    "Ovia South-East",
    "Orhionwon",
    "Uhunmwonde",
    "Etsako East",
    "Esan South-East",
  ],
  Ekiti: [
    "Ado",
    "Ekiti-East",
    "Ekiti-West",
    "Emure/Ise/Orun",
    "Ekiti South-West",
    "Ikere",
    "Irepodun",
    "Ijero,",
    "Ido/Osi",
    "Oye",
    "Ikole",
    "Moba",
    "Gbonyin",
    "Efon",
    "Ise/Orun",
    "Ilejemeje.",
  ],
  FCT: ["Abaji", "Abuja Municipal", "Bwari", "Gwagwalada", "Kuje", "Kwali"],
  Gombe: [
    "Akko",
    "Balanga",
    "Billiri",
    "Dukku",
    "Kaltungo",
    "Kwami",
    "Shomgom",
    "Funakaye",
    "Gombe",
    "Nafada/Bajoga",
    "Yamaltu/Delta.",
  ],
  Imo: [
    "Aboh-Mbaise",
    "Ahiazu-Mbaise",
    "Ehime-Mbano",
    "Ezinihitte",
    "Ideato North",
    "Ideato South",
    "Ihitte/Uboma",
    "Ikeduru",
    "Isiala Mbano",
    "Isu",
    "Mbaitoli",
    "Mbaitoli",
    "Ngor-Okpala",
    "Njaba",
    "Nwangele",
    "Nkwerre",
    "Obowo",
    "Oguta",
    "Ohaji/Egbema",
    "Okigwe",
    "Orlu",
    "Orsu",
    "Oru East",
    "Oru West",
    "Owerri-Municipal",
    "Owerri North",
    "Owerri West",
  ],
  Jigawa: [
    "Auyo",
    "Babura",
    "Birni Kudu",
    "Biriniwa",
    "Buji",
    "Dutse",
    "Gagarawa",
    "Garki",
    "Gumel",
    "Guri",
    "Gwaram",
    "Gwiwa",
    "Hadejia",
    "Jahun",
    "Kafin Hausa",
    "Kaugama Kazaure",
    "Kiri Kasamma",
    "Kiyawa",
    "Maigatari",
    "Malam Madori",
    "Miga",
    "Ringim",
    "Roni",
    "Sule-Tankarkar",
    "Taura",
    "Yankwashi",
  ],
  Kaduna: [
    "Birni-Gwari",
    "Chikun",
    "Giwa",
    "Igabi",
    "Ikara",
    "jaba",
    "Jema'a",
    "Kachia",
    "Kaduna North",
    "Kaduna South",
    "Kagarko",
    "Kajuru",
    "Kaura",
    "Kauru",
    "Kubau",
    "Kudan",
    "Lere",
    "Makarfi",
    "Sabon-Gari",
    "Sanga",
    "Soba",
    "Zango-Kataf",
    "Zaria",
  ],
  Kano: [
    "Ajingi",
    "Albasu",
    "Bagwai",
    "Bebeji",
    "Bichi",
    "Bunkure",
    "Dala",
    "Dambatta",
    "Dawakin Kudu",
    "Dawakin Tofa",
    "Doguwa",
    "Fagge",
    "Gabasawa",
    "Garko",
    "Garum",
    "Mallam",
    "Gaya",
    "Gezawa",
    "Gwale",
    "Gwarzo",
    "Kabo",
    "Kano Municipal",
    "Karaye",
    "Kibiya",
    "Kiru",
    "kumbotso",
    "Ghari",
    "Kura",
    "Madobi",
    "Makoda",
    "Minjibir",
    "Nasarawa",
    "Rano",
    "Rimin Gado",
    "Rogo",
    "Shanono",
    "Sumaila",
    "Takali",
    "Tarauni",
    "Tofa",
    "Tsanyawa",
    "Tudun Wada",
    "Ungogo",
    "Warawa",
    "Wudil",
  ],
  Katsina: [
    "Bakori",
    "Batagarawa",
    "Batsari",
    "Baure",
    "Bindawa",
    "Charanchi",
    "Dandume",
    "Danja",
    "Dan Musa",
    "Daura",
    "Dutsi",
    "Dutsin-Ma",
    "Faskari",
    "Funtua",
    "Ingawa",
    "Jibia",
    "Kafur",
    "Kaita",
    "Kankara",
    "Kankia",
    "Katsina",
    "Kurfi",
    "Kusada",
    "Mai'Adua",
    "Malumfashi",
    "Mani",
    "Mashi",
    "Matazuu",
    "Musawa",
    "Rimi",
    "Sabuwa",
    "Safana",
    "Sandamu",
    "Zango",
  ],
  Kebbi: [
    "Aleiro",
    "Arewa-Dandi",
    "Argungu",
    "Augie",
    "Bagudo",
    "Birnin Kebbi",
    "Bunza",
    "Dandi",
    "Fakai",
    "Gwandu",
    "Jega",
    "Kalgo",
    "Koko/Besse",
    "Maiyama",
    "Ngaski",
    "Sakaba",
    "Shanga",
    "Suru",
    "Wasagu/Danko",
    "Yauri",
    "Zuru",
  ],
  Kogi: [
    "Adavi",
    "Ajaokuta",
    "Ankpa",
    "Bassa",
    "Dekina",
    "Ibaji",
    "Idah",
    "Igalamela-Odolu",
    "Ijumu",
    "Kabba/Bunu",
    "Kogi",
    "Lokoja",
    "Mopa-Muro",
    "Ofu",
    "Ogori/Mangongo",
    "Okehi",
    "Okene",
    "Olamabolo",
    "Omala",
    "Yagba East",
    "Yagba West",
  ],
  Kwara: [
    "Asa",
    "Baruten",
    "Edu",
    "Ekiti",
    "Ifelodun",
    "Ilorin East",
    "Ilorin West",
    "Irepodun",
    "Isin",
    "Kaiama",
    "Moro",
    "Offa",
    "Oke-Ero",
    "Oyun",
    "Pategi",
  ],
  Lagos: [
    "Agege",
    "Ajeromi-Ifelodun",
    "Alimosho",
    "Amuwo-Odofin",
    "Apapa",
    "Badagry",
    "Epe",
    "Eti-Osa",
    "Ibeju/Lekki",
    "Ifako-Ijaye",
    "Ikeja",
    "Ikorodu",
    "Kosofe",
    "Lagos Island",
    "Lagos Mainland",
    "Mushin",
    "Ojo",
    "Oshodi-Isolo",
    "Shomolu",
    "Surulere",
  ],
  Nasarawa: [
    "Akwanga",
    "Awe",
    "Doma",
    "Karu",
    "Keana",
    "Keffi",
    "Kokona",
    "Lafia",
    "Nasarawa",
    "Nasarawa-Eggon",
    "Obi",
    "Toto",
    "Wamba",
  ],
  Niger: [
    "Agaie",
    "Agwara",
    "Bida",
    "Borgu",
    "Bosso",
    "Chanchaga",
    "Edati",
    "Gbako",
    "Gurara",
    "Katcha",
    "Kontagora",
    "Lapai",
    "Lavun",
    "Magama",
    "Mariga",
    "Mashegu",
    "Mokwa",
    "Muya",
    "Pailoro",
    "Rafi",
    "Rijau",
    "Shiroro",
    "Suleja",
    "Tafa",
    "Wushishi",
  ],
  Ogun: [
    "Abeokuta North",
    "Abeokuta South",
    "Ado-Odo/Ota",
    "Yewa North",
    "Yewa South",
    "Ewekoro",
    "Ifo",
    "Ijebu East",
    "Ijebu North",
    "Ijebu North East",
    "Ijebu Ode",
    "Ikenne",
    "Imeko-Afon",
    "Ipokia",
    "Obafemi-Owode",
    "Ogun Waterside",
    "Odeda",
    "Odogbolu",
    "Remo North",
    "Shagamu",
  ],
  Ondo: [
    "Akoko North East",
    "Akoko North West",
    "Akoko South Akure East",
    "Akoko South West",
    "Akure North",
    "Akure South",
    "Ese-Odo",
    "Idanre",
    "Ifedore",
    "Ilaje",
    "Ile-Oluji",
    "Okeigbo",
    "Irele",
    "Odigbo",
    "Okitipupa",
    "Ondo East",
    "Ondo West",
    "Ose",
    "Owo",
  ],
  Osun: [
    "Aiyedade",
    "Aiyedire",
    "Atakumosa East",
    "Atakumosa West",
    "Boluwaduro",
    "Boripe",
    "Ede North",
    "Ede South",
    "Egbedore",
    "Ejigbo",
    "Ife Central",
    "Ife East",
    "Ife North",
    "Ife South",
    "Ifedayo",
    "Ifelodun",
    "Ila",
    "Ilesha East",
    "Ilesha West",
    "Irepodun",
    "Irewole",
    "Isokan",
    "Iwo",
    "Obokun",
    "Odo-Otin",
    "Ola-Oluwa",
    "Olorunda",
    "Oriade",
    "Orolu",
    "Osogbo",
  ],
  Oyo: [
    "Afijio",
    "Akinyele",
    "Atiba",
    "Atisbo",
    "Egbeda",
    "Ibadan Central",
    "Ibadan North",
    "Ibadan North West",
    "Ibadan South East",
    "Ibadan South West",
    "Ibarapa Central",
    "Ibarapa East",
    "Ibarapa North",
    "Ido",
    "Irepo",
    "Iseyin",
    "Itesiwaju",
    "Iwajowa",
    "Kajola",
    "Lagelu Ogbomosho North",
    "Ogbomosho South",
    "Ogo Oluwa",
    "Olorunsogo",
    "Oluyole",
    "Ona-Ara",
    "Orelope",
    "Ori Ire",
    "Oyo East",
    "Oyo West",
    "Saki East",
    "Saki West",
    "Surulere",
  ],
  Plateau: [
    "Barikin Ladi",
    "Bassa",
    "Bokkos",
    "Jos East",
    "Jos North",
    "Jos South",
    "Kanam",
    "Kanke",
    "Langtang North",
    "Langtang South",
    "Mangu",
    "Mikang",
    "Pankshin",
    "Qua'an Pan",
    "Riyom",
    "Shendam",
    "Wase",
  ],
  Rivers: [
    "Abua/Odual",
    "Ahoada East",
    "Ahoada West",
    "Akuku Toru",
    "Andoni",
    "Asari-Toru",
    "Bonny",
    "Degema",
    "Emohua",
    "Eleme",
    "Etche",
    "Gokana",
    "Ikwerre",
    "Khana",
    "Obio/Akpor",
    "Ogba/Egbema/Ndoni",
    "Ogu/Bolo",
    "Okrika",
    "Omumma",
    "Opobo/Nkoro",
    "Oyigbo",
    "Port-Harcourt",
    "Tai",
  ],
  Sokoto: [
    "Binji",
    "Bodinga",
    "Dange-shnsi",
    "Gada",
    "Goronyo",
    "Gudu",
    "Gawabawa",
    "Illela",
    "Isa",
    "Kware",
    "kebbe",
    "Rabah",
    "Sabon birni",
    "Shagari",
    "Silame",
    "Sokoto North",
    "Sokoto South",
    "Tambuwal",
    "Tqngaza",
    "Tureta",
    "Wamako",
    "Wurno",
    "Yabo",
  ],
  Taraba: [
    "Ardo-kola",
    "Bali",
    "Donga",
    "Gashaka",
    "Cassol",
    "Ibi",
    "Jalingo",
    "Karin-Lamido",
    "Kurmi",
    "Lau",
    "Sardauna",
    "Takum",
    "Ussa",
    "Wukari",
    "Yorro",
    "Zing",
  ],
  Yobe: [
    "Bade",
    "Bursari",
    "Damaturu",
    "Fika",
    "Fune",
    "Geidam",
    "Gujba",
    "Gulani",
    "Jakusko",
    "Karasuwa",
    "Karawa",
    "Machina",
    "Nangere",
    "Nguru Potiskum",
    "Tarmua",
    "Yunusari",
    "Yusufari",
  ],
  Zamfara: [
    "Anka",
    "Bakura",
    "Birnin Magaji",
    "Bukkuyum",
    "Bungudu",
    "Gummi",
    "Gusau",
    "Kaura",
    "Namoda",
    "Maradun",
    "Maru",
    "Shinkafi",
    "Talata Mafara",
    "Tsafe",
    "Zurmi",
  ],
};

export const stateNamesMap: { [key: string]: STATES } = {
  abia: "Abia",
  adamawa: "Adamawa",
  "akwa-ibom": "Akwa Ibom",
  anambra: "Anambra",
  bauchi: "Bauchi",
  bayelsa: "Bayelsa",
  benue: "Benue",
  borno: "Borno",
  "cross-river": "Cross River",
  delta: "Delta",
  ebonyi: "Ebonyi",
  edo: "Edo",
  ekiti: "Ekiti",
  enugu: "Enugu",
  "federal-capital-territory": "FCT",
  gombe: "Gombe",
  imo: "Imo",
  jigawa: "Jigawa",
  kaduna: "Kaduna",
  kano: "Kano",
  katsina: "Katsina",
  kebbi: "Kebbi",
  kogi: "Kogi",
  kwara: "Kwara",
  lagos: "Lagos",
  nasarawa: "Nasarawa",
  niger: "Niger",
  ogun: "Ogun",
  ondo: "Ondo",
  osun: "Osun",
  oyo: "Oyo",
  plateau: "Plateau",
  rivers: "Rivers",
  sokoto: "Sokoto",
  taraba: "Taraba",
  yobe: "Yobe",
  zamfara: "Zamfara",
};

export type UserType = "agent" | "company" | "government" | "individual"|"admin";
export const userTypes: UserType[] = [
  "agent",
  "company",
  "government",
  "individual",
];

export const units = [
  { id: "kg", label: "Kilogram" },
  { id: "tons", label: "Tons" },
  { id: "metric-tonnes", label: "Metric Tonnes" },
  { id: "barrels", label: "Barrels" },
  { id: "drums", label: "Drums" },
  { id: "grammes", label: "Grammes" },
  { id: "pounds", label: "Pounds" },
  { id: "litres", label: "Litres" },
];

export const countries = [
  { id: "AD", label: "Andorra" },
  { id: "AE", label: "United Arab Emirates" },
  { id: "AF", label: "Afghanistan" },
  { id: "AG", label: "Antigua and Barbuda" },
  { id: "AI", label: "Anguilla" },
  { id: "AL", label: "Albania" },
  { id: "AM", label: "Armenia" },
  { id: "AO", label: "Angola" },
  { id: "AR", label: "Argentina" },
  { id: "AS", label: "American Samoa" },
  { id: "AT", label: "Austria" },
  { id: "AU", label: "Australia" },
  { id: "AW", label: "Aruba" },
  { id: "AX", label: "Åland Islands" },
  { id: "AZ", label: "Azerbaijan" },
  { id: "BA", label: "Bosnia and Herzegovina" },
  { id: "BB", label: "Barbados" },
  { id: "BD", label: "Bangladesh" },
  { id: "BE", label: "Belgium" },
  { id: "BF", label: "Burkina Faso" },
  { id: "BG", label: "Bulgaria" },
  { id: "BH", label: "Bahrain" },
  { id: "BI", label: "Burundi" },
  { id: "BJ", label: "Benin" },
  { id: "BL", label: "Saint Barthélemy" },
  { id: "BM", label: "Bermuda" },
  { id: "BN", label: "Brunei Darussalam" },
  { id: "BO", label: "Bolivia" },
  { id: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
  { id: "BR", label: "Brazil" },
  { id: "BS", label: "Bahamas" },
  { id: "BT", label: "Bhutan" },
  { id: "BV", label: "Bouvet Island" },
  { id: "BW", label: "Botswana" },
  { id: "BY", label: "Belarus" },
  { id: "BZ", label: "Belize" },
  { id: "CA", label: "Canada" },
  { id: "CC", label: "Cocos (Keeling) Islands" },
  { id: "CD", label: "Democratic Republic of the Congo" },
  { id: "CF", label: "Central African Republic" },
  { id: "CG", label: "Congo" },
  { id: "CH", label: "Switzerland" },
  { id: "CI", label: "Côte d'Ivoire" },
  { id: "CK", label: "Cook Islands" },
  { id: "CL", label: "Chile" },
  { id: "CM", label: "Cameroon" },
  { id: "CN", label: "China" },
  { id: "CO", label: "Colombia" },
  { id: "CR", label: "Costa Rica" },
  { id: "CU", label: "Cuba" },
  { id: "CV", label: "Cabo Verde" },
  { id: "CW", label: "Curaçao" },
  { id: "CX", label: "Christmas Island" },
  { id: "CY", label: "Cyprus" },
  { id: "CZ", label: "Czech Republic" },
  { id: "DE", label: "Germany" },
  { id: "DJ", label: "Djibouti" },
  { id: "DK", label: "Denmark" },
  { id: "DM", label: "Dominica" },
  { id: "DO", label: "Dominican Republic" },
  { id: "DZ", label: "Algeria" },
  { id: "EC", label: "Ecuador" },
  { id: "EE", label: "Estonia" },
  { id: "EG", label: "Egypt" },
  { id: "EH", label: "Western Sahara" },
  { id: "ER", label: "Eritrea" },
  { id: "ES", label: "Spain" },
  { id: "ET", label: "Ethiopia" },
  { id: "FI", label: "Finland" },
  { id: "FJ", label: "Fiji" },
  { id: "FM", label: "Micronesia" },
  { id: "FO", label: "Faroe Islands" },
  { id: "FR", label: "France" },
  { id: "GA", label: "Gabon" },
  { id: "GB", label: "United Kingdom" },
  { id: "GD", label: "Grenada" },
  { id: "GE", label: "Georgia" },
  { id: "GF", label: "French Guiana" },
  { id: "GG", label: "Guernsey" },
  { id: "GH", label: "Ghana" },
  { id: "GI", label: "Gibraltar" },
  { id: "GL", label: "Greenland" },
  { id: "GM", label: "Gambia" },
  { id: "GN", label: "Guinea" },
  { id: "GP", label: "Guadeloupe" },
  { id: "GQ", label: "Equatorial Guinea" },
  { id: "GR", label: "Greece" },
  { id: "GT", label: "Guatemala" },
  { id: "GU", label: "Guam" },
  { id: "GW", label: "Guinea-Bissau" },
  { id: "GY", label: "Guyana" },
  { id: "HK", label: "Hong Kong" },
  { id: "HM", label: "Heard Island and McDonald Islands" },
  { id: "HN", label: "Honduras" },
  { id: "HR", label: "Croatia" },
  { id: "HT", label: "Haiti" },
  { id: "HU", label: "Hungary" },
  { id: "ID", label: "Indonesia" },
  { id: "IE", label: "Ireland" },
  { id: "IL", label: "Israel" },
  { id: "IM", label: "Isle of Man" },
  { id: "IN", label: "India" },
  { id: "IO", label: "British Indian Ocean Territory" },
  { id: "IQ", label: "Iraq" },
  { id: "IR", label: "Iran" },
  { id: "IS", label: "Iceland" },
  { id: "IT", label: "Italy" },
  { id: "JE", label: "Jersey" },
  { id: "JM", label: "Jamaica" },
  { id: "JO", label: "Jordan" },
  { id: "JP", label: "Japan" },
  { id: "KE", label: "Kenya" },
  { id: "KG", label: "Kyrgyzstan" },
  { id: "KH", label: "Cambodia" },
  { id: "KI", label: "Kiribati" },
  { id: "KM", label: "Comoros" },
  { id: "KN", label: "Saint Kitts and Nevis" },
  { id: "KP", label: "North Korea" },
  { id: "KR", label: "South Korea" },
  { id: "KW", label: "Kuwait" },
  { id: "KY", label: "Cayman Islands" },
  { id: "KZ", label: "Kazakhstan" },
  { id: "LA", label: "Laos" },
  { id: "LB", label: "Lebanon" },
  { id: "LC", label: "Saint Lucia" },
  { id: "LI", label: "Liechtenstein" },
  { id: "LK", label: "Sri Lanka" },
  { id: "LR", label: "Liberia" },
  { id: "LS", label: "Lesotho" },
  { id: "LT", label: "Lithuania" },
  { id: "LU", label: "Luxembourg" },
  { id: "LV", label: "Latvia" },
  { id: "LY", label: "Libya" },
  { id: "MA", label: "Morocco" },
  { id: "MC", label: "Monaco" },
  { id: "MD", label: "Moldova" },
  { id: "ME", label: "Montenegro" },
  { id: "MF", label: "Saint Martin" },
  { id: "MG", label: "Madagascar" },
  { id: "MH", label: "Marshall Islands" },
  { id: "MK", label: "North Macedonia" },
  { id: "ML", label: "Mali" },
  { id: "MM", label: "Myanmar" },
  { id: "MN", label: "Mongolia" },
  { id: "MO", label: "Macao" },
  { id: "MP", label: "Northern Mariana Islands" },
  { id: "MQ", label: "Martinique" },
  { id: "MR", label: "Mauritania" },
  { id: "MS", label: "Montserrat" },
  { id: "MT", label: "Malta" },
  { id: "MU", label: "Mauritius" },
  { id: "MV", label: "Maldives" },
  { id: "MW", label: "Malawi" },
  { id: "MX", label: "Mexico" },
  { id: "MY", label: "Malaysia" },
  { id: "MZ", label: "Mozambique" },
  { id: "NA", label: "Namibia" },
  { id: "NC", label: "New Caledonia" },
  { id: "NE", label: "Niger" },
  { id: "NF", label: "Norfolk Island" },
  { id: "NG", label: "Nigeria" },
  { id: "NI", label: "Nicaragua" },
  { id: "NL", label: "Netherlands" },
  { id: "NO", label: "Norway" },
  { id: "NP", label: "Nepal" },
  { id: "NR", label: "Nauru" },
  { id: "NU", label: "Niue" },
  { id: "NZ", label: "New Zealand" },
  { id: "OM", label: "Oman" },
  { id: "PA", label: "Panama" },
  { id: "PE", label: "Peru" },
  { id: "PF", label: "French Polynesia" },
  { id: "PG", label: "Papua New Guinea" },
  { id: "PH", label: "Philippines" },
  { id: "PK", label: "Pakistan" },
  { id: "PL", label: "Poland" },
  { id: "PM", label: "Saint Pierre and Miquelon" },
  { id: "PN", label: "Pitcairn" },
  { id: "PR", label: "Puerto Rico" },
  { id: "PT", label: "Portugal" },
  { id: "PW", label: "Palau" },
  { id: "PY", label: "Paraguay" },
  { id: "QA", label: "Qatar" },
  { id: "RE", label: "Réunion" },
  { id: "RO", label: "Romania" },
  { id: "RS", label: "Serbia" },
  { id: "RU", label: "Russia" },
  { id: "RW", label: "Rwanda" },
  { id: "SA", label: "Saudi Arabia" },
  { id: "SB", label: "Solomon Islands" },
  { id: "SC", label: "Seychelles" },
  { id: "SD", label: "Sudan" },
  { id: "SE", label: "Sweden" },
  { id: "SG", label: "Singapore" },
  { id: "SH", label: "Saint Helena" },
  { id: "SI", label: "Slovenia" },
  { id: "SJ", label: "Svalbard and Jan Mayen" },
  { id: "SK", label: "Slovakia" },
  { id: "SL", label: "Sierra Leone" },
  { id: "SM", label: "San Marino" },
  { id: "SN", label: "Senegal" },
  { id: "SO", label: "Somalia" },
  { id: "SR", label: "Suriname" },
  { id: "SS", label: "South Sudan" },
  { id: "ST", label: "São Tomé and Príncipe" },
  { id: "SV", label: "El Salvador" },
  { id: "SX", label: "Sint Maarten" },
  { id: "SY", label: "Syria" },
  { id: "SZ", label: "Eswatini" },
  { id: "TC", label: "Turks and Caicos Islands" },
  { id: "TD", label: "Chad" },
  { id: "TF", label: "French Southern Territories" },
  { id: "TG", label: "Togo" },
  { id: "TH", label: "Thailand" },
  { id: "TJ", label: "Tajikistan" },
  { id: "TK", label: "Tokelau" },
  { id: "TL", label: "Timor-Leste" },
  { id: "TM", label: "Turkmenistan" },
  { id: "TN", label: "Tunisia" },
  { id: "TO", label: "Tonga" },
  { id: "TR", label: "Turkey" },
  { id: "TT", label: "Trinidad and Tobago" },
  { id: "TV", label: "Tuvalu" },
  { id: "TZ", label: "Tanzania" },
  { id: "UA", label: "Ukraine" },
  { id: "UG", label: "Uganda" },
  { id: "UM", label: "United States Minor Outlying Islands" },
  { id: "US", label: "United States" },
  { id: "UY", label: "Uruguay" },
  { id: "UZ", label: "Uzbekistan" },
  { id: "VA", label: "Vatican City" },
  { id: "VC", label: "Saint Vincent and the Grenadines" },
  { id: "VE", label: "Venezuela" },
  { id: "VG", label: "British Virgin Islands" },
  { id: "VI", label: "U.S. Virgin Islands" },
  { id: "VN", label: "Vietnam" },
  { id: "VU", label: "Vanuatu" },
  { id: "WF", label: "Wallis and Futuna" },
  { id: "WS", label: "Samoa" },
  { id: "XA", label: "Xavier" },
  { id: "XB", label: "Xavier" },
  { id: "XC", label: "Xavier" },
  { id: "XD", label: "Xavier" },
  { id: "YE", label: "Yemen" },
  { id: "YT", label: "Mayotte" },
  { id: "ZA", label: "South Africa" },
  { id: "ZM", label: "Zambia" },
  { id: "ZW", label: "Zimbabwe" },
];

export const portsData = [
  { id: "lagos-port-complex", label: "Lagos Port Complex" },
  { id: "tin-can-island-port", label: "Tin Can Island Port" },
  { id: "port-harcourt-port", label: "Port Harcourt Port" },
  { id: "onne-port", label: "Onne Port" },
  { id: "calabar-port", label: "Calabar Port" },
  { id: "warri-port", label: "Warri Port" },
  {
    id: "murtala-muhammed-international-airport",
    label: "Murtala Muhammed International Airport",
  },
];

export const importationPurposes = [
  { id: "commercial-trade", label: "Commercial Trade" },
  { id: "personal-use", label: "Personal Use" },
  { id: "manufacture", label: "Manufacture" },
  { id: "pharmaceuticals-and-medicals", label: "Pharmaceuticals And Medicals" },
  { id: "agriculture", label: "Agriculture" },
  { id: "constructions", label: "Constructions" },
];
