"use client";

import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronLeft, ChevronRight, Filter, PlusCircle } from "lucide-react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button, buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { arrayToCSV, cn, filterByDate } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { toast } from "sonner";
import CSVExport from "@/components/ui/csv-export";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const agentStore = useStore((state) => state);

  const [filterState, setFilterState] = React.useState<{
    prev: {
      selectedCategory: "all" | "individual" | "company";
      activePeriod:
        | "All Time"
        | "A day ago"
        | "A week ago"
        | "A month ago"
        | "Past 6 months";
      status: "pending" | "approved" | "all";
    };
    current: {
      selectedCategory: "all" | "individual" | "company";
      activePeriod:
        | "All Time"
        | "A day ago"
        | "A week ago"
        | "A month ago"
        | "Past 6 months";
      status: "pending" | "approved" | "all";
    };
  }>({
    prev: {
      selectedCategory: "all",
      activePeriod: "All Time",
      status: "all",
    },
    current: {
      selectedCategory: "all",
      activePeriod: "All Time",
      status: "all",
    },
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });

  return (
    <div className="rounded-xl border border-neutral-300 overflow-hidden flex flex-col gap-3">
      <div className="">
        <div className="flex items-center justify-between px-6 py-7 pb-0">
          <h4 className="text-lg text-primary-700 font-medium">Application</h4>
          <div className="flex items-center gap-2">
            <Sheet>
              <SheetTrigger
                className={cn(
                  buttonVariants({ variant: "default" }),
                  "bg-transparent text-primary-700 hover:text-primary-foreground flex gap-1 items-center"
                )}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Filters
              </SheetTrigger>
              <SheetContent className="bg-secondary-50 flex flex-col gap-5 py-10">
                {/* Filters Here */}
                <div className="flex items-center gap-2 font-light text-neutral-500 text-xl">
                  <Filter size={25} />
                  Filter
                </div>
                <div className="w-full rounded-xl shadow-md bg-white px-5">
                  <Accordion type="multiple">
                    <AccordionItem value="user-category">
                      <AccordionTrigger>Client Category</AccordionTrigger>
                      <AccordionContent className="flex flex-col gap-2">
                        {["all", "individual", "company"].map(
                          (category: any, index) => (
                            <div
                              key={`${category} ${index}`}
                              className="flex items-center capitalize"
                            >
                              <Checkbox
                                id={category}
                                checked={
                                  category ==
                                  filterState.current?.selectedCategory
                                }
                                onCheckedChange={(checked) => {
                                  if (checked)
                                    setFilterState((prev) => ({
                                      ...prev,
                                      current: {
                                        ...prev.current,
                                        selectedCategory: category,
                                      },
                                    }));
                                }}
                              />
                              <Label
                                htmlFor={category}
                                className="cursor-pointer pl-3"
                              >
                                {category}
                              </Label>
                            </div>
                          )
                        )}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="w-full rounded-xl shadow-md bg-white px-5">
                  <Accordion type="multiple">
                    <AccordionItem value="user-category">
                      <AccordionTrigger>Date Period</AccordionTrigger>
                      <AccordionContent className="flex flex-col gap-2">
                        {[
                          "All Time",
                          "A day ago",
                          "A week ago",
                          "A month ago",
                          "Past 6 months",
                        ].map((period: any, index) => (
                          <div
                            key={`${period} ${index}`}
                            className="flex items-center capitalize"
                          >
                            <Checkbox
                              id={period}
                              checked={
                                period == filterState.current?.activePeriod
                              }
                              onCheckedChange={(checked) => {
                                if (checked)
                                  setFilterState((prev) => ({
                                    ...prev,
                                    current: {
                                      ...prev.current,
                                      activePeriod: period,
                                    },
                                  }));
                              }}
                            />
                            <Label
                              htmlFor={period}
                              className="cursor-pointer pl-3"
                            >
                              {period}
                            </Label>
                          </div>
                        ))}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="w-full rounded-xl shadow-md bg-white px-5">
                  <Accordion type="multiple">
                    <AccordionItem value="user-category">
                      <AccordionTrigger>Application Status</AccordionTrigger>
                      <AccordionContent className="flex flex-col gap-2">
                        {["All", "Pending", "Approved"].map(
                          (status: any, index) => (
                            <div
                              key={`${status} ${index}`}
                              className="flex items-center capitalize"
                            >
                              <Checkbox
                                id={status}
                                checked={
                                  status.toLowerCase() ==
                                  filterState.current.status.toLowerCase()
                                }
                                onCheckedChange={(checked) => {
                                  if (checked)
                                    setFilterState((prev) => ({
                                      ...prev,
                                      current: {
                                        ...prev.current,
                                        status: status,
                                      },
                                    }));
                                }}
                              />
                              <Label
                                htmlFor={status}
                                className="cursor-pointer pl-3"
                              >
                                {status}
                              </Label>
                            </div>
                          )
                        )}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>

                <SheetClose className="w-full flex justify-end mt-auto">
                  <Button
                    className="w-full max-w-[150px]"
                    disabled={
                      JSON.stringify(filterState.current) ==
                      JSON.stringify(filterState.prev)
                    }
                    onClick={() => {
                      let new_data = agentStore.applications
                        .filter((data_item) =>
                          filterState.current.selectedCategory == "all"
                            ? true
                            : data_item.category.toLowerCase() ==
                              filterState.current.selectedCategory.toLowerCase()
                        )
                        .filter((data_item) =>
                          filterByDate(
                            data_item.dateCreated,
                            filterState.current.activePeriod
                          )
                        )
                        .filter((data_item) =>
                          filterState.current.status == "all"
                            ? true
                            : data_item.status.toLowerCase() ==
                              filterState.current.status.toLowerCase()
                        );
                      agentStore.setFilteredApplications(new_data);
                      setFilterState((prev) => ({
                        prev: { ...prev.current },
                        current: { ...prev.current },
                      }));
                    }}
                  >
                    Save
                  </Button>
                </SheetClose>
              </SheetContent>
            </Sheet>

            <Link
              className={cn(
                buttonVariants({ variant: "default" }),
                "flex items-center gap-1"
              )}
              to={"/dashboard/application/apply"}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00033 1.16602V12.8327M1.16699 6.99935H12.8337"
                  stroke="currentColor"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Apply
            </Link>
          </div>
        </div>
        <p className="text-sm text-neutral-500 px-6">
          Apply for Import Clearance Certificate
        </p>
      </div>
      <div className="border-y border-neutral-300">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="bg-secondary-50 hover:bg-secondary-50/80"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="text-neutral-600 px-6"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="border-neutral-300 border-none hover:bg-secondary-50"
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className="border-none px-6 text-sm text-neutral-800"
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft size={15} />
        </Button>
        <span className="text-xs">
          {table.getState().pagination.pageIndex + 1} of{" "}
          {table.getPageCount()}
        </span>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight size={15} />
        </Button>
      </div>
    </div>
  );
}
