import { Button, buttonVariants } from "@/components/ui/button";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { useStore } from "@/contexts/userStore";
import {
  cn,
  getBackendUrl,
  parseDocumentUrls,
  splitArrayIntoParts,
} from "@/lib/utils";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ImporterImportDetailsTable from "./_tables/ImportDetailsTable";
import { PaystackButton } from "react-paystack";
import { toast } from "sonner";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { ImportItem } from "@/lib/interfaces";

export default function ImportClearanceDetailsPage() {
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [waiverCode, setWaiverCode] = React.useState("");
  const userStore = useStore((state) => state.user);

  const { data, isLoading } = useQuery({
    queryKey: ["import-clearance-details", id],
    queryFn: async () => {
      const {
        data: { Clearance_info, ...data },
      }: {
        data: { Clearance_info: any; "List of Clearance_items": any };
      } = await axios.get(
        `${getBackendUrl()}/get-clearance-details/${userStore.email}`
      );
      const import_items: ImportItem[] = data["List of Clearance_items"].map(
        (item: any) => {
          return {
            itemName: item.item,
            hscode: item.hs_code,
            unit: item.item_unit,
            quantity: item.item_quantity,
            cargo_number: item.container_cargo_number,
            country_of_origin: item.country_of_origin,
            port_of_entry: item.port_of_entry,
            purpose_of_importation: item.purpose_of_importation,
            final_destination_address: item.final_destination_address,
            expected_date_of_arrival: item.expected_date_of_arrival,
          };
        }
      );
      return { Clearance_info, import_items };
    },
  });

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  const config = {
    reference: new Date().getTime().toString(),
    email: userStore.email,
    amount: 9000000 * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_test_8a7b7066803d17caed0fc52c5f94b2c0ad4b48ac",
  };

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="w-full flex flex-col gap-7">
        <h3 className="text-2xl font-medium">
          The following are details of the registration request:
        </h3>
        <div className="w-full grid grid-cols-2 gap-7">
          <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
            <h3 className="text-2xl font-semibold text-center">
              Importer Details
            </h3>
            <div className="w-full flex flex-col gap-6">
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Name</h6>
                <p className="text-neutral-500">
                  {data?.Clearance_info.importer_name}
                </p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Email</h6>
                <p className="text-neutral-500">
                  {data?.Clearance_info.importer_email}
                </p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">NESREA ID</h6>
                <p className="text-neutral-500">
                  {data?.Clearance_info.nesrea_id}
                </p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">
                  Importer Category
                </h6>
                <p className="text-neutral-500">
                  {data?.Clearance_info.importer_category}
                </p>
              </div>
            </div>
          </div>
          <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
            <h3 className="text-2xl font-semibold text-center">
              Documents Uploaded
            </h3>
            {data ? (
              <Carousel
                opts={{
                  align: "start",
                }}
                setApi={setApi}
                className="w-full h-full flex flex-col"
              >
                <CarouselContent>
                  {splitArrayIntoParts<{ name: string; url: string }>(
                    parseDocumentUrls(
                      data?.Clearance_info.document_url,
                      data?.Clearance_info.document_name
                    ),
                    3
                  ).map((sets, index) => (
                    <CarouselItem key={index} className="">
                      <div className="p-1">
                        <div className="w-full flex flex-col gap-6">
                          {sets.map((document, index) => (
                            <div
                              key={`${index}document`}
                              className="w-full flex items-center justify-between"
                            >
                              <div className="flex gap-2">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <mask
                                    id="mask0_3238_3301"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_3238_3301)">
                                    <path
                                      d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"
                                      fill="#1C1B1F"
                                    />
                                  </g>
                                </svg>
                                <p className="font-medium text-sm max-w-[250px]">
                                  {document.name}
                                </p>
                              </div>
                              <Dialog>
                                <DialogTrigger className="text-neutral-500 underline cursor-pointer text-xs">
                                  View Document
                                </DialogTrigger>
                                <DialogContent>
                                  <embed
                                    src={document.url}
                                    width="100%"
                                    height="600px"
                                    type="application/pdf"
                                  />

                                  <DialogClose
                                    className={cn(
                                      buttonVariants({ variant: "default" }),
                                      "px-8"
                                    )}
                                  >
                                    Close
                                  </DialogClose>
                                </DialogContent>
                              </Dialog>
                              {/* <Button
                              variant={"link"}
                              onClick={() =>
                                window.open(document.url, "_blank")
                              }
                            >
                              View Document
                            </Button> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <div className="w-full mt-auto flex items-center justify-center py-5 gap-4">
                  <CarouselPrevious className=" translate-y-0 w-10 h-10" />
                  {current} of {count}
                  <CarouselNext className="translate-y-0 w-10 h-10" />
                </div>
              </Carousel>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="w-full max-wk-[1050px]">
          <ImporterImportDetailsTable
            importDetails={data?.import_items || []}
          />
        </div>

        <div className="flex items-center gap-8 w-full justify-end">
          <Dialog>
            <DialogTrigger
              disabled={data?.Clearance_info.status == "NEW"}
              className={cn(
                buttonVariants({ variant: "default" }),
                "px-8 bg-transparent border-primary-700 border hover:bg-primary-700 hover:text-primary-foreground text-primary-700 w-full max-w-[150px]",
                data?.Clearance_info.status == "NEW" && "!cursor-not-allowed"
              )}
            >
              View Invoice
            </DialogTrigger>
            <DialogContent>
              <div
                className="w-full aspect-[9/12] bg-contain bg-center bg-no-repeat"
                style={{ backgroundImage: `url('/images/cert.png')` }}
              ></div>
              <Link
                to={"/images/cert.png"}
                target="_blank"
                className={cn(
                  buttonVariants({ variant: "default" }),
                  "w-full max-w-[80%] mx-auto border-none"
                )}
              >
                Download
              </Link>
            </DialogContent>
          </Dialog>
          <Dialog>
            <DialogTrigger
              disabled={data?.Clearance_info.status == "NEW"}
              className={cn(
                buttonVariants({ variant: "default" }),
                "px-8 border-primary-700 border w-full max-w-[150px]",
                data?.Clearance_info.status == "NEW" && "!cursor-not-allowed"
              )}
            >
              Proceed to Payment
            </DialogTrigger>
            <DialogContent>
              <div className="flex flex-col gap-2 text-center">
                <h4 className="text-sm font-medium">Payment Summary</h4>
                <p className="text-xs">Review your transaction details</p>
              </div>
              <div className="flex items-center justify-between">
                <h6 className="font-medium text-primary">Total</h6>
                <p className="text-neutral-500">₦9,000,000.00</p>
              </div>
              <p>Mastercard, Visa, Verve Accepted</p>

              {/* <Form {...form}>
              <form
                className="w-full flex flex-col gap-4"
                onSubmit={form.handleSubmit(({waiver_code}) => {
                  
                })}
              >
                <FormField
                  control={form.control}
                  name="waiver_code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Waiver Code</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form> */}

              <div className="flex flex-col gap-2">
                <p className="text-sm text-neutral-500">Waiver Code</p>
                <Input
                  value={waiverCode}
                  onChange={(e) => setWaiverCode(e.target.value)}
                />
              </div>
              <hr className="my-3" />
              <DialogClose className="w-fit">
                <PaystackButton
                  {...config}
                  text="Pay with Paystack"
                  className={cn(
                    buttonVariants({ variant: "outline" }),
                    "w-full max-w-[164px]"
                  )}
                  onSuccess={async (reference) => {
                    try {
                      await axios.post(
                        `${getBackendUrl()}/clearance-payment-response`,
                        {
                          reference: `${reference.reference}`,
                          importer_email: userStore.email,
                          amount: `${115000}`,
                          status: "success",
                        }
                      );
                    } catch (error) {
                      toast.error("An error occurred");
                    }
                  }}
                />
              </DialogClose>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </LoaderWrapper>
  );
}
