import { buttonVariants } from "@/components/ui/button";
import { Button } from "@/components/ui/button";
import CSVExport from "@/components/ui/csv-export";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function FalsifiedRecordsDetailsPage() {
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  return (
    <div className="flex flex-col gap-5">
      <div className="w-full flex justify-end">
        <Dialog>
          <DialogTrigger asChild>
            <Button
              className={cn(
                "bg-transparent text-primary-700 border hover:text-primary-foreground flex gap-1 items-center"
              )}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.66699 14.1667L10.0003 17.5M10.0003 17.5L13.3337 14.1667M10.0003 17.5V10M16.667 13.9524C17.6849 13.1117 18.3337 11.8399 18.3337 10.4167C18.3337 7.88536 16.2816 5.83333 13.7503 5.83333C13.5682 5.83333 13.3979 5.73833 13.3054 5.58145C12.2187 3.73736 10.2124 2.5 7.91699 2.5C4.46521 2.5 1.66699 5.29822 1.66699 8.75C1.66699 10.4718 2.3632 12.0309 3.48945 13.1613"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Export
            </Button>
          </DialogTrigger>
          <DialogContent>
            <div
              className="w-full aspect-[9/10] border-4 border-primary-500 bg-contain bg-center bg-no-repeat"
              style={{ backgroundImage: `url('/images/cert.png')` }}
            ></div>
            <Link
              to={"/images/cert.png"}
              target="_blank"
              className={cn(
                buttonVariants({ variant: "default" }),
                "w-full max-w-[80%] mx-auto border-none"
              )}
            >
              Download
            </Link>
            <DialogClose asChild>
              <Button variant="link" className="w-full max-w-[80%] mx-auto underline">
                Close
              </Button>
            </DialogClose>
          </DialogContent>
        </Dialog>
      </div>
      <div className="flex flex-col gap-5 w-full min-h-[300px] bg-primary-50 py-5 px-10 rounded-lg shadow-md shadow-primary-400">
        <h4 className="text-xl font-semibold text-center">Record details</h4>
        <div className="flex flex-col gap-2 text-lg">
          <h5>
            <strong>NESREA ID:</strong>NES/123456789
          </h5>
          <h5>
            <strong>Record type:</strong>FR-2024-00123
          </h5>
        </div>

        <br />

        <h4 className="text-lg font-semibold text-center">Overview</h4>
        <div className="grid grid-cols-2 w-full">
          <div className="flex flex-col gap-5 text-lg">
            <h5 className="flex gap-2">
              <strong>Date Reported:</strong>15-09-2024
            </h5>
            <h5 className="flex gap-2">
              <strong>Record type:</strong>Fraudulent certificate
            </h5>
            <h5 className="flex gap-2">
              <strong>Reported From:</strong>FCT, Abuja
            </h5>
            <h5 className="flex gap-2">
              <strong>Description:</strong>The submitted environmental
              compliance certificate is suspected to be fraudulent, as the
              certificate number is not found in our database. Discrepancies
              were noted in the issuing authority's stamp and signature. The
              business entity used this certificate to claim compliance with
              hazardous waste management regulations.
            </h5>
          </div>
          <div className="flex flex-col gap-1">
            <h6 className="text-lg font-semibold">Document Preview:</h6>
            <Link
              className="underline text-primary-700 text-sm"
              to="https://www.google.com"
            >
              Clearance Certificate
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
