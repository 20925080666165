import React from "react";
import PaymentsView from "./components/PaymentsView";
import { useStore } from "@/contexts/userStore";

export default function PaymentsPage() {
  const userStore = useStore();
  const { registration_status } = userStore.user;
  return registration_status == "verified" ? (
    <div>
      <PaymentsView />
    </div>
  ) : (
    <></>
  );
}
