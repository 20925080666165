import React, { useState } from "react";
import { UserCategoryDataTable } from "./data-table";
import { UserCategoryColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { Switch } from "@/components/ui/switch";
import { userCategoryData } from "@/lib/dummy-data/userCategoryData";

export type UserCategories = "agent" | "individual" | "company" | "government";



export default function UserCategoryTable({
  category,
}: {
  category: UserCategories;
}) {
  const agentStore = useStore((state) => state);
  const [showInactive, setShowInactive] = useState(false);

  const filteredData = userCategoryData.filter((item) => {
    const isActive = item.status === "active";
    return showInactive ? !isActive : isActive;
  });

  return (
    <div>
      <div className="flex items-center mb-4">
        <Switch checked={showInactive} onCheckedChange={setShowInactive} />
        <span className="ml-2">
          {showInactive ? "Show Only Active Accounts" : "Show Only Inactive Accounts"}
        </span>
      </div>
      <UserCategoryDataTable
        category={category}
        showInactive={showInactive}
        columns={UserCategoryColumns}
        data={filteredData}
      />
    </div>
  );
}
