import React from "react";
import Hero from "./components/Hero";
import OurServices from "./components/OurServices";
import DocumentsRequired from "./components/DocumentsRequired";
import ClearanceProcess from "./components/ClearanceProcess";
import ImageHero from "./components/ImageHero";
import Highlight from "./components/Highlight";
import VerificationProcess from "./components/VerificationProcess";

function Home() {
  return (
    <div className="w-full">
      <ImageHero />
      <Highlight />
      <DocumentsRequired />
      <ClearanceProcess />
      <VerificationProcess />
    </div>
  );
}

export default Home;
