import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import {
  ClearanceApplicationData,
  ImportDocumentationData,
  RegistrationPaymentHistoryData,
} from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "fecha";

export const ClearanceApplicationColumns: ColumnDef<ClearanceApplicationData>[] = [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          S/N
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Import Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "importerCategory",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Importer Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "applicationDate",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { applicationDate } = row.original;
      let formattedDate = format(new Date(applicationDate), "MMM DD, YYYY");
      return <p>{formattedDate}</p>;
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { status } = row.original;
      let isPending = status == "NEW";
      let isApproved = status == "Approved";
      let isRejected = status == "Disapproved";

      const status_dict: { [key: string]: string } = {
        NEW: "Pending",
        Approved: "Approved",
        Disapproved: "Rejected",
      };
      return (
        <p
          className={cn(
            "capitalize",
            isPending && "text-orange-400",
            isApproved && "text-primary-300",
            isRejected && "text-destructive"
          )}
        >
          {status_dict[status]}
        </p>
      );
    },
  },
  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div className="grid gap-7">
          <Link
            className="underline text-primary-700 text-sm"
            to={`/admin/clearance/clearance-application/details?id=${row.original.id}&&email=${row.original.importerEmail}`}
          >
            See details
          </Link>
        </div>
      );
    },
  },
];
