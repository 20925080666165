import RegistrationPaymentHistoryTable from '@/components/tables/registrationPaymentHistoryTable';
import React from 'react'

export default function PaymentPage() {
  return (
    <div>
      <RegistrationPaymentHistoryTable />
    </div>
  );
}
