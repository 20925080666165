import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import ImportDetailsDisplayTable from "@/components/tables/ImportDetailsDisplayTable";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PaymentForm } from "@/components/forms/PaymentForm";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { registrationsData } from "@/lib/dummy-data/registrationsData";
import { generatedWaivers } from "@/lib/dummy-data/generatedWaivers";

export default function GeneratedWaiverDetailsPage() {
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  let waiverDetails = generatedWaivers.find((data) => data.id == id);

  return (
    <div className="w-full flex flex-col gap-7">
      <h3 className="text-2xl font-medium">
        The following are details of the generated waiver:
      </h3>
      <div className="w-full grid grid-cols-2 gap-7">
        <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
          <h3 className="text-2xl font-semibold text-center">Waiver Details</h3>
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Waiver Type</h6>
              <p className="text-neutral-500">{waiverDetails?.waiverType}</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Waiver ID</h6>
              <p className="text-neutral-500">{waiverDetails?.id}</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Waiver Value</h6>
              <p className="text-neutral-500">{waiverDetails?.waiverValue}</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Percentage</h6>
              <p className="text-neutral-500">50%</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Validity Period</h6>
              <p className="text-neutral-500">6 months</p>
            </div>
          </div>
        </div>
        <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
          <h3 className="text-2xl font-semibold text-center">Waiver Usage</h3>
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Usage Status</h6>
              <p className="text-neutral-500">{waiverDetails?.status}</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Applied By</h6>
              <p className="text-neutral-500">NS/999/999/999</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Usage Date</h6>
              <p className="text-neutral-500">31st December 2024</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <Dialog>
          <DialogTrigger>
            <Button>View Waiver Code</Button>
          </DialogTrigger>
          <DialogContent>
            <div className="w-full flex flex-col gap-6">
              <h5 className="text-primary-500 text-center">
                {"Here's"} your generated waiver code
              </h5>
              <p className="text-primary-500 text-center text-xl font-semibold">
                ITEMSALE25
              </p>
              <DialogClose asChild>
                <Button>Close</Button>
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
