import { ImportItem } from "@/lib/interfaces";
import React, { useState } from "react";
import ApplicationFormPageOne from "./_form-pages/application_form_page_one";
import { DocumentsForm } from "@/components/forms/DocumentsForm";
import { useStore } from "@/contexts/userStore";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export const useImporterApplicationForm = (importer_email?: string) => {
  const [formPage, setFormPage] = useState<number>(1);
  const [importDetails, setImportDetails] = useState<ImportItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const userStore = useStore((state) => state.user);
  const navigate = useNavigate();

  const handleDocumentsUpload = async (documents: File[]) => {
    try {
      setIsLoading(true);
      const documentsFormData = new FormData();

      documents.forEach((file) => {
        documentsFormData.append("files[]", file);
      });
      documentsFormData.append("created_by", userStore.email);
      documentsFormData.append(
        "importer_email",
        importer_email || userStore.email
      );
      await axios.post(
        `${getBackendUrl()}/submit-clearance-application`,
        documentsFormData
      );
      toast.success("Application Submitted Successfully");
      navigate("/dashboard/application");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const handleFirstPageSubmit = async (importDetails: ImportItem[]) => {
    if (importDetails.length == 0) {
      toast.error("Please Enter at least one item");
      return;
    }
    try {
      setIsLoading(true);
      setImportDetails(importDetails);
      let items = importDetails.map(async (item) => {
        console.log(item);
        const body = {
          item: item.itemName,
          hs_code: item.hscode,
          unit: item.unit,
          quantity: item.quantity,
          container_cargo_no: item.cargo_number,
          country_of_origin: item.country_of_origin,
          port_of_entry: item.port_of_entry,
          purpose_of_importation: item.purpose_of_importation,
          final_destination_address: item.final_destination_address,
          expected_date_of_arrival: item.expected_date_of_arrival,
          importer_email: importer_email || userStore.email,
          created_by: importer_email || userStore.email,
        };
        await axios.post(`${getBackendUrl()}/add-item`, body);
      });

      await Promise.all(items);

      setFormPage(2);
      toast.success("Applied Successfully");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("An Error Occurred");
    }
  };

  const formPages = [
    {
      key: "1",
      component: (
        <ApplicationFormPageOne
          importDetails={importDetails}
          setImportDetails={setImportDetails}
          handleSubmit={(data) => {
            handleFirstPageSubmit(data);
          }}
        />
      ),
    },
    {
      key: "2",
      component: (
        <DocumentsForm
          handleSubmit={handleDocumentsUpload}
          buttonText="Apply for Clearance"
        />
      ),
    },
  ];

  return {
    form: {
      formPage,
      setFormPage,
      formPages,
    },
    importDetails,
    setImportDetails,
    utils: {
      isLoading,
      setIsLoading,
    },
  };
};
