import React, { useState } from "react";
import { DataTable } from "./data-table";
import { GeneratedWaiversColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { waiverRequests } from "@/lib/dummy-data/waiverRequests";
import { generatedWaivers } from "@/lib/dummy-data/generatedWaivers";
import { Switch } from "@/components/ui/switch";

export default function GeneratedWaiversTable() {
  const [showApplied, setShowApplied] = useState(false);
  const agentStore = useStore((state) => state);
  const filteredGeneratedWaivers = generatedWaivers.filter((waiver) =>
    showApplied ? waiver.status === "Applied" : waiver.status !== "Applied"
  );
  return (
    <div className="flex flex-col mt-10">
      <DataTable
        columns={GeneratedWaiversColumns}
        data={filteredGeneratedWaivers}
      />
      <div className="flex items-center mb-4">
        <Switch checked={showApplied} onCheckedChange={setShowApplied} />
        <span className="ml-2">
          {showApplied
            ? "Show Only Applied Waivers"
            : "Show Only Unapplied Waivers"}
        </span>
      </div>
    </div>
  );
}
