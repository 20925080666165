import PaymentsTable from "@/components/tables/PaymentsTable";
import React from "react";

export default function PaymentsView() {
  return (
    <div>
      <PaymentsTable />
    </div>
  );
}
