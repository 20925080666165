import { useStore } from "@/contexts/userStore";
import React from "react";
import ApplyImporterPage from "../ImporterView/apply";
import ComponentSwitch from "@/components/ui/component-switch";
import DashboardHeader from "@/components/ui/dashboard-header";
import AgentAddApplicationPage from "../AgentView/add-application";

export default function ApplyPage() {
  const userStore = useStore((state) => state);
  const components = [
    {
      key: "company",
      component: <ApplyImporterPage />,
    },
    {
      key: "individual",
      component: <ApplyImporterPage />,
    },
    {
      key: "government",
      component: <ApplyImporterPage />,
    },
    {
      key: "agent",
      component: <AgentAddApplicationPage />,
    },
  ];
  return (
    <>
      <DashboardHeader
        title="Import Clearance Certificate"
        caption="Add the import items and upload the required documents."
      />
      <ComponentSwitch
        activeKey={userStore.user?.account_type}
        components={components}
      />
    </>
  );
}
