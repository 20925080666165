import { buttonVariants } from "@/components/ui/button";
import { ChartConfig, ChartTooltip } from "@/components/ui/chart";
import { ChartTooltipContent } from "@/components/ui/chart";
import { ChartContainer } from "@/components/ui/chart";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn, getPercentage } from "@/lib/utils";
import { Pie } from "recharts";
import React from "react";
import { PieChart } from "recharts";

const CardStats = [
  {
    title: "Total Number of Logged Complaints",
    value: 300,
  },
  {
    title: "Total Number of Open Tickets",
    value: 80,
  },
  {
    title: "Total Number of Closed Tickets",
    value: 220,
  },
];

const chartData = [
  {
    category: "payments",
    value: 2500,
    fill: "#005C00",
  },
  {
    category: "technical-issues",
    value: 1000,
    fill: "#50A7B9",
  },
  {
    category: "profile-updates",
    value: 4000,
    fill: "#8F8F8F",
  },
  {
    category: "others",
    value: 1000,
    fill: "#54AB54",
  },
];

const chartConfig = {
  payments: {
    label: "Payments",
  },
  "technical-issues": {
    label: "Technical Issues",
  },
  "profile-updates": {
    label: "Profile Updates",
  },
  others: {
    label: "Others",
  },
} satisfies ChartConfig;
export default function Complaints() {
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">Complaints</h1>
        <Sheet>
          <SheetTrigger
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-transparent text-primary-700 hover:text-primary-foreground flex gap-1 items-center"
            )}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Filters
          </SheetTrigger>
          <SheetContent className="bg-secondary-50 flex flex-col gap-5 py-10">
            {/* Filters Here */}
          </SheetContent>
        </Sheet>
      </div>
      <div className="w-full min-h-[60vh] grid grid-cols-2 gap-5">
        <div className="flex h-full flex-col justify-center gap-5">
          <ChartContainer
            config={chartConfig}
            className="mx-auto aspect-square h-full max-h-[450px]"
          >
            <PieChart>
              <ChartTooltip content={<ChartTooltipContent />} />
              <Pie data={chartData} dataKey="value" nameKey="category" />
            </PieChart>
          </ChartContainer>
          <div className="flex flex-col gap-2 items-center w-full max-w-xs mx-auto">
            {[...chartData]
              .sort((a, b) => b.value - a.value)
              .map((data) => (
                <div className="flex items-center gap-2 w-full">
                  <div
                    className="w-[15px] aspect-square rounded-full"
                    style={{ backgroundColor: data.fill }}
                  ></div>
                  <p className="capitalize">
                    {data.category.split("-").join(" ")}
                  </p>
                  <p className="ml-auto">
                    {getPercentage(
                      data.value,
                      chartData
                        .map((item) => item.value)
                        .reduce((prev, val) => val + prev)
                    )}
                    %
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-5">
          {CardStats.map(({ title, value }, index) => (
            <div
              className={cn(
                "flex flex-col gap-3 h-fit bg-primary-50 rounded-xl p-5 max-w-xs w-full"
              )}
            >
              <p className={cn("text-xs ")}>{title}</p>
              <h4 className="font-bold text-2xl">{value}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
