import { rootNavigationRoutes } from "@/lib/site-data";
import { cn } from "@/lib/utils";
import { ChevronRight, Menu } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { buttonVariants } from "./button";

export default function Navbar() {
  const [showNav, setShowNav] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const isSticky = scrollY > 450;

  useEffect(() => {
    window.addEventListener("scroll", () => setScrollY(window.scrollY));
  });
  return (
    <div className="w-full min-h-[10vh] bg-primary-800 p-5">
      <div
        className={cn(
          "w-full max-w-7xl h-[10vh] mx-auto flex gap-5 rounded-xl items-center p-5 z-10 relative bg-background",
          isSticky &&
            "animate-slide-down fixed top-0 right-0 z-[9999] max-w-full rounded-none  bg-background/80 backdrop-blur-lg shadow-md"
        )}
      >
        <Link to={"/"} className="w-fit my-2">
          <img src="/images/Logo.png" width={61} alt="" />
        </Link>
        <div className="md:flex items-center hidden w-full">
          <div className="w-fit flex items-center gap-[2.25vw]">
            {rootNavigationRoutes.map((route) => (
              <NavItem key={route.href} {...route} />
            ))}
          </div>
          <a
            href={"/verifycertificate"}
            className={cn(buttonVariants({ variant: "default" }), "ml-auto")}
          >
            Verify Certificate
          </a>
        </div>
        <Menu
          className={cn(
            "cursor-pointer md:hidden flex ml-auto dark:text-white relative z-[9999999] duration-500",
            showNav && "text-primary"
          )}
          onClick={() => setShowNav(!showNav)}
        />
        <div
          className={cn(
            "w-full absolute top-0 right-0 bg-primary origin-top z-[900] scale-0 duration-300 h-screen translate-x-[100%]",
            showNav && "translate-x-0 scale-100"
          )}
        ></div>
        <div
          className={cn(
            "pt-[10vh] w-full flex justify-end bg-popover z-[999999] origin-top fixed duration-700  px-2 pb-2 top-0 right-0 h-screen translate-x-[100%]",
            showNav && "translate-x-0"
          )}
        >
          <div className=" w-full h-full flex flex-col gap-7 px-2">
            {rootNavigationRoutes.map(({ href, txt }) => (
              <div key={href} className="flex gap-3 items-center">
                <ChevronRight />
                <NavItem setShow={setShowNav} href={href} txt={txt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const NavItem = ({
  href,
  txt,
  setShow,
}: {
  txt: string;
  href: string;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { pathname } = useLocation();
  const isActive = href == pathname;
  return (
    <a
      href={href}
      onClick={setShow ? () => setShow(false) : () => {}}
      className={cn(
        "p-2 font-[500] text-sm text-[#787878]",
        isActive && "text-[#005C00] font-semibold"
      )}
    >
      {txt}
    </a>
  );
};
