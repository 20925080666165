import { cn } from "@/lib/utils";
import React from "react";

const highlights = [
  {
    img: "/images/abstract-element-one.svg",
    caption: "monitor",
    title:
      "Environmental monitoring, ensuring compliance with environmental standards standard",
  },
  {
    img: "/images/abstract-element-two.svg",
    caption: "enforce",
    title: "Enforce environmental laws across sectors.",
  },
  {
    img: "/images/abstract-element-three.svg",
    caption: "promote",
    title:
      "Promoting practices that safeguards the environment for future generaions",
  },
];

export default function Highlight() {
  return (
    <div id="services" className="w-full bg-primary-700 text-primary-foreground py-20 flex flex-col gap-20 relative overflow-hidden">
      {highlights.map(({ caption, title, img }, index) => (
        <div className="w-full max-w-6xl mx-auto gap-20 grid md:grid-cols-2 py-10 relative z-10">
          <div
            className={cn("space-y-2", index % 2 == 0 ? "order-1" : "order-2")}
          >
            <h5 className="uppercase">{caption}</h5>
            <h1 className="text-5xl font-bold">{title}</h1>
          </div>
          <div
            className={cn(
              "w-full aspect-[1.4/0.8] bg-contain bg-no-repeat bg-center relative",
              index % 2 == 0 ? "order-2" : "order-1"
            )}
            style={{ backgroundImage: `url('${img}')` }}
          />
        </div>
      ))}
      <svg
        width="545"
        height="364"
        viewBox="0 0 545 364"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-64 bottom-20"
      >
        <path
          d="M351.675 294.708C283.126 377.357 158.806 387.337 73.9988 316.998C-10.8086 246.66 -23.9887 122.638 44.5601 39.9889C113.109 -42.6605 459.429 -227.29 544.236 -156.951C155 152.998 420.224 212.058 351.675 294.708Z"
          fill="url(#paint0_radial_1595_23803)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_1595_23803"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(317.251 205.45) rotate(129.672) scale(194.424 199.5)"
          >
            <stop stop-color="#78B578" />
            <stop offset="1" stop-color="#005C00" />
          </radialGradient>
        </defs>
      </svg>

      <svg
        width="383"
        height="420"
        viewBox="0 0 383 420"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute bottom-0 right-0 z-20"
      >
        <path
          d="M383 463.5C383 569.263 297.263 655 191.5 655C85.7375 655 0 569.263 0 463.5C0 357.737 142.737 0 248.5 0C354.263 0 430.5 321.5 383 463.5Z"
          fill="url(#paint0_radial_1595_23818)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_1595_23818"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(419.5 396) rotate(90) scale(191.5 191.5)"
          >
            <stop stop-color="#78B578" />
            <stop offset="1" stop-color="#005C00" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}
