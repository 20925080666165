import { Button, buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { TrendingUp } from "lucide-react";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

import React, { useState } from "react";

const chartData = [
  { tag: "January", trend: 186 },
  { tag: "February", trend: 305 },
  { tag: "March", trend: 237 },
  { tag: "April", trend: 73 },
  { tag: "May", trend: 209 },
  { tag: "June", trend: 214 },
  { tag: "July", trend: 150 },
  { tag: "August", trend: 300 },
  { tag: "September", trend: 200 },
  { tag: "October", trend: 275 },
  { tag: "November", trend: 230 },
  { tag: "December", trend: 190 },
];

const charts = [
  {
    title: "Agent",
    dailyData: [
      { tag: "00:00", trend: 45 },
      { tag: "06:00", trend: 60 },
      { tag: "12:00", trend: 85 },
      { tag: "18:00", trend: 72 },
      { tag: "24:00", trend: 90 },
    ],
    monthlyData: [
      { tag: "January", trend: 180 },
      { tag: "February", trend: 310 },
      { tag: "March", trend: 255 },
      { tag: "April", trend: 95 },
      { tag: "May", trend: 220 },
      { tag: "June", trend: 215 },
      { tag: "July", trend: 175 },
      { tag: "August", trend: 305 },
      { tag: "September", trend: 225 },
      { tag: "October", trend: 290 },
      { tag: "November", trend: 245 },
      { tag: "December", trend: 195 },
    ],
    yearlyData: [
      { tag: "2020", trend: 1800 },
      { tag: "2021", trend: 2300 },
      { tag: "2022", trend: 2100 },
      { tag: "2023", trend: 2600 },
      { tag: "2024", trend: 2750 },
    ],
  },
  {
    title: "Company",
    dailyData: [
      { tag: "00:00", trend: 55 },
      { tag: "06:00", trend: 65 },
      { tag: "12:00", trend: 95 },
      { tag: "18:00", trend: 80 },
      { tag: "24:00", trend: 100 },
    ],
    monthlyData: [
      { tag: "January", trend: 195 },
      { tag: "February", trend: 325 },
      { tag: "March", trend: 240 },
      { tag: "April", trend: 110 },
      { tag: "May", trend: 235 },
      { tag: "June", trend: 230 },
      { tag: "July", trend: 160 },
      { tag: "August", trend: 315 },
      { tag: "September", trend: 210 },
      { tag: "October", trend: 280 },
      { tag: "November", trend: 260 },
      { tag: "December", trend: 210 },
    ],
    yearlyData: [
      { tag: "2020", trend: 1950 },
      { tag: "2021", trend: 2200 },
      { tag: "2022", trend: 2400 },
      { tag: "2023", trend: 2700 },
      { tag: "2024", trend: 2850 },
    ],
  },
  {
    title: "Government",
    dailyData: [
      { tag: "00:00", trend: 50 },
      { tag: "06:00", trend: 75 },
      { tag: "12:00", trend: 90 },
      { tag: "18:00", trend: 70 },
      { tag: "24:00", trend: 105 },
    ],
    monthlyData: [
      { tag: "January", trend: 205 },
      { tag: "February", trend: 320 },
      { tag: "March", trend: 260 },
      { tag: "April", trend: 120 },
      { tag: "May", trend: 240 },
      { tag: "June", trend: 225 },
      { tag: "July", trend: 175 },
      { tag: "August", trend: 310 },
      { tag: "September", trend: 215 },
      { tag: "October", trend: 285 },
      { tag: "November", trend: 235 },
      { tag: "December", trend: 215 },
    ],
    yearlyData: [
      { tag: "2020", trend: 1850 },
      { tag: "2021", trend: 2250 },
      { tag: "2022", trend: 2450 },
      { tag: "2023", trend: 2650 },
      { tag: "2024", trend: 2950 },
    ],
  },
  {
    title: "Individual",
    dailyData: [
      { tag: "00:00", trend: 60 },
      { tag: "06:00", trend: 70 },
      { tag: "12:00", trend: 85 },
      { tag: "18:00", trend: 65 },
      { tag: "24:00", trend: 110 },
    ],
    monthlyData: [
      { tag: "January", trend: 190 },
      { tag: "February", trend: 315 },
      { tag: "March", trend: 245 },
      { tag: "April", trend: 105 },
      { tag: "May", trend: 225 },
      { tag: "June", trend: 220 },
      { tag: "July", trend: 170 },
      { tag: "August", trend: 295 },
      { tag: "September", trend: 200 },
      { tag: "October", trend: 270 },
      { tag: "November", trend: 225 },
      { tag: "December", trend: 205 },
    ],
    yearlyData: [
      { tag: "2020", trend: 2000 },
      { tag: "2021", trend: 2150 },
      { tag: "2022", trend: 2550 },
      { tag: "2023", trend: 2750 },
      { tag: "2024", trend: 2900 },
    ],
  },
];

export default function RevenueOverview() {
  const filters: ("daily" | "monthly" | "annually")[] = [
    "daily",
    "monthly",
    "annually",
  ];
  const [activeFilter, setActiveFilter] = useState(filters[0]);
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          Revenue Overview
        </h1>
        <div className="flex gap-3 items-center w-fit">
          {filters.map((filter) => (
            <Button
              className="capitalize px-10"
              onClick={() => setActiveFilter(filter)}
              variant={filter == activeFilter ? "default" : "outline"}
            >
              {filter}
            </Button>
          ))}
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-10">
        {charts.map((data) => {
          switch (activeFilter) {
            case "daily":
              return (
                <TrendsChart
                  activeFilter={activeFilter}
                  chartData={data.dailyData}
                />
              );
            case "monthly":
              return (
                <TrendsChart
                  activeFilter={activeFilter}
                  chartData={data.monthlyData}
                />
              );
            case "annually":
              return (
                <TrendsChart
                  activeFilter={activeFilter}
                  chartData={data.yearlyData}
                />
              );

            default:
              return <></>;
          }
        })}
      </div>
    </div>
  );
}

function TrendsChart({
  chartData,
  activeFilter,
}: {
  chartData: { tag: string; trend: number }[];
  activeFilter: "daily" | "monthly" | "annually";
}) {
  const tickData = { stroke: "#A1A1A1", strokeWidth: 0.25, fontSize: 11 };
  const chartConfig = {
    desktop: {
      label: "trend",
      color: "rgb(var(--primary))",
    },
  } satisfies ChartConfig;
  return (
    <div className="pb-10">
      <ChartContainer config={chartConfig}>
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid strokeWidth={0.25} />
          <XAxis
            dataKey="tag"
            tickLine={false}
            tick={tickData}
            axisLine={false}
            // tickMargin={8}
            label={{
              value: activeFilter.toUpperCase(),
              position: "insideBottom",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
            tickFormatter={
              activeFilter == "monthly"
                ? (value) => value.slice(0, 3)
                : (value) => value
            }
          ></XAxis>
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={0}
            tick={tickData}
            label={{
              value: "Revenue (₦)",
              angle: -90,
              position: "center",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
          />

          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <Line
            dataKey="trend"
            type="linear"
            stroke="rgb(var(--secondary))"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ChartContainer>
    </div>
  );
}
