import RolesPermissionsTable from "@/components/tables/rolespermissionstable";
import React from "react";

export default function RolesPermissions() {
  return (
    <div>
      <RolesPermissionsTable />
    </div>
  );
}
