import { create } from "zustand";

export interface userStoreInitialState {
  user: {
    id: string;
    nesrea_id: string;
    name: string;
    email: string;
    account_type: "admin" | "agent" | "company" | "government" | "individual";
    email_verification_status: string;
    created_at: string;
    updated_at: string;
    profile_updated: boolean;
    registration_status: "verified" | "unverified" | "pending";
    registration_payment_status: "paid" | "unpaid";
    registration_payment_date: string;
  };
  setUser: (user: any) => void;
}

export const useStore = create<userStoreInitialState>((set) => ({
  user: {
    id: "",
    nesrea_id: "",
    name: "",
    email: "",
    account_type: "individual",
    email_verification_status: "",
    created_at: "",
    updated_at: "",
    registration_status: "unverified",
    registration_payment_status: "unpaid",
    registration_payment_date: "",
    profile_updated: false,
  },
  setUser(user: any) {
    set((state) => {
      return { ...state, user };
    });
  },
}));
