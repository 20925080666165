import ImportDocumentationTable from "@/components/tables/ImportDocumentationTable";
import RegistrationDocumentationTable from "@/components/tables/registrationDocumentationTable";
import SupportDocumentationTable from "@/components/tables/supportDocumentationTable";
import React from "react";

export default function DocumentationKYCPage() {
  return (
    <div className="flex flex-col gap-7">
      <ImportDocumentationTable />
      <RegistrationDocumentationTable />
      <SupportDocumentationTable />
    </div>
  );
}
