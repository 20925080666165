import { buttonVariants } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { Filter } from "lucide-react";
import React from "react";

const CardStats = [
  {
    title: "Chemicals (Raw Materials)",
    value: 100000000,
    remark: {
      percentage: 25.2,
      increased: true,
    },
  },
  {
    title: "Used Electrical Electronics Equipment",
    value: 70000000,
    remark: {
      percentage: 10.16,
      increased: true,
    },
  },
  {
    title: "Chemicals (Raw Materials)",
    value: 50000000,
    remark: {
      percentage: 18.5,
      increased: false,
    },
  },
  {
    title: "Chemicals (Raw Materials)",
    value: 40000000,
    remark: {
      percentage: 10.16,
      increased: true,
    },
  },
];

export default function RevenueTopImportCategories() {
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="w-full flex justify-between items-center">
        <h4 className="font-medium text-primary-700">
          Revenue of Top Import Categories
        </h4>
        <Sheet>
          <SheetTrigger
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-transparent text-primary-700 hover:text-primary-foreground flex gap-1 items-center"
            )}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Filters
          </SheetTrigger>
          <SheetContent className="bg-secondary-50 flex flex-col gap-5 py-10">
            {/* Filters Here */}
          </SheetContent>
        </Sheet>
      </div>
      <div className="grid grid-cols-4 gap-5 w-full">
        {CardStats.map((stat) => (
          <div className="w-full bg-primary-50 rounded-xl p-2 flex flex-col gap-3 justify-between">
            <p className="text-primary-700 text-sm max-w-[150px]">{stat.title}</p>
            <h3 className="font-bold text-3xl">
              ₦{stat.value.toLocaleString()}
            </h3>
            <p
              className={cn(
                stat.remark.increased ? "text-primary-300" : "text-destructive"
              )}
            >
              {stat.remark.increased ? "+" : "-"}
              {stat.remark.percentage}% (Last 30 days)
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
