import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { Link } from "react-router-dom";
import { AllClearanceApplicationsTableData } from "./_interfaces";
import { format } from "fecha";

export const ApplicationsColumns: ColumnDef<AllClearanceApplicationsTableData>[] =
  [
    {
      accessorKey: "sn",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            SN
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => {
        return <p>{row.index + 1}</p>;
      },
    },
    {
      accessorKey: "id",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Application ID
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
    },
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Application Date
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => {
        const { dateCreated } = row.original;
        return <p>{format(new Date(dateCreated), "MM/DD/YYYY")}</p>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Status
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => {
        let { status } = row.original;
        let isPending = status == "NEW";
        let isApproved = status == "Approved";
        let isRejected = status == "Disapproved";

        const status_dict: { [key: string]: string } = {
          NEW: "Pending",
          Approved: "Approved",
          Disapproved: "Rejected",
        };
        return (
          <p
            className={cn(
              "capitalize",
              isPending && "text-orange-400",
              isApproved && "text-primary-300",
              isRejected && "text-destructive"
            )}
          >
            {status_dict[status]}
          </p>
        );
      },
    },
    {
      accessorKey: "action",
      header: ({ column }) => {
        return (
          <div className="flex items-center">
            Action
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link
            to={`/dashboard/application/import-clearance?id=${id}`}
            className="underline text-neutral-500"
          >
            View
          </Link>
        );
      },
    },
  ];
