import React from "react";
import WaiverRequestsTableAdmin from "@/components/tables/WaiverRequestsTableAdmin";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import GeneratedWaiversTable from "@/components/tables/GeneratedWaiversTable";

export default function WaiverPage() {
  return (
    <div className="flex flex-col gap-7">
      <div className="w-full flex justify-end">
        <Link
          to={"/admin/accounting/waiver/generate-waiver"}
          className={cn(buttonVariants({ variant: "default" }))}
        >
          Generate Waiver
        </Link>
      </div>
      <WaiverRequestsTableAdmin />
      <GeneratedWaiversTable />
    </div>
  );
}
