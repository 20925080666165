import React from 'react'
import Admins from './_components/Admins'
import UserLoginHeatMap from './_components/UserLoginHeatMap'

export default function AdminPanelPage() {
  return (
    <div className="w-full flex flex-col gap-5">
      <Admins />
      <UserLoginHeatMap />
    </div>
  )
}
