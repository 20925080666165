import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";

export const ClientsColumns: ColumnDef<agentStoreInitialState["clients"][0]>[] =
  [
    {
      accessorKey: "select",
      header: ({ column, ...props }) => {
        const agentStore = useStore((state) => state);

        return (
          <Checkbox
            checked={
              agentStore.clients.length == agentStore.selectedClients.length
            }
            onCheckedChange={(checked) => {
              if (checked) {
                agentStore.selectClients(
                  agentStore.clients.filter(
                    (client) => !agentStore.selectedClients.includes(client)
                  )
                );
              } else {
                agentStore.clearSelectedClients();
              }
            }}
          />
        );
      },
      cell: ({ row }) => {
        const agentStore = useStore((state) => state);
        let isSelected = agentStore.selectedClients.includes(row.original);
        return (
          <Checkbox
            checked={isSelected}
            onCheckedChange={(checked) => {
              if (checked) {
                agentStore.selectClients([row.original]);
              } else {
                agentStore.deselectClient(row.original);
              }
            }}
          />
        );
      },
    },
    {
      accessorKey: "category",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Client Category
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
    },
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
    },
    {
      accessorKey: "dateCreated",
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:underline"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Date
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => {
        let { dateCreated } = row.original;
        let formattedDate = formatDate(dateCreated);
        return <p>{formattedDate}</p>;
      },
    },
  ];
