import React from "react";
import Complaints from "./_components/Complaints";
import AdminMessaging from "./_components/AdminMessaging";

export default function SupportPageAdmin() {
  return (
    <div className="w-full flex flex-col gap-10">
      <Complaints />
      <AdminMessaging />
    </div>
  );
}
