import React from 'react'
import RevenueTopImportCategories from "../dashboard/components/RevenueTopImportCategories";
import WaiverRecordsChart from "./components/WaiverRecordsChart";
import RevenueByCategory from "./components/RevenueByCategory";
import RevenueByState from './components/RevenueByState';
import Invoicing from './components/Invoicing';

export default function AccountingPage() {
  return (
    <div className="flex flex-col gap-7">
      <RevenueTopImportCategories />
      <WaiverRecordsChart />
      <RevenueByCategory />
      <RevenueByState />
      <Invoicing />
    </div>
  );
}
