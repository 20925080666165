import React from "react";

const ProcessItems = [
  {
    title: "Submit Application",
    description:
      "Provide the necessary details about your consignment and submit required documents.",
  },
  {
    title: "Make Payment",
    description:
      "With lots of unique blocks, you can easily build a page without coding. ",
  },
  {
    title: "Verification",
    description:
      "NESREA verifies your documents and checks compliance with regulations.",
  },
  {
    title: "Receive Clearance",
    description:
      "Upon approval, download your Environmental Import Clearance Certificate within 48 hours.",
  },
];

export default function ClearanceProcess() {
  return (
    <div
      id="clearance"
      className="w-full h-fit md:py-0 py-12 md:h-[90vh] clearance-gradient flex flex-col gap-16 items-center justify-center relative z-10 text-background"
    >
      <svg
        width="383"
        height="384"
        viewBox="0 0 383 384"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="-top-10 right-0 absolute"
      >
        <path
          d="M383 191.641C383 297.403 297.263 383.141 191.5 383.141C85.7375 383.141 409 50.6406 0 191.641C0 85.8781 85.7375 0.140625 191.5 0.140625C297.263 0.140625 383 85.8781 383 191.641Z"
          fill="url(#paint0_radial_1595_23900)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_1595_23900"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(191.5 191.641) rotate(90) scale(191.5)"
          >
            <stop stopColor="#78B578" />
            <stop offset="1" stop-color="#005C00" />
          </radialGradient>
        </defs>
      </svg>

      <div className="absolute aspect-[1.4/1] w-[30%] bottom-0 right-0 overflow-hidden">
        <svg
          width="383"
          height="384"
          viewBox="0 0 383 384"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute -bottom-[60%] right-0"
        >
          <path
            d="M383 191.641C383 297.403 297.263 383.141 191.5 383.141C85.7375 383.141 409 50.6406 0 191.641C0 85.8781 85.7375 0.140625 191.5 0.140625C297.263 0.140625 383 85.8781 383 191.641Z"
            fill="url(#paint0_radial_1595_23902)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_1595_23902"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(191.5 191.641) rotate(90) scale(191.5)"
            >
              <stop stop-color="#78B578" />
              <stop offset="1" stop-color="#005C00" />
            </radialGradient>
          </defs>
        </svg>
      </div>

      <div className="absolute w-[30%] left-0 top-0 h-full flex items-center">
        <svg
          width="385"
          height="245"
          viewBox="0 0 385 245"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M384.403 205.337C384.403 311.099 159.405 178.337 103.405 89.8366C-2.35749 89.8366 103.406 338.837 1.40283 205.337C-13.095 45.8369 87.1403 13.8366 192.903 13.8366C317.905 -45.6631 384.403 99.5741 384.403 205.337Z"
            fill="url(#paint0_radial_1595_23901)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_1595_23901"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(192.906 205.337) rotate(90) scale(191.5 191.5)"
            >
              <stop stopColor="#78B578" />
              <stop offset="1" stop-color="#005C00" />
            </radialGradient>
          </defs>
        </svg>
      </div>

      <div data-aos="fade-in" className="text-center space-y-5">
        <h1 className="text-4xl font-semibold">Import Clearance Process</h1>
        <p className="opacity-70 text-lg">
          Follow these simple steps to get your import clearance approved by
          NESREA.
        </p>
      </div>
      <div className="grid md:grid-cols-2 gap-10 px-10 md:max-w-[60%] w-full">
        {ProcessItems.map(({ title, description }, index) => (
          <div
            key={title}
            data-aos="fade-in"
            data-aos-delay={`${2 * (index * 100)}`}
            data-aos-anchor-placement="top-bottom"
            className="w-full max-w-[80%] h-[101px] flex gap-2 even:ml-auto"
          >
            <div className="w-[30px]">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="15" r="15" fill="#68D585" />
                <path
                  d="M13.1117 19.6923C12.87 19.6923 12.6287 19.6016 12.4442 19.4196L8 15.039L9.33504 13.7226L13.1117 17.4452L20.665 10L22 11.3164L13.7792 19.4196C13.5946 19.6016 13.3534 19.6923 13.1117 19.6923Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-col">
              <h1 className="font-semibold text-xl">{title}</h1>
              <p className="opacity-70 text-xs">{description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
