import React, { useEffect, useState } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { Mail } from "lucide-react";

export default function OTP() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();

  const handleVerification = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${getBackendUrl()}/verify-otp`, {
        email: email,
        otp: value,
      });
      toast.success("You have been verified", {
        description: <>You can now sign in</>,
      });
      localStorage.removeItem("nesrea_user_email");
      localStorage.removeItem("nesrea_user_form_stage");
      setIsLoading(false);
      navigate("/auth/sign-in");
    } catch (error) {
      toast.error("An error occurred while verifying your account", {
        description: "Please try again",
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setEmail(localStorage.getItem("nesrea_user_email") || "");
  }, []);
  return (
    <>
      <h1>Verify Email to Proceed</h1>
      <hr className="w-full border border-neutral-200" />
      <div className="flex items-center gap-5 w-full">
        <Mail size={45} className="p-3 border rounded-[12px] text-primary" />
        <div className="text-left space-y-2">
          <h4 className="font-semibold">Check your email</h4>
          <p className="text-sm text-neutral-500">
            We’ve sent a code to {email}
          </p>
        </div>
      </div>
      <div className="text-left space-y-2">
        <h5 className="text-sm text-neutral-400">
          Verification code <strong>({email})</strong>
        </h5>
        <InputOTP
          value={value}
          className="w-full"
          onChange={(e) => {
            if (e.length < 6) {
              setIsComplete(false);
            }
            setValue(e);
          }}
          onComplete={() => setIsComplete(true)}
          maxLength={6}
          pattern={REGEXP_ONLY_DIGITS}
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
        <h5 className="text-xs text-destructive">Code expires in 10 minutes</h5>
      </div>
      <div className="flex gap-4 items-center justify-end w-full">
        <Button variant={"outline"}>Resend Code</Button>
        <Button
          onClick={() => handleVerification()}
          disabled={!isComplete || isLoading}
        >
          {isLoading ? "Verifying..." : "Verify"}
        </Button>
      </div>
    </>
  );
}
