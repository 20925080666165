import React from "react";
import { DataTable } from "./data-table";
import { SupportColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { certificatesAdminData } from "@/lib/dummy-data/certificatesAdminData";

export default function SupportTable() {
  const agentStore = useStore((state) => state);
  return (
    <div>
      <DataTable columns={SupportColumns} data={[]} />
    </div>
  );
}
