import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import ImportDetailsDisplayTable from "@/components/tables/ImportDetailsDisplayTable";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PaymentForm } from "@/components/forms/PaymentForm";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { registrationsData } from "@/lib/dummy-data/registrationsData";
import RecentImportsTable from "@/components/tables/RecentImportsTable";

export default function PaymentDetailsPage() {
  const [searchParams] = useSearchParams();
  let id = searchParams.get("registrationID");
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  let registrationDetails = registrationsData.find((data) => data.id == id);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);
  return (
    <div className="w-full flex flex-col gap-7">
      <h3 className="text-2xl font-medium">
        The following are details of the registration request:
      </h3>
      <div className="w-full grid grid-cols-2 gap-7">
        <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
          <h3 className="text-2xl font-semibold text-center">
            Importer Details
          </h3>
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Name</h6>
              <p className="text-neutral-500">{registrationDetails?.name}</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Email</h6>
              <p className="text-neutral-500">example@email.com</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">NESREA ID</h6>
              <p className="text-neutral-500">{registrationDetails?.id}</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">
                Importer Category
              </h6>
              <p className="text-neutral-500">
                {registrationDetails?.importerCategory}
              </p>
            </div>
          </div>
        </div>
        <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
          <h3 className="text-2xl font-semibold text-center">
            Documents Uploaded
          </h3>
          <Carousel
            opts={{
              align: "start",
            }}
            setApi={setApi}
            className="w-full h-full flex flex-col"
          >
            <CarouselContent>
              {Array.from({ length: 5 }).map((_, index) => (
                <CarouselItem key={index} className="">
                  <div className="p-1">
                    <div className="w-full flex flex-col gap-6">
                      {Array.from({ length: 3 }).map((_, index) => (
                        <div
                          key={`${index}document`}
                          className="w-full flex items-center justify-between"
                        >
                          <div className="flex gap-2">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_3238_3301"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                              >
                                <rect width="24" height="24" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_3238_3301)">
                                <path
                                  d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"
                                  fill="#1C1B1F"
                                />
                              </g>
                            </svg>
                            <p className="font-medium">Pdf File</p>
                          </div>
                          <Dialog>
                            <DialogTrigger className="text-neutral-500 underline cursor-pointer text-sm">
                              View Document
                            </DialogTrigger>
                            <DialogContent>
                              <div
                                className="w-full aspect-[9/12] bg-contain bg-center bg-no-repeat"
                                style={{
                                  backgroundImage: `url('/images/cert.png')`,
                                }}
                              ></div>

                              <DialogClose
                                className={cn(
                                  buttonVariants({ variant: "default" }),
                                  "px-8"
                                )}
                              >
                                Close
                              </DialogClose>
                            </DialogContent>
                          </Dialog>
                        </div>
                      ))}
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <div className="w-full mt-auto flex items-center justify-center py-5 gap-4">
              <CarouselPrevious className=" translate-y-0 w-10 h-10" />
              {current} of {count}
              <CarouselNext className="translate-y-0 w-10 h-10" />
            </div>
          </Carousel>
        </div>
        <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
          <h3 className="text-2xl font-semibold text-center">
            Registration Payment Details
          </h3>
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Amount</h6>
              <p className="text-neutral-500">₦115,000</p>
            </div>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-medium text-primary-700">Payment Date</h6>
              <p className="text-neutral-500">28th October, 2024 (12:00 PM)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
