import ClearancePaymentsTable from "@/components/tables/clearancePaymentsTable";
import GeneralRegistrationPaymentHistoryTable from "@/components/tables/generalRegistrationPaymentHistoryTable";
import InvoicesHistoryTable from "@/components/tables/invoicesHistoryTable";
import WaiverHistoryTable from "@/components/tables/waiverHistoryTable";
import React from "react";

export default function PaymentsPage() {
  return (
    <div className="flex flex-col gap-7">
      <GeneralRegistrationPaymentHistoryTable />
      <ClearancePaymentsTable />
      <InvoicesHistoryTable />
      <WaiverHistoryTable />
    </div>
  );
}
