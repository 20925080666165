import UserAdministrationTable from "@/components/tables/useradministrationtable";
import React from "react";

export default function UserAdministration() {
  return (
    <div>
      <UserAdministrationTable />
    </div>
  );
}
