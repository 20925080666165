import React from "react";
import { DataTable } from "./data-table";
import { ClientsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";

export default function LegalOverviewTable() {
  const agentStore = useStore((state) => state);
  return (
    <div>
      <DataTable columns={ClientsColumns} data={[]} />
    </div>
  );
}
