import { RegistrationPaymentHistoryData } from "@/lib/interfaces";

export const registrationPaymentsHistoryData: RegistrationPaymentHistoryData = [
    {
        id: "1",
        name: "John Doe",
        amount: "100.00",
        paymentID: "NS/123456",
        paymentDate: "2023-10-01"
    },
    {
        id: "2",
        name: "Jane Smith",
        amount: "200.00",
        paymentID: "NS/123457",
        paymentDate: "2023-10-02"
    },
    // Add more entries as needed
];
