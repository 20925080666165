import { useStore } from "@/contexts/userStore";
import { isLoggedIn } from "@/lib/auth";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const ProtectedLayout = () => {
  const userStore = useStore((state) => state);
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const userCache = JSON.parse(localStorage.getItem("nesrea_user") || "{}");
  useEffect(() => {
    isLoggedIn().then((isValid) => {
      if (!isValid || !userCache) {
        navigate("/auth/sign-in");
      }
      if (userCache.account_type) {
        userStore.setUser(userCache);
      }
      setIsMounted(true);
    });
  }, []);
  return isMounted ? <Outlet /> : <></>;
};
