type Permission = {
  module: string;
  isAllowed: boolean;
  permissions: string[];
};

export const PROFILE_MANAGEMENT_PERMISSIONS: Permission[] = [
  {
    module: "profile",
    isAllowed: false,
    permissions: ["all"],
  },
  {
    module: "registration_request",
    isAllowed: false,
    permissions: ["view_export", "approve_reject"],
  },
  {
    module: "user_profiling",
    isAllowed: false,
    permissions: ["view_export", "add_delete", "deactivate"],
  },
  {
    module: "documentation_kyc",
    isAllowed: false,
    permissions: ["view_export", "edit"],
  },
  {
    module: "registration_payments",
    isAllowed: false,
    permissions: ["view_export"],
  },
];

export const ACCOUNT_PERMISSIONS: Permission[] = [
  {
    module: "import_payment_history",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "registration_payment_history",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "revenue_by_state",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "waivers_payment_history",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "waivers_request",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "generate_waivers",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "total_revenue",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
  {
    module: "account_overview",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
  {
    module: "invoicing",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
  {
    module: "generate_invoice",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
  {
    module: "payments",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
  {
    module: "weekly-revenue-report",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
];

export const CLEARANCE_PERMISSIONS: Permission[] = [
  {
    module: "clearance",
    isAllowed: false,
    permissions: ["all"],
  },
  {
    module: "clearance_application",
    isAllowed: false,
    permissions: ["view", "manage", "approve_reject"],
  },
  {
    module: "categories",
    isAllowed: false,
    permissions: ["view", "edit", "manage"],
  },
  {
    module: "certificates",
    isAllowed: false,
    permissions: ["view"],
  },
  {
    module: "pricing",
    isAllowed: false,
    permissions: ["view", "edit", "manage"],
  },
];

export const LEGAL_PERMISSIONS: Permission[] = [
  {
    module: "legal",
    isAllowed: false,
    permissions: ["all"],
  },
  {
    module: "falsified_records",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
  {
    module: "verifications",
    isAllowed: false,
    permissions: ["view", "manage"],
  },
];

export const REPORTS_PERMISSIONS: Permission[] = [
  {
    module: "reports",
    isAllowed: false,
    permissions: ["all"],
  },
  {
    module: "profile_management",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "clearance",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "accounting",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "legal",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "support",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
  {
    module: "admin_panel",
    isAllowed: false,
    permissions: ["view_export", "manage"],
  },
];

export const SUPPORT_PERMISSIONS: Permission[] = [
  {
    module: "support",
    isAllowed: false,
    permissions: ["all"],
  },
  {
    module: "complaints_ticketing",
    isAllowed: false,
    permissions: ["view_export", "respond", "manage"],
  },
  {
    module: "admin_chats",
    isAllowed: false,
    permissions: ["view_export", "respond", "manage"],
  },
];

export const sections = [
  {
    name: "Profile Management",
    permissions: PROFILE_MANAGEMENT_PERMISSIONS,
  },
  {
    name: "Account",
    permissions: ACCOUNT_PERMISSIONS,
  },
  {
    name: "Clearance",
    permissions: CLEARANCE_PERMISSIONS,
  },
  {
    name: "Legal",
    permissions: LEGAL_PERMISSIONS,
  },
  {
    name: "Reports",
    permissions: REPORTS_PERMISSIONS,
  },
  {
    name: "Support",
    permissions: SUPPORT_PERMISSIONS,
  },
];
