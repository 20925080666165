import FalsifiedRecordsTable from "@/components/tables/FalsifiedRecordsTable";
import React from "react";

export default function FalsifiedRecordsPage() {
  return (
    <div>
      <FalsifiedRecordsTable />
    </div>
  );
}
