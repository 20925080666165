import React from "react";
import { TrendingUp } from "lucide-react";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

// Yearly trend data, representing annual revenue
const chartData = [
  { year: "2018", trend: 1200 },
  { year: "2019", trend: 1500 },
  { year: "2020", trend: 1700 },
  { year: "2021", trend: 1600 },
  { year: "2022", trend: 1800 },
  { year: "2023", trend: 1900 },
];

const chartConfig = {
  desktop: {
    label: "trend",
    color: "rgb(var(--primary))",
  },
} satisfies ChartConfig;

const tickData = { stroke: "#A1A1A1", strokeWidth: 0.25, fontSize: 11 };

export default function YearlyRevenueTrends() {
  return (
    <div className="pb-10">
      <ChartContainer config={chartConfig}>
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid strokeWidth={0.25} />
          <XAxis
            dataKey="year"
            tickLine={false}
            tick={tickData}
            axisLine={false}
            label={{
              value: "Years",
              position: "insideBottom",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={0}
            tick={tickData}
            label={{
              value: "Revenue",
              angle: -90,
              position: "center",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
          />

          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent />}
          />
          <Line
            dataKey="trend"
            type="linear"
            stroke="#58B7CB"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ChartContainer>
    </div>
  );
}
