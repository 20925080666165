import React from "react";
import { TrendingUp } from "lucide-react";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { getPercentage } from "@/lib/utils";

const chartData = [
  { month: "January", total: 186, positive: 100, negative: 86, neutral: 100 },
  { month: "February", total: 305, positive: 150, negative: 155, neutral: 100 },
  { month: "March", total: 237, positive: 100, negative: 137, neutral: 100 },
  { month: "April", total: 73, positive: 50, negative: 23, neutral: 100 },
  { month: "May", total: 209, positive: 100, negative: 109, neutral: 100 },
  { month: "June", total: 214, positive: 100, negative: 114, neutral: 100 },
  { month: "July", total: 150, positive: 100, negative: 50, neutral: 100 },
  { month: "August", total: 300, positive: 100, negative: 200, neutral: 100 },
  {
    month: "September",
    total: 200,
    positive: 100,
    negative: 100,
    neutral: 100,
  },
  { month: "October", total: 275, positive: 100, negative: 175, neutral: 100 },
  { month: "November", total: 230, positive: 100, negative: 130, neutral: 100 },
  { month: "December", total: 190, positive: 100, negative: 90, neutral: 100 },
];

const chartConfig = {
  total: {
    label: "total",
    color: "rgb(var(--primary))",
  },
  positive: {
    label: "positive",
    color: "#8AE6AE",
  },
  negative: {
    label: "negative",
    color: "#E40101",
  },
  neutral: {
    label: "neutral",
    color: "#FFC69D",
  },
} satisfies ChartConfig;

const tickData = { stroke: "#A1A1A1", strokeWidth: 0.25, fontSize: 11 };

export default function MessagesChart() {
  return (
    <div className="pb-10 h-full w-full max-w-xl flex flex-col gap-5">
      <ChartContainer className="h-full w-full" config={chartConfig}>
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid strokeWidth={0.25} />
          <XAxis
            dataKey="month"
            tickLine={false}
            tick={tickData}
            axisLine={false}
            // tickMargin={8}
            label={{
              value: "Months",
              position: "insideBottom",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
            tickFormatter={(value) => value.slice(0, 3)}
          ></XAxis>
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={0}
            tick={tickData}
            label={{
              value: "Revenue",
              angle: -90,
              position: "center",
              offset: 1,
              stroke: "green",
              strokeWidth: 0.5,
            }}
          />

          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel={false} />}
          />
          <Line
            dataKey="total"
            type="linear"
            strokeWidth={2}
            stroke="var(--color-total)"
            dot={{
              fill: "var(--color-total)",
            }}
          />
          <Line
            dataKey="positive"
            type="linear"
            strokeWidth={2}
            stroke="var(--color-positive)"
            dot={{
              fill: "var(--color-positive)",
            }}
          />
          <Line
            dataKey="negative"
            type="linear"
            strokeWidth={2}
            stroke="var(--color-negative)"
            dot={{
              fill: "var(--color-negative)",
            }}
          />
          <Line
            dataKey="neutral"
            type="linear"
            strokeWidth={2}
            stroke="var(--color-neutral)"
            dot={{
              fill: "var(--color-neutral)",
            }}
          />
        </LineChart>
      </ChartContainer>
      <div className="w-full grid grid-cols-2 gap-5">
        {[
          {
            label: "Total Number of Messages Exchanged",
            color: "rgb(var(--primary))",
          },
          {
            label: "Positive sentiment",
            color: "#8AE6AE",
          },
          {
            label: "Negative sentiment",
            color: "#E40101",
          },
          {
            label: "Neutral sentiment",
            color: "#FFC69D",
          },
        ].map(({ label, color }) => (
          <div className="flex items-center gap-2 w-[170px]">
            <div
              className="w-[20px] min-w-[20px] aspect-square rounded-full"
              style={{ backgroundColor: color }}
            ></div>
            <p className="capitalize text-sm">{label}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
