import React from "react";
import { Button } from "../ui/button";

export default function DashboardModal({
  title,
  description,
  cta,
}: {
  title: string;
  description: string;
  cta: { txt: string; onClick: () => void };
}) {
  return (
    <div className="w-full h-screen fixed top-0 right-0 z-50 flex items-center justify-center">
      <div className="absolute top-0 right-0 w-full h-full bg-black/50 backdrop-blur-sm" />
      <div className="w-full relative z-10 max-w-xl bg-white rounded-xl p-5 flex flex-col items-center text-center gap-9">
        <h4 className="text-primary-700 font-medium text-xl">{title}</h4>
        <p className="text-lg text-primary-700">{description}</p>
        <Button className="w-full" onClick={cta.onClick}>{cta.txt}</Button>
      </div>
    </div>
  );
}
