import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ChevronDown, ChevronRight } from "lucide-react";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { HSCodes } from "@/lib/dummy-data/hsCodes";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  countries,
  importationPurposes,
  portsData,
  units,
} from "@/lib/site-data";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import { useAddImportItemForm } from "./_hooks";
import { ImportItem } from "@/lib/interfaces";
import { DialogClose } from "@/components/ui/dialog";

const ImportDetailItemForm = ({
  handleSubmit,
  importItems,
}: {
  handleSubmit: (data: ImportItem) => void;
  importItems: ImportItem[];
}) => {
  const { form, hsCode, setHSCode, showDropdown, setShowDropdown } =
    useAddImportItemForm();

  useEffect(() => {
    form.setValue("hsCode", hsCode.map((item) => item.code).join("."));
  });
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-10"
        onSubmit={form.handleSubmit((data) => {
          const hs_codes = importItems.map((item) => item.hscode);
          if (hs_codes.includes(data.hsCode)) {
            toast.error(`${data.hsCode} has already been added.`);
            return;
          }
          const importItem: ImportItem = {
            itemName: data.item,
            hscode: data.hsCode,
            unit: data.unit,
            quantity: data.quantity,
            cargo_number: data.cargoNumber,
            country_of_origin: data.countryOfOrigin,
            port_of_entry: data.portOfEntry,
            purpose_of_importation: data.purposeOfImportation,
            final_destination_address: data.finalDestinationAddress,
            expected_date_of_arrival: data.expectedDateofArrival,
          };
          handleSubmit(importItem);
        })}
      >
        <FormField
          control={form.control}
          name="item"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2 items-start">
              <FormLabel className="flex-[0.3] flex font-semibold">
                Item
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div className="flex flex-col gap-4 w-full">
                  <div
                    onClick={() => setShowDropdown(true)}
                    className="w-full p-2 rounded-lg border cursor-pointer flex items-center justify-between text-neutral-400 "
                  >
                    {!hsCode.length && (
                      <p className="font-light text-sm">Select Item</p>
                    )}
                    <div className="flex gap-2">
                      {hsCode.map((code, index) => (
                        <div className="flex gap-2" key={code.id}>
                          <Badge>{code.label}</Badge>
                          {index != hsCode.length - 1 && <ChevronRight />}
                        </div>
                      ))}
                    </div>
                    <ChevronDown size={15} />
                  </div>
                  <div className="grid grid-cols-4 gap-4">
                    {showDropdown && (
                      <>
                        <ScrollArea className="max-h-[50vh] bg-white rounded-lg overflow-hidden">
                          <div className="flex flex-col w-full">
                            {HSCodes.map((code) => {
                              let isSelected = hsCode.find(
                                (codeItem) => codeItem.id == code.categoryId
                              );
                              return (
                                <div
                                  className={cn(
                                    "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                    isSelected &&
                                      "bg-primary-50 text-primary-700"
                                  )}
                                  onClick={() => {
                                    if (!isSelected) {
                                      setHSCode((prev) => {
                                        prev[0] = {
                                          label: code.label,
                                          code: code.code,
                                          id: code.categoryId,
                                        };
                                        return [
                                          {
                                            label: code.label,
                                            code: code.code,
                                            id: code.categoryId,
                                          },
                                        ];
                                      });
                                    }
                                  }}
                                  key={code.categoryId}
                                >
                                  {code.label}
                                </div>
                              );
                            })}
                          </div>
                        </ScrollArea>
                        {hsCode.length > 0 && (
                          <ScrollArea className="max-h-[50vh]">
                            <div className="flex flex-col w-full">
                              {HSCodes.find(
                                (codeParent) =>
                                  codeParent.categoryId == hsCode[0].id
                              )?.items.map((code) => {
                                let isSelected = hsCode.find(
                                  (codeItem) => codeItem.id == code.categoryId
                                );
                                return (
                                  <div
                                    className={cn(
                                      "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                      isSelected &&
                                        "bg-primary-50 text-primary-700"
                                    )}
                                    onClick={() => {
                                      if (!isSelected) {
                                        setHSCode((prev) => {
                                          return [
                                            prev[0],
                                            {
                                              label: code.label,
                                              code: code.code,
                                              id: code.categoryId,
                                            },
                                          ];
                                        });
                                      }
                                    }}
                                    key={code.categoryId}
                                  >
                                    {code.label}
                                  </div>
                                );
                              })}
                            </div>
                          </ScrollArea>
                        )}
                        {hsCode.length > 1 && (
                          <ScrollArea className="max-h-[50vh]">
                            <div className="flex flex-col w-full">
                              {HSCodes.find(
                                (codeParent) =>
                                  codeParent.categoryId == hsCode[0].id
                              )
                                ?.items.find(
                                  (codeSubOne) =>
                                    codeSubOne.categoryId == hsCode[1].id
                                )
                                ?.items.map((code) => {
                                  let isSelected = hsCode.find(
                                    (codeItem) => codeItem.id == code.categoryId
                                  );
                                  return (
                                    <div
                                      className={cn(
                                        "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                        isSelected &&
                                          "bg-primary-50 text-primary-700"
                                      )}
                                      onClick={() => {
                                        if (!isSelected) {
                                          setHSCode((prev) => {
                                            return [
                                              prev[0],
                                              prev[1],
                                              {
                                                label: code.label,
                                                code: code.code,
                                                id: code.categoryId,
                                              },
                                            ];
                                          });
                                        }
                                      }}
                                      key={code.categoryId}
                                    >
                                      {code.label}
                                    </div>
                                  );
                                })}
                            </div>
                          </ScrollArea>
                        )}
                        {hsCode.length > 2 && (
                          <ScrollArea className="max-h-[50vh]">
                            <div className="flex flex-col w-full">
                              {HSCodes.find(
                                (codeParent) =>
                                  codeParent.categoryId == hsCode[0].id
                              )
                                ?.items.find(
                                  (codeSubOne) =>
                                    codeSubOne.categoryId == hsCode[1].id
                                )
                                ?.items.find(
                                  (codeSubTwo) =>
                                    codeSubTwo.categoryId == hsCode[2].id
                                )
                                ?.items.map((code) => {
                                  let isSelected = hsCode.find(
                                    (codeItem) => codeItem.id == code.itemId
                                  );
                                  return (
                                    <div
                                      className={cn(
                                        "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                        isSelected &&
                                          "bg-primary-50 text-primary-700"
                                      )}
                                      onClick={() => {
                                        if (!isSelected) {
                                          setHSCode((prev) => {
                                            prev[3] = {
                                              label: code.label,
                                              code: code.code,
                                              id: code.itemId,
                                            };
                                            return [...prev];
                                          });
                                          form.setValue("item", code.label);
                                          field.onBlur();
                                        }
                                      }}
                                      key={code.itemId}
                                    >
                                      {code.label}
                                    </div>
                                  );
                                })}
                            </div>
                          </ScrollArea>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="hsCode"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">HS Code</FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="text" disabled {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="unit"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Unit</FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("unit", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select unit" />
                    </SelectTrigger>
                    <SelectContent>
                      {units.map((unit) => (
                        <SelectItem key={unit.id} value={unit.id}>
                          {unit.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Quantity</FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cargoNumber"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Container / Cargo No.
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="countryOfOrigin"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Country of Oirigin</FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("countryOfOrigin", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select country" />
                    </SelectTrigger>
                    <SelectContent>
                      {countries
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((unit) => (
                          <SelectItem key={unit.id} value={unit.id}>
                            {unit.label}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="portOfEntry"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Port of Entry</FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("portOfEntry", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select port of entry" />
                    </SelectTrigger>
                    <SelectContent>
                      {portsData
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((unit) => (
                          <SelectItem key={unit.id} value={unit.id}>
                            {unit.label}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="purposeOfImportation"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Purpose of Importation
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("purposeOfImportation", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select purpose of importation" />
                    </SelectTrigger>
                    <SelectContent>
                      {importationPurposes.map((unit) => (
                        <SelectItem key={unit.id} value={unit.id}>
                          {unit.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="finalDestinationAddress"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Final Dest. Address</FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input
                  type="text"
                  {...field}
                  placeholder="Enter full address"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="expectedDateofArrival"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Expected Date of Arr.
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="date" {...field} placeholder="DD/MM/YY" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="sticky -bottom-6 right-0 w-full pt-7 pb-3 bg-gradient-to-t from-white via-white/50 to-transparent flex justify-center">
          <DialogClose
            disabled={!form.formState.isValid}
            className="w-fit disabled:!cursor-not-allowed"
          >
            <Button
              className="w-full max-w-[164px] disabled:opacity-100 disabled:bg-primary-200 disabled:!cursor-not-allowed"
              disabled={!form.formState.isValid}
            >
              Add Item
            </Button>
          </DialogClose>
        </div>
      </form>
    </Form>
  );
};

export default ImportDetailItemForm;
