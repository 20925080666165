import React from "react";

export default function LoaderWrapper({
  children,
  isLoading,
}: {
  children: React.ReactNode;
  isLoading: boolean;
}) {
  return (
    <>
      {isLoading && (
        <div className="fixed top-0 bg-primary/20 backdrop-blur-sm right-0 w-full h-screen z-[999999999999999999999999999999999] flex justify-center items-center">
          <div className="loader" />
        </div>
      )}
      {children}
    </>
  );
}
