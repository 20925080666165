import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";
import MonthlyRevenueTrends from "./MonthlyRevenueTrends";
import YearlyRevenueTrends from "./YearlyRevenueTrends";

export default function AccountingOverview() {
  return (
    <div className="w-full min-h-[40vh] shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          Accounting Overview
        </h1>
        <Link
          to={"#"}
          className={cn(
            buttonVariants({ variant: "link" }),
            "p-0 underline text-xs"
          )}
        >
          View All
        </Link>
      </div>
      <h5 className="text-primary-700 font-bold text-sm">Revenue Trends</h5>
      <div className="w-full grid grid-cols-2 gap-10">
        <div className="flex flex-col gap-5">
          <MonthlyRevenueTrends />
        </div>
        <div className="">
          <YearlyRevenueTrends />
        </div>
      </div>
    </div>
  );
}
