import React from "react";
import { DataTable } from "./data-table";
import { ClientsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";

export default function ClientsTable({ data }: { data: any }) {
  return (
    <div>
      <DataTable columns={ClientsColumns} data={data || []} />
    </div>
  );
}
