import { userStoreInitialState, useStore } from "@/contexts/userStore";
import React, { useEffect, useState } from "react";
import {
  government_form_schema_page_1_type,
  government_form_schema_page_2_type,
} from "./_schema";
import axios from "axios";
import { toast } from "sonner";
import { getBackendUrl } from "@/lib/utils";
import { DocumentsForm } from "@/components/forms/DocumentsForm";
import { GovernmentFirstForm } from "./_form-pages/first_form_page";
import { GovernmentSecondForm } from "./_form-pages/second_form_page";
import { useNavigate } from "react-router-dom";
import { PaymentForm } from "@/components/ui/payment-section";

export const useGovernmentProfileRegistrationForm = () => {
  const [formPage, setFormPage] = useState(1);
  const [formData, setFormData] = useState({
    institutionName: "",
    contactPerson: "",
    phoneNumber: "",
    state: "",
    lga: "",
    street: "",
    association: "",
    department: "",
    tin_cac: "",
  });
  const [files, setFiles] = useState<File[]>([]);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const userStore = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (userStore.user.profile_updated) {
      setFormPage(3);
    }
  }, []);

  const firstFormHandleSubmit = (
    values: government_form_schema_page_1_type
  ) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setFormPage((prev) => prev + 1);
  };
  const secondFormHandleSubmit = async (
    values: government_form_schema_page_2_type
  ) => {
    setIsLoading(true);
    try {
      setFormData((prev) => {
        return { ...prev, ...values };
      });
      const government_profile_data = {
        account_type: "government",
        importer_name: formData.institutionName,
        email: userStore.user.email,
        contact_person: formData.contactPerson,
        phone_number: formData.phoneNumber,
        state: formData.state,
        lga: formData.lga,
        association: values.association,
        department: values.department,
        street: formData.street,
        tin_cac: formData.tin_cac,
        created_by: userStore.user.email,
      };
      await axios.post(
        `${getBackendUrl()}/store-information`,
        government_profile_data
      );
      setFormPage((prev) => prev + 1);
      setIsLoading(false);
      toast.success("Profile data stored successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const handleDocumentsUpload = async (documents: File[]) => {
    if (documents.length === 0) {
      toast.error("No document selected");
      return;
    }
    let total_mb = 0;
    documents.forEach((file) => {
      total_mb += file.size / 1024 / 1024;
    });
    if (total_mb > 3) {
      toast.error("Total file size must not be more than 3MB");
      return;
    }

    try {
      toast.success("Document Successfully Uploaded");
      setProfileUploaded(true);
      setFiles(documents);
      setFormPage((prev) => prev + 1);
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const handlePaymentSuccess = async () => {
    setIsLoading(true);
    try {
      const documentsFormData = new FormData();

      files.forEach((file) => {
        documentsFormData.append("files[]", file);
      });
      documentsFormData.append("account_type", "government");
      documentsFormData.append("email", userStore.user.email);
      await axios.post(
        `${getBackendUrl()}/upload-reg-document`,
        documentsFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const new_user: userStoreInitialState["user"] = {
        ...userStore.user,
        registration_payment_status: "paid",
        registration_status: "pending",
        profile_updated: true,
      };
      userStore.setUser(new_user);
      localStorage.setItem("nesrea_user", JSON.stringify(new_user));
      navigate("/dashboard");
      toast.success("Profile updated successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const formPages = [
    {
      key: "1",
      component: (
        <>
          <GovernmentFirstForm handleSubmit={firstFormHandleSubmit} />
          <div className="w-full flex justify-end text-primary-700 text-xs">
            <p>Page {formPage} of 2</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      component: (
        <>
          <GovernmentSecondForm handleSubmit={secondFormHandleSubmit} />
          <div className="w-full flex justify-end text-primary-700 text-xs">
            <p>Page {formPage} of 2</p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      component: <DocumentsForm handleSubmit={handleDocumentsUpload} />,
    },
    {
      key: "4",
      component: (
        <PaymentForm amount={115000} onSuccess={handlePaymentSuccess} />
      ),
    },
  ];

  return {
    company_form: {
      formPage,
      setFormPage,
      formData,
      setFormData,
      formPages,
      firstFormHandleSubmit,
      handleDocumentsUpload,
    },
    company_files: {
      files,
      setFiles,
    },
    utils: {
      isLoading,
      setIsLoading,
      profileUploaded,
      setProfileUploaded,
      handlePaymentSuccess,
    },
  };
};
