import React from "react";
import { DataTable } from "./data-table";
import { initItemDetailsAdminColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { ImportItem } from "@/lib/interfaces";

export default function ItemDetailsAdminTable({
  data,
  handleSelect,
  selected_hs_codes,
}: {
  data: ImportItem[];
  handleSelect: (hs_codes: string[]) => void;
  selected_hs_codes: string[];
}) {
  return (
    <div>
      <DataTable
        columns={initItemDetailsAdminColumns(handleSelect, selected_hs_codes)}
        data={data}
      />
    </div>
  );
}
