import UserCategoryTable from "@/components/tables/UserCategoryTable";
import { cn } from "@/lib/utils";
import { Eye } from "lucide-react";
import React, { useState } from "react";

const UserCategories: {
  id: "company" | "individual" | "agent" | "government";
  title: string;
  value: number;
}[] = [
  {
    id: "company",
    title: "All Companies",
    value: 5000,
  },
  {
    id: "individual",
    title: "All Individuals",
    value: 5000,
  },
  {
    id: "government",
    title: "All Government Institutions",
    value: 5000,
  },
  {
    id: "agent",
    title: "All Agents",
    value: 5000,
  },
];

export default function UserProfilingPage() {
  const [activeCategory, setActiveCategory] = useState<
    "agent" | "individual" | "company" | "government"
  >(UserCategories[0].id);
  return (
    <div className="flex flex-col gap-10">
      <div className="w-full grid grid-cols-4 gap-10">
        {UserCategories.map(({ title, value, id }) => (
          <div
            key={id}
            className={cn(
              "flex flex-col gap-3 p-5 rounded-xl flex-1 duration-500 min-w-[200px] max-w-[250px] h-full justify-between bg-primary-50 text-primary-700 shadow-lg border-primary-700 border cursor-pointer",
              activeCategory == id && "bg-primary-700 text-primary-foreground",
              activeCategory != id && "hover:scale-105"
            )}
            onClick={() => setActiveCategory(id)}
          >
            <p className={cn("text-xs flex items-center gap-1")}>
              <Eye size={15} />
              {title}
            </p>
            <h4 className="font-bold text-2xl">{value}</h4>
          </div>
        ))}
      </div>
      <UserCategoryTable category={activeCategory} />
    </div>
  );
}
