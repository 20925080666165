import React from "react";
import { DataTable } from "./data-table";
import { RegistrationsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { registrationsData } from "@/lib/dummy-data/registrationsData";
import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";

export default function RegistrationsTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["registrations"],
    queryFn: async () => {
      const {
        data,
      }: {
        data: {
          alluser: {
            id: number;
            nesrea_id: string;
            importer_category: string;
            importer_name: string;
            importer_representative: string | null;
            email: string;
            phone_number: string;
            tin_nin_cac: string;
            country: string;
            state: string;
            lga: string;
            street_address: string;
            association: string;
            department: string | null;
            payment_id: string | null;
            reference: string | null;
            amount: number | null;
            payment_status: string | null;
            created_by: string | null;
            status: string;
            created_at: string;
            updated_at: string;
            comments: string | null;
            activities: string | null;
            document_url: string | null;
            document_name: string | null;
            action_taken: string | null;
            confim_by_name: string | null;
            confirm_date: string | null;
            action_taken_registration: string | null;
            registration_approved_by: string | null;
            registration_approval_date: string | null;
            registration_approval_comment: string | null;
            action_taken_payment: string | null;
            payment_approved_by: string | null;
            payment_approval_date: string | null;
            payment_approval_comment: string | null;
          }[];
        };
      } = await axios.get(`${getBackendUrl()}/all-registered-account`);
      return data.alluser;
    },
  });
  return (
    <div>
      <DataTable columns={RegistrationsColumns} data={data || []} />
    </div>
  );
}
