import CertificatesAdminTable from '@/components/tables/certificatesAdminTable';
import React from 'react'

export default function CertificatesPage() {
  return (
    <div>
      <CertificatesAdminTable />
    </div>
  );
}
