import { WaiverRequest } from "../interfaces";

export const waiverRequests: WaiverRequest[] = [
    { id: "ESR/123/001", name: "Alice Johnson", category: "Medical", dateCreated: "2023-01-01", status: "Pending" },
    { id: "ESR/123/002", name: "Bob Smith", category: "Academic", dateCreated: "2023-01-02", status: "Approved" },
    { id: "ESR/123/003", name: "Charlie Brown", category: "Financial", dateCreated: "2023-01-03", status: "Rejected" },
    { id: "ESR/123/004", name: "Diana Prince", category: "Medical", dateCreated: "2023-01-04", status: "Pending" },
    { id: "ESR/123/005", name: "Ethan Hunt", category: "Academic", dateCreated: "2023-01-05", status: "Approved" },
    { id: "ESR/123/006", name: "Fiona Gallagher", category: "Financial", dateCreated: "2023-01-06", status: "Rejected" },
    { id: "ESR/123/007", name: "George Clooney", category: "Medical", dateCreated: "2023-01-07", status: "Pending" },
    { id: "ESR/123/008", name: "Hannah Montana", category: "Academic", dateCreated: "2023-01-08", status: "Approved" },
    { id: "ESR/123/009", name: "Ian Malcolm", category: "Financial", dateCreated: "2023-01-09", status: "Rejected" },
    { id: "ESR/123/010", name: "Julia Roberts", category: "Medical", dateCreated: "2023-01-10", status: "Pending" },
    // ... add more entries as needed ...
];
