import React, { useState } from "react";
import DashboardModal from "@/components/modals/DashboardModal";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/contexts/userStore";
import AgentApplicationsView from "./AgentView/ApplicationsView";
import ComponentSwitch from "@/components/ui/component-switch";
import ImporterApplicationsView from "./ImporterView";

export default function ApplicationsPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const userStore = useStore();
  const { registration_status } = userStore.user;
  const application_user_type_pages = [
    {
      key: "agent",
      component: <AgentApplicationsView />,
    },
    {
      key: "company",
      component: <ImporterApplicationsView />,
    },
    {
      key: "individual",
      component: <ImporterApplicationsView />,
    },
    {
      key: "government",
      component: <ImporterApplicationsView />,
    },
  ];

  return registration_status == "verified" ? (
    <div>
      {showModal && (
        <DashboardModal
          title=""
          description="You have no record of any application for import clearance certificate"
          cta={{
            txt: "Apply for Import Clearance Certificate",
            onClick: () => {
              navigate("/dashboard/application/add-application");
              setShowModal(false);
            },
          }}
        />
      )}

      <ComponentSwitch
        activeKey={userStore.user?.account_type}
        components={application_user_type_pages}
      />
    </div>
  ) : (
    <></>
  );
}
