import React from "react";
import AccountingOverview from "./components/AccountingOverview";
import RevenueTopImportCategories from "./components/RevenueTopImportCategories";
import LegalOverview from "./components/LegalOverview";
import ClearanceOverview from "./components/ClearanceOverview";
import ReportOverview from "./components/ReportOverview";

export default function DashboardPage() {
  return (
    <div className="w-full flex flex-col gap-16">
      <AccountingOverview />
      <RevenueTopImportCategories />
      <LegalOverview />
      <ClearanceOverview />
      <ReportOverview />
    </div>
  );
}
