import { agentStoreInitialState } from "@/contexts/agentStore";

export const applications: agentStoreInitialState["applications"] = [
  {
    id: "NESREA/001/24",
    name: "John Doe",
    category: "Individual",
    dateCreated: "2024-10-01",
    status: "Pending",
  },
  {
    id: "NESREA/002/24",
    name: "ABC Enterprises",
    category: "Company",
    dateCreated: "2024-10-03",
    status: "Approved",
  },
  {
    id: "NESREA/003/24",
    name: "Jane Smith",
    category: "Individual",
    dateCreated: "2024-10-05",
    status: "Pending",
  },
  {
    id: "NESREA/004/24",
    name: "Tech Solutions Ltd.",
    category: "Company",
    dateCreated: "2024-10-07",
    status: "Pending",
  },
  {
    id: "NESREA/005/24",
    name: "Green Innovations",
    category: "Company",
    dateCreated: "2024-10-10",
    status: "Approved",
  },
  {
    id: "NESREA/006/24",
    name: "Alex Johnson",
    category: "Individual",
    dateCreated: "2024-10-12",
    status: "Pending",
  },
];
