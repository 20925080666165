import React, { useState } from "react";
import CertificatesView from "./components/CertificatesView";
import { useNavigate } from "react-router-dom";
import DashboardModal from "@/components/modals/DashboardModal";
import { useStore } from "@/contexts/userStore";

export default function CertificatesPage() {
  const [showModal, setShowModal] = useState(false);
  const userStore = useStore();
  const { registration_status } = userStore.user;
  return registration_status == "verified" ? (
    <div>
      {showModal && (
        <DashboardModal
          title=""
          description="You have no record of import clearance certificates"
          cta={{
            txt: "Apply for Import Clearance Certificate",
            onClick: () => {
              setShowModal(false);
            },
          }}
        />
      )}
      <CertificatesView />
    </div>
  ) : (
    <></>
  );
}
