import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";

export const useUserAnalytics = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["user-analytics"],
    queryFn: async () => {
      const { data }: { data: { alluser: any[]; allagent: any[] } } =
        await axios.get(`${getBackendUrl()}/all-registered-account`);
      return data;
    },
  });

  if (data) {
    const {
      TOTAL_USERS,
      TOTAL_AGENTS,
      TOTAL_COMPANIES,
      TOTAL_INDIVIDUALS,
      TOTAL_GOVERNMENT,
    } = getUserCount(data);
    const { TOTAL_NEW_USERS, TOTAL_APPROVED_USERS, TOTAL_REJECTED_USERS } =
      getUserStatusCount(data);

    return {
      users_count: {
        TOTAL_USERS,
        TOTAL_AGENTS,
        TOTAL_COMPANIES,
        TOTAL_INDIVIDUALS,
        TOTAL_GOVERNMENT,
      },
      registration_status: {
        TOTAL_NEW_USERS,
        TOTAL_APPROVED_USERS,
        TOTAL_REJECTED_USERS,
      },
    };
  }

  return null;
};

const getUserCount = (data: any) => {
  const TOTAL_USERS = data.alluser.length + data.allagent.length;
  const TOTAL_AGENTS = data.allagent.length;
  const TOTAL_COMPANIES = data.alluser.filter(
    (user: any) => user.importer_category === "company"
  ).length;
  const TOTAL_INDIVIDUALS = data.alluser.filter(
    (user: any) => user.importer_category === "individual"
  ).length;
  const TOTAL_GOVERNMENT = data.alluser.filter(
    (user: any) => user.importer_category === "government"
  ).length;

  return {
    TOTAL_USERS,
    TOTAL_AGENTS,
    TOTAL_COMPANIES,
    TOTAL_INDIVIDUALS,
    TOTAL_GOVERNMENT,
  };
};

const getUserStatusCount = (data: any) => {
  const TOTAL_NEW_USERS = data.alluser.filter(
    (user: any) => user.status.toLowerCase() === "new"
  ).length;
  const TOTAL_APPROVED_USERS = data.alluser.filter(
    (user: any) => user.status.toLowerCase() === "approved"
  ).length;
  const TOTAL_REJECTED_USERS = data.alluser.filter(
    (user: any) => user.status.toLowerCase() === "rejected"
  ).length;

  return {
    TOTAL_NEW_USERS,
    TOTAL_APPROVED_USERS,
    TOTAL_REJECTED_USERS,
  };
};
