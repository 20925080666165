import { buttonVariants } from "@/components/ui/button";
import { cn, getBackendUrl } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";
import ClientsTable from "./ClientsTable.tsx";
import { useStore } from "@/contexts/agentStore";
import { useStore as useStore_ } from "@/contexts/userStore";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import LoaderWrapper from "@/components/ui/loader-wrapper.js";

export default function ClientsView() {
  const agentStore = useStore((state) => state);
  const userStore = useStore_((state) => state.user);

  const { data, isLoading } = useQuery({
    queryKey: ["agent-clients"],
    queryFn: async () => {
      const { data }: { data: { Clientlist: any[] } } = await axios.get(
        `${getBackendUrl()}/get-my-client/${userStore.email}`
      );
      return data.Clientlist.map((item) => ({
        name: item.importer_name,
        category: item.importer_category,
        dateCreated: item.created_at,
        status:item.status
      }));
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="flex flex-col gap-4">
        <div className="w-full rounded-2xl py-5 px-8 flex items-center text-primary-foreground justify-between banner-gradient">
          <h4 className="text-xl font-medium">
            Apply for an Import Clearance Certificate
          </h4>

          <Link
            to={
              agentStore.selectedClients.length == 1
                ? "/dashboard/application/add-application"
                : "/dashboard/application"
            }
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-white text-primary-700 hover:bg-white/90 w-full max-w-[175px]"
            )}
            onClick={() => {
              if (agentStore.selectedClients.length == 1) {
                agentStore.setActiveApplicationClient(
                  agentStore.selectedClients[0].name
                );
              }
            }}
          >
            Apply Here
          </Link>
        </div>

        <ClientsTable data={data} />
      </div>
    </LoaderWrapper>
  );
}
