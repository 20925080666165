import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function ReportOverview() {
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl flex flex-col gap-5 p-3">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          Report Overview
        </h1>
        <Link
          to={"#"}
          className={cn(
            buttonVariants({ variant: "link" }),
            "p-0 underline text-xs"
          )}
        >
          View All
        </Link>
      </div>

      <h4>User Base Summary</h4>
      <div className="w-full aspect-[1.2/1] flex items-center justify-center relative">
        <CircleChart />
      </div>
    </div>
  );
}

const CircleChart = () => {
  // Define your data with category, percentage, color, and positions for clustering
  const data = [
    {
      id: 1,
      category: "Agent",
      percentage: 50,
      color: "#274b1c",
      top: 10,
      left: 25,
    },
    {
      id: 2,
      category: "Company",
      percentage: 25,
      color: "#5ba8b5",
      top: 40,
      left: 50,
    },
    {
      id: 3,
      category: "Individual",
      percentage: 15,
      color: "#b2d7b4",
      top: 40,
      left: 20,
    },
    {
      id: 4,
      category: "Government",
      percentage: 10,
      color: "#77a55a",
      top: 10,
      left: 60,
    },
  ];
  const [hoveredCircle, setHoveredCircle] = useState<any>(null);

  // Convert percentage to a proportional circle size
  const calculateSize = (percentage: number) => {
    const maxSize = 500; // Adjust this to control the max circle size
    return (percentage / 100) * maxSize;
  };

  const handleMouseEnter = (id: any, e: any) => {
    const circle = data.find((item) => item.id === id);
    const circleRect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - (circleRect.left + circleRect.width / 2);
    const offsetY = e.clientY - (circleRect.top + circleRect.height / 2);

    const displacement = 10; // Adjust this value for stronger or weaker displacement
    setHoveredCircle({
      id,
      top: circle?.top! - (offsetY / Math.abs(offsetY)) * displacement,
      left: circle?.left! - (offsetX / Math.abs(offsetX)) * displacement,
    });
  };

  const handleMouseLeave = () => {
    setHoveredCircle(null); // Reset position when the mouse leaves
  };
  return (
    <div className="w-full flex aspect-square overflow-hidden relative">
      {data.map((item, index) => {
        const isHovered = hoveredCircle && hoveredCircle.id === item.id;
        const top = isHovered ? hoveredCircle.top : item.top;
        const left = isHovered ? hoveredCircle.left : item.left;
        return (
          <div
            key={item.id}
            onMouseEnter={(e) => handleMouseEnter(item.id, e)}
            onMouseLeave={handleMouseLeave}
            className="duration-500 delay-150"
            style={{
              position: "absolute",
              top: `${top}%`,
              left: `${left}%`,
              width: `${item.percentage}%`,
              height: `${item.percentage}%`,
              backgroundColor: item.color,
              borderRadius: "50%",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            {item.percentage}%
          </div>
        );
      })}
      <div className="w-full mt-auto flex items-center justify-center gap-10 py-10">
        {data.map((item) => (
          <div className="flex items-center gap-2">
            <div
              className="w-[20px] aspect-square rounded"
              style={{ backgroundColor: item.color }}
            ></div>
            <h5>{item.category}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};
