import { ImportDocumentationData } from "@/lib/interfaces";

export const importDocumentationData: ImportDocumentationData = [
  {
    id: "1",
    category: "Chemicals(Raw Materials)",
    documents: [
      { documentName: "CAC", documentCode: "2506.00.10.00" },
      { documentName: "Sodium Chloride", documentCode: "2506.00.10.01" },
      { documentName: "Potassium Nitrate", documentCode: "2506.00.10.02" },
      { documentName: "Calcium Carbonate", documentCode: "2506.00.10.03" },
    ],
  },
  {
    id: "2",
    category: "Pharmaceuticals",
    documents: [
      { documentName: "Aspirin", documentCode: "2506.00.20.00" },
      { documentName: "Ibuprofen", documentCode: "2506.00.20.01" },
      { documentName: "Paracetamol", documentCode: "2506.00.20.02" },
    ],
  },
  {
    id: "3",
    category: "Plastics",
    documents: [
      { documentName: "Polyethylene", documentCode: "2506.00.30.00" },
      { documentName: "Polypropylene", documentCode: "2506.00.30.01" },
      { documentName: "Polystyrene", documentCode: "2506.00.30.02" },
    ],
  },
  {
    id: "4",
    category: "Metals",
    documents: [
      { documentName: "Aluminum", documentCode: "2506.00.40.00" },
      { documentName: "Copper", documentCode: "2506.00.40.01" },
      { documentName: "Steel", documentCode: "2506.00.40.02" },
    ],
  },
  // Add more categories and documents as needed
];
