import React from "react";
import { DataTable } from "./data-table";
import { ClientsColumns } from "./columns";
import { AllClearanceApplicationsTableData } from "../../ImporterView/all-clearance-applications-table/_interfaces";

export default function ApplicationsTable({
  data,
}: {
  data: AllClearanceApplicationsTableData[];
}) {
  return (
    <div>
      <DataTable columns={ClientsColumns} data={data} />
    </div>
  );
}
