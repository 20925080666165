import React from "react";
import { DataTable } from "./data-table";
import { RecentImportsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";

export default function RecentImportsTable() {
  const agentStore = useStore((state) => state);
  return (
    <div>
      <DataTable
        columns={RecentImportsColumns}
        data={[]}
      />
    </div>
  );
}
