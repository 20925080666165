import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { format } from "fecha";

interface ColumnSchema {
  name: string;
  category: string;
  dateCreated: string;
  status: string;
}

export const ClientsColumns: ColumnDef<ColumnSchema>[] = [
  {
    accessorKey: "select",
    header: ({ column, ...props }) => {
      const agentStore = useStore((state) => state);

      return (
        // <Checkbox
        //   checked={
        //     agentStore.clients.length == agentStore.selectedClients.length
        //   }
        //   onCheckedChange={(checked) => {
        //     if (checked) {
        //       agentStore.selectClients(
        //         agentStore.clients.filter(
        //           (client) => !agentStore.selectedClients.includes(client)
        //         )
        //       );
        //     } else {
        //       agentStore.clearSelectedClients();
        //     }
        //   }}
        // />
        <></>
      );
    },
    cell: ({ row }) => {
      const agentStore = useStore((state) => state);
      let isSelected = agentStore.selectedClients.includes(row.original);
      return (
        <Checkbox
          checked={isSelected}
          disabled={row.original.status != "Approved"}
          onCheckedChange={(checked) => {
            if (checked) {
              agentStore.selectClients([row.original]);
            } else {
              agentStore.deselectClient(row.original);
            }
          }}
        />
      );
    },
  },
  {
    accessorKey: "category",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Client Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { status } = row.original;
      let isPending = status == "NEW";
      let isApproved = status == "Approved";
      let isRejected = status == "Disapproved";

      const status_dict: { [key: string]: string } = {
        NEW: "Pending",
        Approved: "Approved",
        Disapproved: "Rejected",
      };
      return (
        <p
          className={cn(
            "capitalize",
            isPending && "text-orange-400",
            isApproved && "text-primary-300",
            isRejected && "text-destructive"
          )}
        >
          {status_dict[status]}
        </p>
      );
    },
  },
  {
    accessorKey: "dateCreated",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { dateCreated } = row.original;
      let formattedDate = format(new Date(dateCreated), "mm-dd-YYYY");
      return <p>{formattedDate}</p>;
    },
  },
];
