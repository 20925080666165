import { Button, buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import MessagesChart from "./MessagesChart";

export default function AdminMessaging() {
  const [selectedPeriod, setSelectedPeriod] = useState<
    "daily" | "weekly" | "monthly"
  >("daily");
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">Complaints</h1>
        <div className="flex gap-2">
          {["daily", "weekly", "monthly"].map((period) => (
            <Button
              variant={"outline"}
              className={cn(
                "font-light text-primary-600 capitalize",
                selectedPeriod === period &&
                  "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground"
              )}
              onClick={() =>
                setSelectedPeriod(period as "daily" | "weekly" | "monthly")
              }
            >
              {period}
            </Button>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-5 items-center justify-center">
        <h3 className="text-primary-700 text-lg font-medium">
          Messages Exchanged Over Time
        </h3>
        <MessagesChart />
        
      </div>
    </div>
  );
}
