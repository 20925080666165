import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { ImportDocumentationData } from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

export const RegistrationDocumentationColumns: ColumnDef<
  ImportDocumentationData[0]
>[] = [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Serial Number
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  {
    accessorKey: "category",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Importer Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "documents",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Documents
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div className="grid gap-4">
          {row.original.documents.map((doc) => (
            <p
              key={doc.documentCode}
              className="text-sm text-primary-500 font-bold"
            >
              {doc.documentName}
            </p>
          ))}
        </div>
      );
    },
  },
  {
    accessorKey: "documentCode",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Document Code
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div className="grid gap-4">
          {row.original.documents.map((doc) => (
            <p key={doc.documentCode} className="text-sm">
              {doc.documentCode}
            </p>
          ))}
        </div>
      );
    },
  },

  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const formSchema = z.object({
        comment: z.string().min(2, { message: "" }),
      });
      type formSchemaType = z.infer<typeof formSchema>;
      const form = useForm<formSchemaType>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          comment: "",
        },
      });
      return (
        <div className="grid gap-7">
          {row.original.documents.map((doc) => (
            <Dialog>
              <DialogTrigger className="underline text-primary-700 text-sm">
                Delete
              </DialogTrigger>
              <DialogContent>
                <h3 className="text-primary-500 font-medium text-lg text-center">
                  {"You're"} about to delete a document. Do you want to proceed?
                </h3>
                <Form {...form}>
                  <form
                    className="flex flex-col gap-7"
                    onSubmit={form.handleSubmit(async (data) => {})}
                  >
                    <FormField
                      control={form.control}
                      name="comment"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-primary-700 font-semibold text-lg">
                            Add comment/reason for deletion{" "}
                            <span className="text-red-500">*</span>
                          </FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <div className="flex w-full gap-5">
                      <Button type="submit" className="w-full py-7">
                        Yes, proceed
                      </Button>
                      <DialogClose asChild>
                        <Button
                          type="button"
                          variant="outline"
                          className="w-full py-7 bg-transparent border-destructive text-destructive"
                        >
                          No, cancel
                        </Button>
                      </DialogClose>
                    </div>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          ))}
        </div>
      );
    },
  },
];
