import { useStore } from "@/contexts/agentStore";
import { clearanceApplications } from "@/lib/dummy-data/clearanceApplicationData";
import { ClearanceApplicationColumns } from "./columns";
import { DataTable } from "./data-table";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { ClearanceApplicationData } from "@/lib/interfaces";

export default function ClearanceApplicationTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["clearance-applications-admin"],
    queryFn: async () => {
      const { data }: { data: { allclearanceapps: any[] } } = await axios.get(
        `${getBackendUrl()}/all-clearance-application`
      );
      const dataset: ClearanceApplicationData[] = data.allclearanceapps.map(
        (item) => ({
          id: item.clearance_id,
          name: item.importer_name,
          importerCategory: item.importer_category,
          applicationDate: item.created_at,
          importerEmail:item.importer_email,
          status: item.status,
        })
      );
      return dataset;
    },
  });
  return (
    <div>
      <DataTable columns={ClearanceApplicationColumns} data={data || []} />
    </div>
  );
}
