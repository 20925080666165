import { Button, buttonVariants } from "@/components/ui/button";
import { UserType, userTypes } from "@/lib/site-data";
import { cn } from "@/lib/utils";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RegForm from "./RegForm";
import OTP from "./OTP";
import { Mail } from "lucide-react";

export type formStages = "register" | "otp" | "loading";

export default function SignupForm() {
  const [activeUserType, setActiveUserType] = useState<UserType | "">("");
  const [formStage, setFormStage] = useState<formStages>("register");

  useEffect(() => {
    const fetchRegStage = async () => {
      const stage = localStorage.getItem("nesrea_user_form_stage");
      if (stage) {
        setFormStage(stage as formStages);
      }
    };

    fetchRegStage();
  }, []);
  return (
    <div className="w-full max-w-lg shadow-lg m-auto p-12 flex flex-col items-center gap-4 text-center">
      <img src="/images/Logo.png" className="w-[200px]" alt="" />
      {formStage == "register" && (
        <>
          <div className="space-y-2">
            <h5 className="text-sm text-neutral-600 font-light">
              Welcome back
            </h5>
            <h1 className="font-semibold text-xl">Change your account Type</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {userTypes.map((item: UserType) => {
              const isActive = activeUserType == item;
              return (
                <Button
                  key={item}
                  onClick={() => setActiveUserType(item)}
                  variant={"outline"}
                  className={cn(
                    "capitalize",
                    isActive &&
                      "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground"
                  )}
                >
                  {item}
                </Button>
              );
            })}
          </div>

          {activeUserType == "" ? (
            <>
              {/* <p className="font-semibold">or</p>
              <Link
                to={"/auth/sign-in"}
                className={cn(
                  buttonVariants({ variant: "link" }),
                  "w-full max-w-sm p-0 underline"
                )}
              >
                Login as Admin
              </Link> */}
            </>
          ) : (
            <>
              <RegForm
                setFormStage={setFormStage}
                activeUserType={activeUserType}
              />
              <p className="text-neutral-300 text-sm">
                Already have an account?{" "}
                <Link className="underline text-primary" to={"/auth/sign-in"}>
                  Log in
                </Link>
              </p>
            </>
          )}
        </>
      )}
      {formStage == "otp" && (
        <>
          <OTP />
        </>
      )}
    </div>
  );
}
