import GenerateInvoiceForm from '@/components/forms/GenerateInvoiceForm'
import React from 'react'

export default function GenerateInvoicePage() {
  return (
    <div>
      <GenerateInvoiceForm />
    </div>
  )
}
