import { buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import React from "react";

const CardStats = [
  {
    title: "Total Number of Admins",
    value: 50,
  },
  {
    title: "Total Number of Online Admins",
    value: 35,
  },
  {
    title: "Total Number of Offline Admins",
    value: 15,
  },
];

export default function Admins() {
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">Admins</h1>
        <Sheet>
          <SheetTrigger
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-transparent text-primary-700 hover:text-primary-foreground flex gap-1 items-center"
            )}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Filters
          </SheetTrigger>
          <SheetContent className="bg-secondary-50 flex flex-col gap-5 py-10">
            {/* Filters Here */}
          </SheetContent>
        </Sheet>
      </div>
      <div className="flex flex-wrap justify-center gap-5 w-full">
        {CardStats.map(({ title, value }, index) => (
          <div
            className={cn(
              "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-full h-full justify-between bg-primary-50 text-primary-700",
              index === 0 && "bg-primary-700 text-primary-foreground"
            )}
          >
            <p className={cn("text-xs ")}>{title}</p>
            <h4 className="font-bold text-2xl">{value}</h4>
          </div>
        ))}
      </div>
    </div>
  );
}
