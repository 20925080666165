import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ImportItemFormSchema, ImportItemFormSchemaType } from "./_schema";
import { useState } from "react";

export const useAddImportItemForm = () => {
  const form = useForm<ImportItemFormSchemaType>({
    resolver: zodResolver(ImportItemFormSchema),
  });

  const [hsCode, setHSCode] = useState<
    { label: string; code: string; id: string }[]
  >([]);

  const [showDropdown, setShowDropdown] = useState(false);

  

  return { form, hsCode, setHSCode, showDropdown, setShowDropdown };
};
