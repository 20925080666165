import React from "react";
import { DataTable } from "./data-table";
import { WaiverRequestsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { waiverRequests } from "@/lib/dummy-data/waiverRequests";

export default function WaiverRequestsTableAdmin() {
  const agentStore = useStore((state) => state);
  return (
    <div>
      <DataTable columns={WaiverRequestsColumns} data={waiverRequests} />
    </div>
  );
}
