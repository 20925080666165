import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";

export default function ImageHero() {
  return (
    <div className="w-full aspect-[16/8] relative">
      <div
        className="absolute top-0 w-full h-full bg-cover bg-center bg-no-repeat brightness-50"
        style={{ backgroundImage: `url('/images/hero-img.jpeg')` }}
      />
      <div className="relative h-full z-10 flex items-center justify-center text-center flex-col gap-5 text-white">
        <h1 className="font-bold text-5xl leading-tight">
          Get Your Import <br />
          Clearance in Record Time
        </h1>
        <p className="max-w-md font-medium text-xl">
          NESREA ensures compliance with environmental standards for a safer and
          cleaner Nigeria.
        </p>
        <div className="flex gap-3 items-center w-full justify-center">
          <Link
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-primary-foreground text-primary-500 hover:bg-primary-foreground/90 w-full max-w-[150px] font-medium"
            )}
            to={"/auth/sign-up"}
          >
            Sign Up
          </Link>
          <Link to={"/auth/sign-in"} className={cn(buttonVariants({variant:'ghost'}),'w-full max-w-[150px] border border-white')}>Login</Link>
        </div>
      </div>
    </div>
  );
}
