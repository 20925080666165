import { phoneNumberValidator } from "@/lib/utils";

import { z } from "zod";

export const agent_form_schema_page_1 = z.object({
  fullName: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  phoneNumber: z
    .string()
    .min(2, {
      message: "Username must be at least 2 characters.",
    })
    .refine((val) => phoneNumberValidator(val)),
  state: z.enum(
    [
      "abia",
      "adamawa",
      "akwa-ibom",
      "Anambra",
      "bauchi",
      "bayelsa",
      "benue",
      "borno",
      "cross-river",
      "delta",
      "ebonyi",
      "edo",
      "ekiti",
      "enugu",
      "federal-capital-territory",
      "gombe",
      "imo",
      "jigawa",
      "kaduna",
      "kano",
      "katsina",
      "kebbi",
      "kogi",
      "kwara",
      "lagos",
      "nasarawa",
      "niger",
      "ogun",
      "ondo",
      "osun",
      "oyo",
      "plateau",
      "rivers",
      "sokoto",
      "taraba",
      "yobe",
      "zamfara",
    ],
    { message: "State Is Required." }
  ),
  lga: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  street: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
});

export const agent_form_schema_page_2 = z.object({
  association: z.string().min(2, { message: "Association is required" }),
  nin: z
    .string()
    .min(11, {
      message: "NIN must be at least 11 digits.",
    })
    .max(11, { message: "NIN must not be more than 11 digits." }),
});

export type agent_form_schema_page_1_type = z.infer<
  typeof agent_form_schema_page_1
>;
export type agent_form_schema_page_2_type = z.infer<
  typeof agent_form_schema_page_2
>;
