import CertificatesTable from "@/components/tables/CertificatesTable";
import React from "react";

export default function CertificatesView() {
  return (
    <div>
      <CertificatesTable />
    </div>
  );
}
