import GenerateWaiverForm from "@/components/forms/GenerateWaiverForm";
import React from "react";

export default function GenerateWaiverPage() {
  return (
    <div>
      <GenerateWaiverForm />
    </div>
  );
}
