import React from "react";
import { DataTable } from "./data-table";
import { RecentActivityColumns } from "./columns";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { useStore } from "@/contexts/userStore";

const userActivities: any = [
  {
    title: "Document Submission",
    name: "John Doe",
    date: "2024-10-31",
    status: "Pending",
  },
  {
    title: "Profile Update",
    name: "Jane Smith",
    date: "2024-10-30",
    status: "Approved",
  },
  {
    title: "Password Change",
    name: "John Doe",
    date: "2024-10-29",
    status: "Error",
  },
  {
    title: "Account Verification",
    name: "Alice Johnson",
    date: "2024-10-28",
    status: "Approved",
  },
  {
    title: "Uploaded ID Document",
    name: "Robert Brown",
    date: "2024-10-27",
    status: "Pending",
  },
  {
    title: "Email Confirmation",
    name: "Emily White",
    date: "2024-10-26",
    status: "Approved",
  },
  {
    title: "Security Question Update",
    name: "Michael Green",
    date: "2024-10-25",
    status: "Error",
  },
  {
    title: "Uploaded Profile Picture",
    name: "Sarah Black",
    date: "2024-10-24",
    status: "Approved",
  },
  {
    title: "Submitted Application Form",
    name: "David King",
    date: "2024-10-23",
    status: "Pending",
  },
  {
    title: "Address Update",
    name: "Sophia Miller",
    date: "2024-10-22",
    status: "Approved",
  },
];

export default function RecentActivities() {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["activities"],
    queryFn:
      userStore.account_type == "agent"
        ? async () => {
            const { data } = await axios.get(
              `${getBackendUrl()}/agent-activities/${userStore.email}`
            );
            return (
              data?.Agent_activities.map((activity: any) => ({
                title: activity.activities,
                name: activity.agent_name || activity.importer_name,
                date: activity.created_at,
                status: activity.status,
              })) || []
            );
          }
        : async () => {
            const { data } = await axios.get(
              `${getBackendUrl()}/importer-activities/${userStore.email}`
            );
            return (
              data?.User_activities.map((activity: any) => ({
                title: activity.activities,
                name: activity.importer_name,
                date: activity.created_at,
                status: activity.status,
              })) || []
            );
          },
  });
  return (
    <div>
      <DataTable columns={RecentActivityColumns} data={data || []} />
    </div>
  );
}
