import React from "react";
import ComponentSwitch from "@/components/ui/component-switch";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { PaymentForm } from "@/components/ui/payment-section";
import { useIndividualProfileRegistrationForm } from "./_hooks";

export const IndividualProfileRegistrationForm = () => {
  const { company_form, utils } = useIndividualProfileRegistrationForm();
  return (
    <LoaderWrapper isLoading={utils.isLoading}>
      <div className="w-full flex flex-col gap-10">
        <div className="space-y-3">
          <h4 className="font-medium text-2xl">
            {company_form.formPage <= 2
              ? "Enter the following details to update your profile"
              : "Upload the documents to update your profile"}
          </h4>
          <p className="text-neutral-400 text-xl">Mandatory documents: NIN</p>
        </div>

        <>
          <ComponentSwitch
            activeKey={company_form.formPage.toString()}
            components={company_form.formPages}
          />
        </>

        
      </div>
    </LoaderWrapper>
  );
};
