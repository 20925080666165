import React, { useState } from "react";
import { ImporterImportDetailsTable } from "../../../_tables";
import { Button } from "@/components/ui/button";
import { ImportItem } from "@/lib/interfaces";

export default function ApplicationFormPageOne({
  handleSubmit,
  importDetails,
  setImportDetails,
}: {
  handleSubmit: (data: ImportItem[]) => void;
  importDetails: ImportItem[];
  setImportDetails: React.Dispatch<React.SetStateAction<ImportItem[]>>;
}) {
  return (
    <div className="flex flex-col gap-4">
      <ImporterImportDetailsTable
        importDetails={importDetails}
        handleImportDetails={(data) => {
          setImportDetails((prev) => [...prev, data]);
        }}
      />
      <Button
        className="w-full max-w-[150px]"
        onClick={() => handleSubmit(importDetails)}
      >
        Next
      </Button>
    </div>
  );
}
